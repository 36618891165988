<div *ngIf="!isShowAddForm" class="actions">
  <a mat-raised-button (click)="showAddForm()">{{'addons.banners.add' | translate}}</a>
</div>

<div class="container" *ngIf="banners && !isShowAddForm">
  <div *ngFor="let banner of banners.banners">
    <app-banner [banner]="banner"></app-banner>
  </div>
</div>

<div *ngIf="isShowAddForm">
  <app-banner-add [onClose]="hideAddForm"></app-banner-add>
</div>
