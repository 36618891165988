import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { Employee } from 'src/app/employees/employees.interface';
import { EmployeesService } from '../../employees/employees.service';

@Component({
  selector: 'app-submit-user',
  templateUrl: './submit-user.component.html',
  styleUrls: ['./submit-user.component.scss']
})
export class SubmitUserComponent implements OnInit {

  employees: Employee[];

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    alias: new UntypedFormControl('', Validators.email)}
  );

  @Input() updating;
  @Output() submitted = new EventEmitter();

  constructor(private employeesService: EmployeesService) {
  }

  ngOnInit(): void {
    this.employeesService.getNotExcludedEmployees().subscribe(data => {
      this.employees = data;
    });
  }

  submit() {
    this.submitted.emit(this.form.value);
  }

}
