<mat-card appearance="outlined">

  <mat-card-title>
    <div *ngIf="!editMode">
      {{'media.images.name' | translate}}: {{image.name}}
    </div>
    <form [formGroup]="form" class="form" (ngSubmit)="changeImage()" *ngIf="editMode">
      <mat-form-field appearance="fill">
        <mat-label>{{'media.images.name' | translate}}</mat-label>
        <input matInput formControlName="name" type="text">
        <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
      </mat-form-field>
    </form>
  </mat-card-title >

  <img alt="" [src]="image.imageUrl">

  <mat-card-actions class="actions">
    <mat-icon *ngIf="editMode" title="{{'cancel' | translate}}" (click)="cancelEditImage()">cancel</mat-icon>
    <mat-icon *ngIf="editMode" title="{{'save' | translate}}" (click)="changeImage()">save</mat-icon>
    <mat-icon *ngIf="!editMode" title="{{'edit' | translate}}" (click)="changeEditMode()">edit</mat-icon>
    <mat-icon title="{{'copyToClipboard' | translate}}" (click)="copyImageUrlToClipboard()">content_copy</mat-icon>
    <mat-icon title="{{'delete' | translate}}" (click)="removeImage(image.id)">delete_outline</mat-icon>
  </mat-card-actions>

</mat-card>
