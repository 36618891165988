import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeSetting, PredefinedEmployeeSetting } from './settings.interface';

@Injectable({providedIn: 'root'})
export class SettingsService {

  constructor(private http: HttpClient) {
  }

  getPredefinedEmployeeSettings(): Observable<PredefinedEmployeeSetting[]> {
    return this.http.get<PredefinedEmployeeSetting[]>('/signature-variables-predefined');
  }

  getEmployeeSettings(): Observable<EmployeeSetting[]> {
    return this.http.get<EmployeeSetting[]>('/signature-variables');
  }

  changeEmployeeSettings(payload: EmployeeSetting[]): Observable<any> {
    return this.http.put('/signature-variables', payload);
  }
}
