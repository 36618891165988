import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {PartnerClientsComponent} from './partner-clients/partner-clients.component';
import {PartnerMainComponent} from './partner-main/partner-main.component';
import {AppRoutingModule} from '../app-routing.module';
import {PartnerCreateClientDialogComponent} from './partner-create-client-dialog/partner-create-client-dialog.component';


@NgModule({
  declarations: [
    PartnerClientsComponent,
    PartnerMainComponent,
    PartnerCreateClientDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    ReactiveFormsModule,
  ]
})
export class PartnerModule { }
