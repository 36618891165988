import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Image } from '../media.interface';
import { ChangeImageAction, RemoveImageAction } from '../images.actions';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() image: Image;
  form: UntypedFormGroup;
  editMode = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.image.id, Validators.required),
      name: new UntypedFormControl(this.image.name, Validators.required),
    });
  }

  changeImage = () => {
    if (this.form.valid) {
      this.store.dispatch(new ChangeImageAction(this.form.value));
      this.changeEditMode();
    } else {
      this.form.markAllAsTouched();
    }
  }

  cancelEditImage = () => {
    this.fillInitialForm();
    this.changeEditMode();
  }

  changeEditMode = () => {
    this.editMode = !this.editMode;
  }


  copyImageUrlToClipboard = () => {
    navigator.clipboard.writeText(this.image.imageUrl);
    this.toastr.success(this.translate.instant('media.images.copyUrlSuccess'));
  }

  removeImage = imageId => {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('media.images.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.store.dispatch(new RemoveImageAction(imageId));
          }
        }
      );
  }

}
