import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LoaderService {
  private loading = new BehaviorSubject(false);

  isLoading = () => this.loading;

  setLoading = (val: boolean) => this.loading.next(val);
}
