import { Component } from '@angular/core';
import moment from 'moment';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-trial-info',
  templateUrl: './trial-info.component.html',
  styleUrls: ['./trial-info.component.scss']
})
export class TrialInfoComponent {

  constructor(public auth: AuthService) {
  }

  daysRemaining = moment(this.auth.trialFinishDate).diff(moment(), 'days');

}
