import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SignatureInstallationStateService} from './signature-installation-state.service';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {
  POLL_STATE_UNTIL_INSTALLATION_FINISHED,
  PollStateUntilInstallationFinishedAction, FetchSignatureInstallationStateFailureAction,
  START_CHECKING_FOR_INSTALLATION_IN_PROGRESS, AddInstallationStateProgressAction
} from './signature-installation-state.actions';
import {interval, Subject, timer} from 'rxjs';
import moment from 'moment';

@Injectable()
export class SignatureInstallationStateEffects {


  // noinspection JSUnusedGlobalSymbols
  startCheckingForInstallationInProgress$ = createEffect(() => this.actions$.pipe(
    ofType(START_CHECKING_FOR_INSTALLATION_IN_PROGRESS),
    switchMap(() => {
      const initialTime = new Date();
      const kill$ = new Subject();

      return timer(500, 1000).pipe(
        takeUntil(kill$),
        switchMap((numberOfInvocations) => {
          if (numberOfInvocations > 5) {
            kill$.next(1);
            return [];
          }

          return this.signatureInstallationStateService.getInProgressState()
            .pipe(
              switchMap(response => {
                if (!response?.length) {
                  return [];
                }
                const latestState = response[0];

                if (latestState.status === 'IN_PROGRESS' || moment(latestState.modifiedAt).isAfter(initialTime)) {
                  kill$.next(1);
                  return [new PollStateUntilInstallationFinishedAction(), new AddInstallationStateProgressAction(response[0])];
                }
                return [];
              })
            );
        }),
      );
    })
  ));

  // noinspection JSUnusedGlobalSymbols
  pollStateUntilInstallationFinished$ = createEffect(() => this.actions$.pipe(
    ofType(POLL_STATE_UNTIL_INSTALLATION_FINISHED),
    switchMap(() => {
      const kill$ = new Subject();

      return timer(2000, 2000).pipe(
        takeUntil(kill$),
        switchMap(() => this.signatureInstallationStateService.getInProgressState().pipe(
          switchMap(response => {
            const latestState = response?.[0];

            if (!latestState) {
              kill$.next(1);
              return [];
            }

            if (latestState.status !== 'IN_PROGRESS') {
              kill$.next(1);
            }

            return [new AddInstallationStateProgressAction(latestState)];
          })
        ))
      );
    })
  ));


  constructor(private actions$: Actions, private signatureInstallationStateService: SignatureInstallationStateService) {
  }

}
