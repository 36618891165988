import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  CHANGE_EXCLUDED_FROM_SIGNATURES,
  ChangeExcludedFromSignaturesAction,
  ChangeExcludedFromSignaturesSuccessAction,
  GET_EMPLOYEES,
  GetEmployeesAction,
  GetEmployeesFailureAction,
  GetEmployeesSuccessAction,
  SET_EMPLOYEES_PARAMS,
  SetEmployeesParamsAction,
  ChangeExcludedFromSignaturesFailureAction,
  SEND_EMPLOYEE_REQUEST,
  SendEmployeeRequestSuccessAction,
  SendEmployeeRequestFailureAction,
  SendEmployeeRequestAction,
  GET_EMPLOYEE,
  GetEmployeeAction,
  GetEmployeeSuccessAction,
  GetEmployeeFailureAction,
  UPDATE_EMPLOYEE,
  UpdateEmployeeAction,
  UpdateEmployeeSuccessAction,
  UpdateEmployeeFailureAction,
  GET_CURRENT_USER,
  GetCurrentUserSuccessAction,
  GetCurrentUserFailureAction,
  UpdateEmployeeAndInstallSignatureAction,
  UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE,
  UpdateEmployeeAndInstallSignatureSuccessAction,
  UpdateEmployeeAndInstallSignatureFailureAction,
  SYNC_GOOGLE_EMPLOYEES,
  SyncGoogleEmployeesSuccessAction,
  SyncGoogleEmployeesFailureAction,
  SYNC_GOOGLE_EMPLOYEES_SUCCESS,
  CLEAR_EMPLOYEES_PARAMS,
  GetCurrentUserAction
} from './employees.actions';
import { EmployeesService } from './employees.service';
import { PaginatedData } from '../shared/model/table';
import {
  ChangeExcludedFromSignaturesParams,
  Employee,
  EmployeeListItem,
  EmployeeListSearchParams,
  EmployeeRequest
} from './employees.interface';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class EmployeesEffects {

  getEmployees$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_EMPLOYEES),
    map((action: GetEmployeesAction) => action.payload),
    switchMap((payload: EmployeeListSearchParams) => {
      return this.employeesService.getEmployees(payload).pipe(
        switchMap((response: PaginatedData<EmployeeListItem>) => {
          return [new GetEmployeesSuccessAction(response)];
        }),
        catchError(error => of(new GetEmployeesFailureAction(error)))
      );
    })
  ));

  setEmployeeListParams$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SET_EMPLOYEES_PARAMS),
    map((action: SetEmployeesParamsAction) => action.payload),
    map((payload: EmployeeListSearchParams) => new GetEmployeesAction(payload))
  ));

  clearEmployeeListParams$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CLEAR_EMPLOYEES_PARAMS),
    map(() => new SetEmployeesParamsAction({pager: {limit: 10, page: 0}, sortBy: 'fullName'}))
  ));

  changeExcludedFromSignatures$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_EXCLUDED_FROM_SIGNATURES),
    map((action: ChangeExcludedFromSignaturesAction) => action.payload),
    switchMap((payload: ChangeExcludedFromSignaturesParams) => {
      return this.employeesService.changeExcludedFromSignatures(payload).pipe(
        switchMap((response) => {
          return [new ChangeExcludedFromSignaturesSuccessAction(response)];
        }),
        catchError(error => of(new ChangeExcludedFromSignaturesFailureAction(error)))
      );
    })
  ));

  sendEmployeeRequest$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SEND_EMPLOYEE_REQUEST),
    map((action: SendEmployeeRequestAction) => action.payload),
    switchMap((payload: EmployeeRequest) => {
      return this.employeesService.sendEmployeeRequest(payload).pipe(
        switchMap((response) => {
          return [new SendEmployeeRequestSuccessAction(response)];
        }),
        catchError(error => of(new SendEmployeeRequestFailureAction(error)))
      );
    })
  ));

  updateEmployee$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_EMPLOYEE),
    map((action: UpdateEmployeeAction) => action.payload),
    switchMap((payload: Employee) => {
      this.toastr.success(this.translate.instant('employee.saveSuccess'));
      this.router.navigate(['employees']);
      return this.employeesService.updateEmployee(payload).pipe(
        switchMap((response) => {
          return [new UpdateEmployeeSuccessAction(response)];
        }),
        catchError(error => of(new UpdateEmployeeFailureAction(error)))
      );
    })
  ));

  getEmployee$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_EMPLOYEE),
    map((action: GetEmployeeAction) => action.payload),
    switchMap((payload: string) => {
      return this.employeesService.getEmployee(payload).pipe(
        switchMap((response: any) => {
          return [new GetEmployeeSuccessAction(response)];
        }),
        catchError(error => of(new GetEmployeeFailureAction(error)))
      );
    })
  ));

  getCurrentUser$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_CURRENT_USER),
    switchMap(() => {
      return this.employeesService.getCurrentUser().pipe(
        switchMap((response: any) => {
          if(response.email === this.authService.appUser?.email)
          this.authService.changeAvatarUrl(response.thumbnailPhotoUrl);
          return [new GetCurrentUserSuccessAction(response)];
        }),
        catchError(error => of(new GetCurrentUserFailureAction(error)))
      );
    })
  ));

  updateEmployeeAndInstallSignature$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(UPDATE_EMPLOYEE_AND_INSTALL_SIGNATURE),
    map((action: UpdateEmployeeAndInstallSignatureAction) => action.payload),
    switchMap((payload: Employee) => {
      return this.employeesService.updateEmployeeAndInstallSignature(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('employee.saveAndInstallSuccess'));
          return [
            new UpdateEmployeeAndInstallSignatureSuccessAction(response),
            new GetCurrentUserAction()
          ];
        }),
        catchError(error => of(new UpdateEmployeeAndInstallSignatureFailureAction(error)))
      );
    })
  ));

  syncGoogleEmployees$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SYNC_GOOGLE_EMPLOYEES),
    switchMap(() => {
      return this.employeesService.syncGoogleEmployees().pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('employee.sync-googleSuccess'));
          return [new SyncGoogleEmployeesSuccessAction()];
        }),
        catchError(error => of(new SyncGoogleEmployeesFailureAction(error)))
      );
    })
  ));

  syncGoogleEmployeesSuccess$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SYNC_GOOGLE_EMPLOYEES_SUCCESS),
    map(() => new GetEmployeesAction({pager: {limit: 10, page: 0}, sortBy: 'fullName'}))
  ));

  constructor(private actions$: Actions,
              private employeesService: EmployeesService,
              private authService: AuthService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private router: Router) {
  }

}
