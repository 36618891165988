<small>
  {{'footers.aliasInfo' | translate}}
  <a href="https://support.google.com/mail/answer/22370" target="_blank">
    {{'more' | translate}}
  </a>
</small>

<form [formGroup]="form" (ngSubmit)="submit()" class="form">
  <mat-form-field appearance="fill" *ngIf="employees">
    <mat-label>{{'user.email' | translate}}</mat-label>
    <mat-select formControlName="email">
      <mat-option *ngFor="let employee of employees" [value]="employee.email">
        {{employee.email}} ({{employee.fullName}})
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.hasError('required', ['email'])">{{'fieldRequired' | translate}}</mat-error>
    <mat-error *ngIf="form.hasError('email', ['email'])">{{'emailInvalid' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'user.alias' | translate}}</mat-label>
    <input matInput formControlName="alias" type="text">
    <mat-error *ngIf="form.hasError('email', ['alias'])">{{'emailInvalid' | translate}}</mat-error>
  </mat-form-field>

  <div class="actions">
    <button
      mat-raised-button
      [disabled]="form.invalid || updating"
      type="submit"
    >
      {{(updating ? 'footers.setting' : 'footers.set') | translate}}
    </button>
  </div>
</form>
