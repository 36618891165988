import { Action } from '@ngrx/store';
import { AddDisclaimerObject, Disclaimer, Disclaimers} from './disclaimers.interface';

export const GET_DISCLAIMERS = 'GET_DISCLAIMERS';
export const GET_DISCLAIMERS_SUCCESS = 'GET_DISCLAIMERS_SUCCESS';
export const GET_DISCLAIMERS_FAILURE = 'GET_DISCLAIMERS_FAILURE';

export const CHANGE_DISCLAIMER_SUCCESS = 'CHANGE_DISCLAIMER_SUCCESS';
export const CHANGE_DISCLAIMER_FAILURE = 'CHANGE_DISCLAIMER_FAILURE';
export const CHANGE_DISCLAIMER = 'CHANGE_DISCLAIMER';

export const REMOVE_DISCLAIMER_SUCCESS = 'REMOVE_DISCLAIMER_SUCCESS';
export const REMOVE_DISCLAIMER_FAILURE = 'REMOVE_DISCLAIMER_FAILURE';
export const REMOVE_DISCLAIMER = 'REMOVE_DISCLAIMER';

export const ADD_DISCLAIMER_SUCCESS = 'ADD_DISCLAIMER_SUCCESS';
export const ADD_DISCLAIMER_FAILURE = 'ADD_DISCLAIMER_FAILURE';
export const ADD_DISCLAIMER = 'ADD_DISCLAIMER';

export class GetDisclaimersAction implements Action {
  readonly type = GET_DISCLAIMERS;

  constructor() {
  }
}

export class GetDisclaimersSuccessAction implements Action {
  readonly type = GET_DISCLAIMERS_SUCCESS;

  constructor(public payload: Disclaimers) {
  }
}

export class GetDisclaimersFailureAction implements Action {
  readonly type = GET_DISCLAIMERS_FAILURE;

  constructor(public payload: any) {
  }
}

export class ChangeDisclaimerAction implements Action {
  readonly type = CHANGE_DISCLAIMER;

  constructor(public payload: Disclaimer) {
  }
}

export class ChangeDisclaimerSuccessAction implements Action {
  readonly type = CHANGE_DISCLAIMER_SUCCESS;

  constructor(public payload: Disclaimer) {
  }
}

export class ChangeDisclaimerFailureAction implements Action {
  readonly type = CHANGE_DISCLAIMER_FAILURE;

  constructor(public payload: any) {
  }
}

export class RemoveDisclaimerAction implements Action {
  readonly type = REMOVE_DISCLAIMER;

  constructor(public payload: string) {
  }
}

export class RemoveDisclaimerSuccessAction implements Action {
  readonly type = REMOVE_DISCLAIMER_SUCCESS;

  constructor(public payload: string ) {
  }
}

export class RemoveDisclaimerFailureAction implements Action {
  readonly type = REMOVE_DISCLAIMER_FAILURE;

  constructor(public payload: any) {
  }
}

export class AddDisclaimerAction implements Action {
  readonly type = ADD_DISCLAIMER;

  constructor(public payload: AddDisclaimerObject) {
  }
}

export class AddDisclaimerSuccessAction implements Action {
  readonly type = ADD_DISCLAIMER_SUCCESS;

  constructor(public payload: Disclaimer) {
  }
}

export class AddDisclaimerFailureAction implements Action {
  readonly type = ADD_DISCLAIMER_FAILURE;

  constructor(public payload: any) {
  }
}

export type DisclaimersActions =
  | GetDisclaimersAction
  | GetDisclaimersSuccessAction
  | GetDisclaimersFailureAction
  | ChangeDisclaimerAction
  | ChangeDisclaimerSuccessAction
  | ChangeDisclaimerFailureAction
  | RemoveDisclaimerAction
  | RemoveDisclaimerSuccessAction
  | RemoveDisclaimerFailureAction
  | AddDisclaimerAction
  | AddDisclaimerSuccessAction
  | AddDisclaimerFailureAction;
