import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {PaginatedData} from '../shared/model/table';
import {
  ChangeExcludedFromSignaturesParams, CurrentUserResponse,
  Employee, EmployeeDataImportResult, EmployeeDataImportVerificationResult,
  EmployeeListItem,
  EmployeeListSearchParams,
  EmployeeRequest, SingleEmployeeResponse
} from './employees.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor(private http: HttpClient) {
  }

  getEmployees({pager, ...params}: EmployeeListSearchParams): Observable<PaginatedData<EmployeeListItem>> {
    return this.http.get<PaginatedData<EmployeeListItem>>('/employees', {
      params: {
        page: String(pager.page),
        limit: String(pager.limit),
        ...params,
      }
    });
  }

  getAllEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>('/employees/all');
  }

  getNotExcludedEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>('/employees/not-excluded');
  }

  changeExcludedFromSignatures(payload: ChangeExcludedFromSignaturesParams): Observable<any> {
    return this.http.put(`/employees/${payload.id}`, {excludedFromSignatures: payload.excludedFromSignatures});
  }

  sendEmployeeRequest(payload: EmployeeRequest): Observable<any> {
    return this.http.post(`/employees/invite`, payload);
  }

  getEmployee(id: string): Observable<SingleEmployeeResponse> {
    return this.http.get<SingleEmployeeResponse>(`/employees/${id}`);
  }

  updateEmployee(payload: Employee): Observable<any> {
    return this.http.put(`/employees/${payload.id}`, payload);
  }

  updateEmployeeAndInstallSignature(payload: Employee): Observable<any> {
    return this.http.put(`/user`, payload);
  }

  syncGoogleEmployees(): Observable<any> {
    return this.http.post('/google-employees-sync', {});
  }

  getCurrentUser(): Observable<CurrentUserResponse> {
    return this.http.get<CurrentUserResponse>(`/user`);
  }

  validateImportData(file: File): Observable<EmployeeDataImportVerificationResult> {
    const formData = new FormData();
    formData.append('data', file);
    return this.http.post('/employees/import/validate', formData);
  }

  importEmployeeData(file: File, installSignature: boolean): Observable<EmployeeDataImportResult> {
    const formData = new FormData();
    formData.append('data', file);
    formData.append('installSignature', String(installSignature));
    return this.http.post<EmployeeDataImportResult>('/employees/import/perform', formData);
  }


  availableColumns(): Observable<string[]> {
    return this.http.get<string[]>('/employees/import/columns');
  }

}
