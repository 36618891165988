import { UntypedFormControl } from '@angular/forms';
import { isValidNumber } from 'libphonenumber-js';
import moment from 'moment';

export const validatePhoneNumber = (formControl: UntypedFormControl) =>
  isValidNumber(formControl.value.replace(/\s/, '')) ? null : {phoneNumberInvalid: true};

export const validateNonRequiredPhoneNumber = (formControl: UntypedFormControl) =>
  !formControl.value ? null : validatePhoneNumber(formControl);

export const validateFutureDate = (formControl: UntypedFormControl) =>
  new Date(formControl.value) > new Date() ? null : {dateInvalid: true};

export const validateNotPastDate = (formControl: UntypedFormControl) =>
  moment().subtract(1, 'days').endOf('day').isBefore(moment(formControl.value))
    ? null : {dateInvalid: true};

export const greaterThan = (toCompareControlName: string) => {
  return (control: UntypedFormControl): { [key: string]: boolean } | null => {
    if (!control.parent) {
      return null;
    }

    const toCompareValue = control.parent.get(toCompareControlName).value;
    if (control.value > toCompareValue) {
      return null;
    }

    return {greaterThanInvalid: true};
  };
};

export const lowerThan = (toCompareControlName: string) => {
  return (control: UntypedFormControl): { [key: string]: boolean } | null => {
    if (!control.parent) {
      return null;
    }

    const toCompareValue = control.parent.get(toCompareControlName).value;
    if (!toCompareValue || control.value < toCompareValue) {
      return null;
    }

    return {lowerThanInvalid: true};
  };
};

