import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss']
})
export class ProgressButtonComponent {
  @Input() loading = false;
  @Input() label: string;
  @Input() color: ThemePalette = 'primary';
  @Input() icon?: string;

  @Output() buttonClick = new EventEmitter<void>();

  onClick() {
    if (!this.loading) {
      this.buttonClick.emit();
    }
  }
}
