import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeesComponent } from './employees.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { AuthGuard } from '../auth/guard/auth.guard';

const routes: Routes = [{
  path: 'employees',
  component: EmployeesComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      component: EmployeeListComponent
    }
  ]
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class EmployeesRoutingModule { }
