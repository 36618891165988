import { createSelector } from '@ngrx/store';
import {
  EmployeesActions,
  GET_CURRENT_USER_SUCCESS,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_SUCCESS,
  SET_EMPLOYEES_PARAMS
} from './employees.actions';
import { EmployeesState, defaultEmployeeListPager } from './employees.interface';

export const initialState: EmployeesState = {
  employees: {
    total: 0,
    data: []
  },
  params: {
    sortBy: 'fullName',
    pager: defaultEmployeeListPager
  },
  currentEmployee: null,
  employee: null
};

export function employeesReducer(
  state: EmployeesState = initialState,
  action: EmployeesActions
): EmployeesState {
  switch (action.type) {
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload
      };
    case SET_EMPLOYEES_PARAMS:
      return {
        ...state,
        params: action.payload
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentEmployee: action.payload
      };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employee: action.payload
      };
    default: {
      return state;
    }
  }
}

export const getEmployeesState = state => state.employees;
export const getCurrentEmployeeState = state => state.currentEmployee;
export const getEmployeeState = state => state.employee;
export const getWholeEmployeeListState = (employeeListState: EmployeesState): EmployeesState => employeeListState;

export const getEmployeeListSelector = createSelector(getWholeEmployeeListState, getEmployeesState);
export const getCurrentEmployeeSelector = createSelector(getEmployeesState, getCurrentEmployeeState);
export const getEmployeeSelector = createSelector(getEmployeesState, getEmployeeState);
