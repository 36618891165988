<div class="images-wrapper">
  <div *ngIf="!isShowAddForm" class="actions">
    <a mat-raised-button (click)="showAddForm()">{{'media.images.add' | translate}}</a>
  </div>

  <div class="container" *ngIf="images && !isShowAddForm">
    <div *ngFor="let image of images">
      <app-image [image]="image"></app-image>
    </div>
  </div>

  <div *ngIf="isShowAddForm">
    <app-image-add [onClose]="hideAddForm"></app-image-add>
  </div>
</div>
