import {Component, OnInit} from '@angular/core';
import {PartnerService} from '../partner.service';
import {MatDialog} from '@angular/material/dialog';
import {PartnerCreateClientDialogComponent} from '../partner-create-client-dialog/partner-create-client-dialog.component';
import {ToastrTranslatedService} from '../../shared/toastr-translated.service';
import {Observable, ReplaySubject} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ClientWithStats} from '../../client/client.interface';
import { ReportsService } from '../../shared/reports/reports.service';

@Component({
  selector: 'app-partner-clients',
  templateUrl: './partner-clients.component.html',
  styleUrls: ['./partner-clients.component.scss']
})
export class PartnerClientsComponent implements OnInit {
  public clients: ClientWithStats[];
  public loading = true;

  public reloadClients = new ReplaySubject<number>(1);
  public clients$: Observable<ClientWithStats[]>;

  constructor(private partnerService: PartnerService,
              private dialog: MatDialog,
              private toastr: ToastrTranslatedService,
              private reportsService: ReportsService,
  ) {
  }

  openCreateClientDialog() {
    this.dialog.open(PartnerCreateClientDialogComponent)
      .afterClosed()
      .subscribe(() => {
        this.refreshClients();
        this.toastr.success('client.addSuccess');
      });
  }

  async ngOnInit() {
    this.clients$ = this.reloadClients.pipe(
      switchMap(() => this.partnerService.fetchClients())
    );
    this.refreshClients();
    this.clients$.subscribe((clients: ClientWithStats[]) => this.clients = clients);
  }

  private refreshClients = () => {
    this.reloadClients.next(1);
  }

  generateReport = () => this.reportsService.generateClientsReport(this.clients);

}
