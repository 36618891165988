import {AccountsResponse, AvailableAccount} from './accounts.interface';

export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';
export const CHANGE_ACCOUNT_SUCCESS = 'CHANGE_ACCOUNT_SUCCESS';
export const RESET_ACCOUNT = 'RESET_ACCOUNT';
export const RESET_ACCOUNT_SUCCESS = 'RESET_ACCOUNT_SUCCESS';

export const FETCH_AVAILABLE_ACCOUNT = 'FETCH_AVAILABLE_ACCOUNT';
export const FETCH_AVAILABLE_ACCOUNT_SUCCESS = 'FETCH_AVAILABLE_ACCOUNT_SUCCESS';
export const FETCH_AVAILABLE_ACCOUNT_FAILURE = 'FETCH_AVAILABLE_ACCOUNT_FAILURE';


export const CHANGE_PARTNER = 'CHANGE_PARTNER';
export const CHANGE_PARTNER_SUCCESS = 'CHANGE_PARTNER_SUCCESS';


export class ChangeAccountAction {
  readonly type = CHANGE_ACCOUNT;

  constructor(public payload: string) {
  }
}

export class ChangeAccountSuccessAction {
  readonly type = CHANGE_ACCOUNT_SUCCESS;

  constructor(public payload: string) {
  }
}

export class ResetAccountAction {
  readonly type = RESET_ACCOUNT;

  constructor() {
  }
}

export class ResetAccountSuccessAction {
  readonly type = RESET_ACCOUNT_SUCCESS;

  constructor() {
  }
}

export class FetchAvailableAccountsAction {
  readonly type = FETCH_AVAILABLE_ACCOUNT;

  constructor() {
  }
}

export class FetchAvailableAccountsSuccessAction {
  readonly type = FETCH_AVAILABLE_ACCOUNT_SUCCESS;

  constructor(public payload: AccountsResponse) {
  }
}

export class FetchAvailableAccountsFailureAction {
  readonly type = FETCH_AVAILABLE_ACCOUNT_FAILURE;

  constructor(public payload: any) {
  }
}

export class ChangePartnerAction {
  readonly type = CHANGE_PARTNER;

  constructor(public payload: string) {
  }
}

export class ChangePartnerSuccessAction {
  readonly type = CHANGE_PARTNER_SUCCESS;

  constructor(public payload: string) {
  }
}


export type AccountActions =
  | ChangeAccountAction
  | ChangeAccountSuccessAction
  | ResetAccountSuccessAction
  | ResetAccountAction
  | FetchAvailableAccountsAction
  | FetchAvailableAccountsSuccessAction
  | ChangePartnerAction
  | ChangePartnerSuccessAction
  ;
