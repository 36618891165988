<mat-form-field appearance="fill" subscriptSizing="dynamic">
  <mat-label>{{label}}</mat-label>

  <!-- the dual input field: -->
  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker"

  >
    <!-- start part -->
    <input matStartDate formControlName="from"/>
    <!-- end part -->
    <input matEndDate formControlName="to"/>
  </mat-date-range-input>

  <!-- the picker trigger button -->
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

  <!-- the actual picker -->
  <mat-date-range-picker
    #picker [calendarHeaderComponent]="CalendarRangeHeaderComponent"></mat-date-range-picker>
</mat-form-field>
