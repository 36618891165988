import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-employee-data-import-preview',
  templateUrl: './employee-data-import-preview.component.html',
  styleUrls: ['./employee-data-import-preview.component.scss']
})
export class EmployeeDataImportPreviewComponent {
  @Input() displayedColumns: string[];
  @Input() dataSource: Array<Record<string, string>>
}
