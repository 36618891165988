import {Component} from '@angular/core';
import {LoaderService} from './shared/loading/loader.service';
import {AuthService} from './auth/auth.service';

export const LANG = 'lang';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public loading: LoaderService, public auth: AuthService) {
  }
}
