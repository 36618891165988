<h1 mat-dialog-title translate>partner.addClient.header</h1>

<div mat-dialog-content class="content">

  <form [formGroup]="form" (submit)="submit()" class="form">

    <mat-form-field appearance="fill">
      <mat-label translate>partner.addClient.domain</mat-label>
      <input type="text" matInput formControlName="domain">
      <mat-error *ngIf="form.hasError('required', ['domain'])">{{ 'fieldRequired' | translate }}</mat-error>
      <mat-error *ngIf="form.hasError('httpExists', ['domain'])">{{ 'domainWithoutHttp' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label translate>partner.addClient.googleAdminEmail</mat-label>
      <input type="text" matInput formControlName="googleAdminEmail">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label translate>partner.addClient.partnerAdmin</mat-label>
      <input type="text" matInput formControlName="partnerAdminEmail">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'client.trialFinishDate' | translate}}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="trialFinishDate">
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="form.hasError('required', ['trialFinishDate'])">{{'fieldRequired' | translate}}</mat-error>
      <mat-error
        *ngIf="form.hasError('dateInvalid', ['trialFinishDate'])">{{'futureDateInvalid' | translate}}</mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="isIncludedDemoContent">{{'client.includeDemoContent' | translate}}</mat-checkbox>

  </form>

</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="close()">{{'cancel' | translate}}</button>
  <app-progress-button label="save" (buttonClick)="submit()" [loading]="sendingRequest"/>
</div>
