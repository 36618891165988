import {Component, OnInit} from '@angular/core';
import {EmployeesService} from '../../employees.service';
import {EmployeeDataImportResult, EmployeeDataImportVerificationResult} from '../../employees.interface';
import {Observable} from 'rxjs';
import {SettingsService} from '../../../settings/settings.service';
import {map} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {StartCheckingForInstallationInProgressAction} from '../../../signature-installation-state/signature-installation-state.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-employee-data-import-dialog',
  templateUrl: './employee-data-import-dialog.component.html',
  styleUrls: ['./employee-data-import-dialog.component.scss']
})
export class EmployeeDataImportDialogComponent implements OnInit {
  public fileName = '';
  public file: File;
  public parsingFile = false;
  public importingData = false;
  public verificationResult: EmployeeDataImportVerificationResult;
  public availableColumns$: Observable<string[]>;

  constructor(private readonly service: EmployeesService,
              private readonly store: Store,
              private readonly dialogRef: MatDialogRef<any>,
              private readonly settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.availableColumns$ = this.settingsService.getEmployeeSettings()
      .pipe(
        map(res => res.map(e => e.name))
      );
  }

  onFileSelected(event: Event) {
    this.parsingFile = true;
    this.verificationResult = undefined;
    const file: File = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.file = file;
      this.fileName = file.name;
      this.service.validateImportData(file)
        .subscribe(res => {
          this.verificationResult = res;
          return this.parsingFile = false;
        });
    }
  }

  importData(installSignature: boolean) {
    if (this.file) {
      this.importingData = true;
      this.store.dispatch(new StartCheckingForInstallationInProgressAction());
      this.service.importEmployeeData(this.file, installSignature)
        .subscribe((result) => {
          this.importingData = false;
          this.close(result);
        });
    }

  }

  close(result?: EmployeeDataImportResult) {
    this.dialogRef.close(result);
  }
}
