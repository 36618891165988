import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddImageObject, ChangeImageObject, Image } from './media.interface';
import { ChangeBannerObject } from '../addons/banners/banners.interface';

@Injectable({providedIn: 'root'})
export class ImagesService {

  constructor(private http: HttpClient) {
  }

  getImages = (): Observable<Image[]> => {
    return this.http.get<Image[]>('/media/images');
  };

  removeImage = (id: string): Observable<any> => {
    return this.http.delete(`/media/images/${id}`);
  };

  addImage = (payload: AddImageObject): Observable<Image> => {
    const formData = new FormData();
    formData.append('image', payload.file);
    formData.append('name', payload.name);

    return this.http.post<Image>('/media/images', formData)
      .pipe(map(image => ({id: image.id, ...image} as Image)));
  };

  changeImage = ( image: ChangeImageObject): Observable<ChangeImageObject> => {
    return this.http.put(`/media/images/${image.id}`, {name: image.name})
      .pipe(
        map(() => (image))
      );
  };

}
