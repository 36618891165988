import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {getAccountContextStateSelector} from '../../accounts/accounts.reducer';
import {catchError, switchMap, take} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CurrentDomainInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService,
              private toastr: ToastrService,
              private translate: TranslateService,
              private readonly store: Store) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(getAccountContextStateSelector)
      .pipe(
        take(1),
        switchMap((context) => {
          const headers = {} as Record<string, string>;

          if (context.currentAccount) {
            headers.domain = context.currentAccount;
          }
          if (context.currentPartnerId) {
            headers.partner = context.currentPartnerId;
          }


          const apiReq = request.clone({
            setHeaders: headers
          });
          return next.handle(apiReq)
            .pipe(catchError((e) => this.serviceError(e)));
        })
      );
  }

  private serviceError(e) {
    console.error(e);
    this.toastr.error(this.translate.instant('error'));
    return throwError(e.message);
  }

}
