import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AnalyticsService} from './analytics.service';
import {Observable, Subject} from 'rxjs';
import {AnalStatsResponse, Top10Users, TopUser, TotalClicks} from './analytics.interface';
import {map, takeUntil} from 'rxjs/operators';
import {BannersModule} from '../addons/banners/banners.module';
import {DisclaimersModule} from '../addons/disclaimers/disclaimers.module';
import {LinksModule} from '../addons/links/links.module';
import {MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup} from '@angular/material/button-toggle';
import moment from 'moment';
import {StoreExtendedService} from '../shared/store-extended.service';
import {GetAdvancedSettingsAction} from '../settings/settings.actions';
import {RangeCalendarComponent} from '../shared/range-calendar/range-calendar.component';
import {FormControl, FormGroup} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatChip} from '@angular/material/chips';

@Component({
  selector: 'app-analytics-dashboard',
  standalone: true,
  imports: [
    SharedModule,
    BannersModule,
    DisclaimersModule,
    LinksModule,
    RangeCalendarComponent,
    RouterLink,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatChip
  ],
  templateUrl: './analytics-dashboard.component.html',
  styleUrl: './analytics-dashboard.component.scss'
})
export class AnalyticsDashboardComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public analyticsResponse?: AnalStatsResponse;
  public totalClicks?: TotalClicks;
  public top10Users: Top10Users;

  public dateRangeForm = new FormGroup({
    from: new FormControl(moment().startOf('month').toDate()),
    to: new FormControl(moment().toDate()),
  });

  public displayedColumns = ['userEmail', 'clicks', 'links'];
  public dataSource: MatTableDataSource<TopUser>;

  public loadingData = false;
  public analyticLinksEnabled$: Observable<boolean>;


  constructor(private readonly service: AnalyticsService,
              private readonly store: StoreExtendedService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();

    this.dateRangeForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (!value.from || !value.to) {
          return;
        }
        this.loadData();

      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadData() {
    this.loadingData = true;

    this.analyticLinksEnabled$ = this.store.dispatchAndWaitForResult(new GetAdvancedSettingsAction())
      .pipe(
        takeUntil(this.destroy$),
        map(({success, payload}) => success && payload.analyticLinksEnabled),
      );

    this.service.findTotalClicks(this.getDateRange())
      .subscribe((data) => {
        this.analyticsResponse = data;
        this.top10Users = data.top10;
        this.totalClicks = data.clicks;
        this.dataSource.data = this.top10Users.topTotalClicks;

        this.loadingData = false;
      });
  }

  onTop10ResourceChange($event: MatButtonToggleChange) {
    switch ($event.value) {
      case 'ALL':
        return this.dataSource.data = this.top10Users.topTotalClicks;
      case 'BANNER':
        return this.dataSource.data = this.top10Users.topAddonBannerClicks;
      case 'LINK':
        return this.dataSource.data = this.top10Users.topAddonLinkClicks;
      case 'TEMPLATE':
        return this.dataSource.data = this.top10Users.topTemplateClicks;
      default:
        console.error('Unknown event type', $event.value);
    }
  }

  private getDateRange() {
    const {from, to} = this.dateRangeForm.getRawValue()
    return {
      from: moment(from).startOf('day').toDate(),
      to: moment(to).endOf('day').toDate()
    };
  }

  assertColumnItemType(item: TopUser): TopUser {
    return item;
  }
}
