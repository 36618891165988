<form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <mat-label>{{'addons.links.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.links.url' | translate}}</mat-label>
    <input matInput formControlName="url" type="text">
    <mat-error *ngIf="form.hasError('required', ['url'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.links.text' | translate}}</mat-label>
    <input matInput formControlName="text" type="text">
    <mat-error *ngIf="form.hasError('required', ['text'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <div class="actions">
    <button mat-raised-button (click)="cancel()">{{'cancel' | translate}}</button>
    <button mat-raised-button type="submit">{{'save' | translate}}</button>
  </div>
</form>
