import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Plan} from './plan';
import {environment} from '../../environments/environment';
import {FinanceSettings} from '../finance/finance';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  stripe;

  constructor(private http: HttpClient) {
    // @ts-ignore
    this.stripe = Stripe(environment.stripeKey);
  }

  getAll = () => this.http.get<Plan[]>('/plan');
  buy = (priceId: string) => this.http.post('/plan/checkout', {priceId});
  settings = () => this.http.post<FinanceSettings>('/plan/settings', {});

  redirectToCheckout = async (sessionId: string) => this.stripe.redirectToCheckout({sessionId}).then(console.log);
}
