import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {SuperAdminPartnersComponent} from './super-admin-partners/super-admin-partners.component';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {superAdminPartnersReducer} from './super-admin-partners.reducer';
import {SuperAdminPartnersEffects} from './super-admin-partners.effects';
import { AddPartnerDialogComponent } from './add-partner-dialog/add-partner-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    SuperAdminPartnersComponent,
    AddPartnerDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    StoreModule.forFeature('superAdminPartners', superAdminPartnersReducer),
    EffectsModule.forFeature([SuperAdminPartnersEffects]),
    MatProgressSpinnerModule,
  ]
})
export class SuperAdminPartnersModule {
}
