import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AddImageAction } from '../images.actions';

@Component({
  selector: 'app-image-add',
  templateUrl: './image-add.component.html',
  styleUrls: ['./image-add.component.scss']
})
export class ImageAddComponent implements OnInit {

  @Input() onClose;

  file;
  form: UntypedFormGroup;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      file: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.store.dispatch(new AddImageAction(this.form.value));
      this.onClose();
    } else {
      this.form.markAllAsTouched();
    }
  }

  fileChange = ({file}) => {
    this.file = file;
    this.form.patchValue({file});
  }

  cancel = () => {
    this.fillInitialForm();
    this.onClose();
  }

}
