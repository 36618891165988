import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeSetting, PredefinedEmployeeSetting } from '../settings/settings.interface';
import { ChangeEmployeeSettingsAction, GetEmployeeSettingsAction, GetPredefinedEmployeeSettingsAction } from '../settings/settings.actions';
import { getEmployeeSettingsSelector, getPredefinedEmployeeSettingsSelector } from '../settings/settings.reducer';
import { EmployeeSettingsFormDialogComponent } from './employee-settings-form-dialog/employee-settings-form-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { scrollToBottom } from '../shared/utils/window.utils';

@Component({
  selector: 'app-employee-settings',
  templateUrl: './employee-settings.component.html',
  styleUrls: ['./employee-settings.component.scss']
})
export class EmployeeSettingsComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({
    employeeSettings: new FormArray([]),
  });

  predefinedEmployeeSettingsForm: UntypedFormGroup = new UntypedFormGroup({
    predefinedEmployeeSettings: new UntypedFormControl(false),
  });

  predefinedEmployeeSettings: PredefinedEmployeeSetting[];

  constructor(private store: Store,
              private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetPredefinedEmployeeSettingsAction());
    this.store.select(getPredefinedEmployeeSettingsSelector).subscribe((predefinedEmployeeSettings: PredefinedEmployeeSetting[]) => {
      if (predefinedEmployeeSettings) {
        this.predefinedEmployeeSettings = predefinedEmployeeSettings;
        this.store.dispatch(new GetEmployeeSettingsAction());
      }
    });

    this.store.select(getEmployeeSettingsSelector).subscribe((employeeSettings: EmployeeSetting[]) => {
      if (employeeSettings) {
        const defaultPredefinedEmployeeSettings = [];
        this.employeeSettingsList.clear();
        employeeSettings.forEach(setting => {
          this.addEmployeeSetting(setting.name, setting.overridable, setting.defaultValue, setting.mandatory, setting.label);
          const predefinedEmployeeSetting = this.predefinedEmployeeSettings.find(item => item.name === setting.name);
          if (predefinedEmployeeSetting) {
            defaultPredefinedEmployeeSettings.push(predefinedEmployeeSetting);
          }
        });
        this.predefinedEmployeeSettingsForm.setValue({predefinedEmployeeSettings: defaultPredefinedEmployeeSettings});
      }
    });
  }

  get employeeSettingsList() {
    return this.form.get('employeeSettings') as FormArray;
  }

  onChangePredefinedEmployeeSettings = (newSettings) => {
    const selectedSettings = this.form.get('employeeSettings').value;

    if (newSettings.length > selectedSettings.length) {
      newSettings.forEach((newSetting) => {
        const existingSetting = selectedSettings.find(setting => setting.name === newSetting.name);
        if (!existingSetting) {
          this.addEmployeeSetting(newSetting.name, newSetting.overridableByDefault, '',  false, newSetting.label);
        }
      });
    } else {
      for (let i = 0; i < selectedSettings.length; i++) {
        const existingSetting = newSettings.find(setting => setting.name === selectedSettings[i].name);
        if (!existingSetting) {
          this.removeEmployeeSetting(i);
        }
      }
    }
  }

  addEmployeeSetting = (name, overridable, defaultValue, mandatory, label) => {
    this.employeeSettingsList.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(name, Validators.required),
        defaultValue: new UntypedFormControl(defaultValue),
        overridable: new UntypedFormControl(overridable),
        mandatory: new UntypedFormControl(mandatory),
        label: new UntypedFormControl(label),
      })
    );
  }

  removeEmployeeSetting(index: number) {
    this.employeeSettingsList.removeAt(index);
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.store.dispatch(new ChangeEmployeeSettingsAction(this.form.value.employeeSettings));
    } else {
      this.form.markAllAsTouched();
    }
  }

  addNewEmployeeSetting = () => {
    this.dialog.open(EmployeeSettingsFormDialogComponent, {})
      .afterClosed()
      .subscribe(data => {
          if (data) {
            const { name, label, defaultValue, overridable, mandatory} = data;
            this.addEmployeeSetting(name, overridable, defaultValue, mandatory, label);
            const newPredefinedSetting = { id: name, name, overridableByDefault: true };
            this.predefinedEmployeeSettings = [...this.predefinedEmployeeSettings, newPredefinedSetting];

            this.predefinedEmployeeSettingsForm.setValue({predefinedEmployeeSettings: [
                ...this.predefinedEmployeeSettingsForm.get('predefinedEmployeeSettings').value,
                newPredefinedSetting
              ]
            }, { emitEvent: true });

            this.toastr.success(this.translate.instant('settings.addNewSuccess'));
            scrollToBottom();
          }
        }
      );
  }

}
