import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-wizard-user',
  templateUrl: './wizard-user.component.html',
  styleUrls: ['./wizard-user.component.scss']
})
export class WizardUserComponent {

  constructor(private router: Router) {
  }

  submitted = (user: any) => this.router.navigate(['wizard/final'], {state: {internal: {user}}});
}
