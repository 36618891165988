<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
  <mat-sidenav opened="true">

    <app-trial-info/>
    
    <a class="nav-link" routerLink="/partner/clients" routerLinkActive="active">
      <button mat-button class="menu-button">
        <mat-icon>person_pin_circle</mat-icon>
        <span>{{'partner.menu.clients' | translate}}</span>
      </button>
    </a>

    <a class="nav-link" [routerLink]="['/accounts']" *ngIf="auth.canChangeAccounts">
      <button mat-button class="menu-button">
        <mat-icon>supervisor_account</mat-icon>
        <span>{{'menu.changeAccount' | translate}}</span>
      </button>
    </a>

    <a class="nav-link" (click)="auth.logOut()" [routerLinkActive]="'active'">
      <button mat-button class="menu-button">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{'menu.logout' | translate}}</span>
      </button>
    </a>

    <div class="language-selector">
      <mat-form-field appearance="fill">
        <mat-select [value]="selectedLanguage" (selectionChange)="changeLanguage($event)">
          <mat-option *ngFor="let language of languages" [value]="language">{{language.toUpperCase()}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-sidenav>
</nav>

<div class="main-content">
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>
