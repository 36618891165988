import { Component, OnInit } from '@angular/core';
import { SignaturesService } from '../signatures.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-placeholders',
  templateUrl: './placeholders.component.html',
  styleUrls: ['./placeholders.component.scss']
})
export class PlaceholdersComponent implements OnInit {

  companyPlaceholders;
  userPlaceholders;
  phoneNumberPlaceholders;
  otherPlaceholders;
  placeholders;

  constructor(
    private service: SignaturesService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
  }

  async ngOnInit() {
    this.service.placeholders().toPromise().then((placeholders: string[]) => {
      this.placeholders = placeholders;

      this.companyPlaceholders = placeholders.filter(placeholder => placeholder.startsWith('${company'));
      this.userPlaceholders = placeholders.filter(placeholder => placeholder.startsWith('${user') || placeholder.startsWith('${job'));
      this.phoneNumberPlaceholders = [
        ...placeholders.filter(placeholder => placeholder.includes('obileNumber')),
        ...placeholders.filter(placeholder => placeholder.includes(`honeNumber`))
      ];

      const usedPlaceholders = [...this.companyPlaceholders, ...this.userPlaceholders, ...this.phoneNumberPlaceholders];
      this.otherPlaceholders = placeholders.filter(placeholder => !usedPlaceholders.includes(placeholder));
    });
  }

  copyPlaceholderToClipboard = (placeholder: string) => {
    navigator.clipboard.writeText(placeholder);
    this.toastr.success(this.translate.instant('footers.copyPlaceholderSuccess'));
  };

}
