import {Component, OnInit} from '@angular/core';
import {UserService} from '../../users/users.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-subscription-success',
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.scss']
})
export class SubscriptionSuccessComponent implements OnInit {
  private times = 0;

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit(): void {
    this.checkUser();
  }

  checkUser = () => {
    this.userService.current().toPromise().then(u => {
      if (u.planId) {
        return this.router.navigate(['/']);
      }
      if (this.times < 10) {
        this.times++;
        setTimeout(this.checkUser, 3000);
      } else {
        return this.router.navigate(['/plan/fail']);
      }
    });
  };
}
