<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
    <mat-header-cell *matHeaderCellDef>{{disCol}}</mat-header-cell>
    <mat-cell *matCellDef="let element "> {{element[disCol]}}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
