import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '../users/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {Unit} from '../users/user';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit {

  displayedColumns = ['name', 'orgUnitPath', 'assign'];
  dataSource: MatTableDataSource<Unit>;
  @ViewChild(MatSort) sort: MatSort;
  @Input() updating ? = false;
  @Output() clicked = new EventEmitter<string>();

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();
  }

  loadData() {
    this.userService.units().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }

  assign(orgUnitPath: string) {
    return this.clicked.emit(orgUnitPath);
  }

}
