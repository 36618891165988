<div *ngIf="!isShowAddForm" class="actions">
  <a mat-raised-button (click)="showAddForm()">{{'addons.links.add' | translate}}</a>
</div>

<div class="container" *ngIf="links && !isShowAddForm">
  <div *ngFor="let link of links.links">
    <app-link [link]="link"></app-link>
  </div>
</div>

<div *ngIf="isShowAddForm">
  <app-link-add [onClose]="hideAddForm"></app-link-add>
</div>
