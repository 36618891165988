<mat-toolbar class="header">
  <span>{{footer?.name}}</span>
</mat-toolbar>

<app-loading *ngIf="!footer"></app-loading>

<form [formGroup]="form" class="form" (ngSubmit)="save()" *ngIf="footer">

  <mat-form-field appearance="fill" class="name-form-field">
    <mat-label>{{'footers.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <angular-editor matInput formControlName="content" [config]="config"></angular-editor>

  <app-addons [form]="form"></app-addons>

  <mat-form-field appearance="fill">
    <mat-label>{{'footers.userAvatarWidth' | translate}}</mat-label>
    <input matInput formControlName="userAvatarWidth" type="number" min="0">
    <span matSuffix>px</span>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'footers.userAvatarBorderRadius' | translate}}</mat-label>
    <input matInput formControlName="userAvatarBorderRadius" type="number" min="0" max="50">
    <span matSuffix>%</span>
  </mat-form-field>

  <div class="actions">
    <button mat-raised-button (click)="goToSignatures()">{{'cancel' | translate}}</button>
    <button mat-stroked-button color="primary" (click)="saveAndInstall()"
            [disabled]="!form.valid || updating">{{(updating ? 'saving' : 'saveAndInstall') | translate}}
    </button>
    <button mat-raised-button type="submit" color="primary"
            [disabled]="!form.valid || updating">{{(updating ? 'saving' : 'save') | translate}}
    </button>
  </div>

  <app-placeholders></app-placeholders>
</form>
