<div class="employee-settings">
  <form [formGroup]="predefinedEmployeeSettingsForm"  *ngIf="predefinedEmployeeSettings">
    <mat-form-field appearance="fill" class="employee-fields">
      <mat-label>{{'settings.employeeFields' | translate}}</mat-label>
      <mat-select
        formControlName="predefinedEmployeeSettings"
        (selectionChange)="onChangePredefinedEmployeeSettings($event.value)"
        multiple
      >
        <mat-option
          *ngFor="let predefinedEmployeeSetting of predefinedEmployeeSettings"
          [value]="predefinedEmployeeSetting"
        >
          {{predefinedEmployeeSetting.name}}
        </mat-option>
        <a mat-raised-button (click)="addNewEmployeeSetting()" class="new-setting-button">
          {{'settings.new' | translate}}
        </a>
      </mat-select>
    </mat-form-field>
  </form>

  <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
    <table class="table">
      <thead>
      <tr>
        <th>{{'settings.customField' | translate}}</th>
        <th>{{'settings.mandatory' | translate}}</th>
        <th>{{'settings.overridable' | translate}}</th>
        <th>{{'settings.defaultValue' | translate}}</th>
        <th>{{'settings.customLabel' | translate}}</th>
      </tr>
      </thead>
      <tbody class="list" formArrayName="employeeSettings">
      <tr *ngFor="let employeeSetting of employeeSettingsList.controls" [formGroup]="employeeSetting">
        <td>{{employeeSetting.value.name}}</td>
        <td><mat-checkbox formControlName="mandatory"></mat-checkbox></td>
        <td><mat-checkbox formControlName="overridable"></mat-checkbox></td>
        <td>
          <mat-form-field appearance="fill">
            <input matInput formControlName="defaultValue" type="text">
          </mat-form-field>
        </td>
        <td>
          <mat-form-field appearance="fill">
            <input matInput formControlName="label" type="text">
          </mat-form-field>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="actions">
      <button mat-raised-button type="submit">{{'save' | translate}}</button>
    </div>
  </form>
</div>
