import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-wizard-choose',
  templateUrl: './wizard-choose.component.html',
  styleUrls: ['./wizard-choose.component.scss']
})
export class WizardChooseComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  setForUser = () => this.router.navigate(['/wizard/user']);

  setForGroup = () => this.router.navigate(['/wizard/group']);

  setForAll = () => this.router.navigate(['/wizard/final'], {state: {internal: {all: 'all'}}});
}
