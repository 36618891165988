import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreExtendedService {

  constructor(private store: Store,
              private actions: Actions) {
  }

  dispatch<V extends Action = Action>(action: Action) {
    this.store.dispatch(action);
  }

  select<K>(selector: (state: unknown) => K): Observable<K> {
    return this.store.select(selector);
  }

  waitForAction(actionType: string): Observable<Action> {
    return this.actions.pipe(
      ofType(actionType),
      take(1),
    );
  }


  dispatchAndWaitForResult<V extends Action = Action>(action: Action): Observable<{ success: boolean, payload?: any }> {
    this.store.dispatch(action);
    const type = action.type;
    const successAction = `${type}_SUCCESS`;
    const failureAction = `${type}_FAILURE`;

    return this.actions.pipe(
      ofType(successAction, failureAction),
      map((a: Action & { payload?: any }) => ({
        success: a.type.includes('SUCCESS'),
        payload: a.payload
      }))
    );
  }
}
