import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {GetBannersAction} from '../../addons/banners/banners.actions';
import {GetLinksAction} from '../../addons/links/links.actions';
import {GetDisclaimersAction} from '../../addons/disclaimers/disclaimers.actions';
import {getBannersSelector} from '../../addons/banners/banners.reducer';
import {Banners} from '../../addons/banners/banners.interface';
import {getLinksSelector} from '../../addons/links/links.reducer';
import {Links} from '../../addons/links/links.interface';
import {getDisclaimersSelector} from '../../addons/disclaimers/disclaimers.reducer';
import {Disclaimer, Disclaimers} from '../../addons/disclaimers/disclaimers.interface';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss']
})
export class AddonsComponent implements OnInit {
  banners: Banners;
  links: Links;
  disclaimers: Disclaimers;

  @Input() form: UntypedFormGroup;

  private readonly domParser = new DOMParser();
  private readonly disclaimerContentCache = new Map<string, string>();

  constructor(private store: Store) {
  }


  ngOnInit(): void {
    this.store.dispatch(new GetBannersAction());
    this.store.dispatch(new GetLinksAction());
    this.store.dispatch(new GetDisclaimersAction());

    this.store.select(getBannersSelector).subscribe((banners: Banners) => {
      this.banners = banners;
    });

    this.store.select(getLinksSelector).subscribe((links: Links) => {
      this.links = links;
    });

    this.store.select(getDisclaimersSelector).subscribe((disclaimers: Disclaimers) => {
      this.disclaimers = disclaimers;
    });
  }

  extractDisclaimerHtmlContent(d: Disclaimer): string {
    const cached = this.disclaimerContentCache.get(d.id);
    if (cached) {
      return cached;
    }

    const htmlContent = this.domParser
      .parseFromString(d.htmlContent, 'text/html')
      .documentElement.textContent;

    this.disclaimerContentCache.set(d.id, htmlContent);
    return htmlContent;
  }


}
