import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Plan} from './plan';
import {PlanService} from './plan.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
  buying;
  plans: Observable<Plan[]>;

  constructor(private planService: PlanService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.plans = this.planService.getAll();
  }

  buy = (id: string) => new Promise(res => res(this.buying = true))
    .then(() => this.planService.buy(id).toPromise())
    .then((r: any) => this.planService.redirectToCheckout(r.id))
    .catch(() => this.toastr.error('Can not buy plan'))
    .finally(() => this.buying = false);
}
