import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SignatureInstallationStateEffects} from './signature-installation-state.effects';
import {signatureInstallationStateReducer} from './signature-installation-state.reducer';
import {
  SignatureInstallationProgressToastComponent
} from './signature-installation-progress-toast/signature-installation-progress-toast.component';
import {SharedModule} from '../shared/shared.module';
import { SignatureInstallationErrorDialogComponent } from './signature-installation-error-dialog/signature-installation-error-dialog.component';


@NgModule({
  declarations: [
    SignatureInstallationProgressToastComponent,
    SignatureInstallationErrorDialogComponent,
  ],
  exports: [
    SignatureInstallationProgressToastComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature('signatureInstallationState', signatureInstallationStateReducer),
    EffectsModule.forFeature([SignatureInstallationStateEffects]),
  ]
})
export class SignatureInstallationStateModule { }
