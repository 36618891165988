import {Component, OnInit} from '@angular/core';
import {SignaturesService} from '../signatures.service';
import {SignaturePredefined} from '../signatures.interface';
import {Router} from '@angular/router';
import {ToastrTranslatedService} from '../../shared/toastr-translated.service';
import {AuthService} from '../../auth/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {DragulaService} from 'ng2-dragula';

@Component({
  selector: 'app-signatures-predefined',
  templateUrl: './signatures-predefined.component.html',
  styleUrls: ['./signatures-predefined.component.scss']
})
export class SignaturesPredefinedComponent implements OnInit {
  public signatures: SignaturePredefined[];
  public saving = false;
  public readonly canEdit: boolean;

  public initialLoading = true;
  public isReordering = false;
  public isSendingRequest = false;


  constructor(private readonly service: SignaturesService,
              private readonly toastr: ToastrTranslatedService,
              private readonly dialog: MatDialog,
              private readonly translate: TranslateService,
              private readonly auth: AuthService,
              private readonly router: Router) {
    this.canEdit = this.auth.isSuperAdmin;
  }

  ngOnInit(): void {
    this.reload();

  }

  async copyPredefinedToClient(id: string) {
    this.saving = true;

    const {clientSignatureId} = await this.service.copyPredefinedToClient(id).toPromise();
    await this.router.navigate(['signatures', clientSignatureId, 'edit']);
    this.toastr.success('predefined.signatureCopiedToClient');
    this.saving = false;
  }

  deletePredefined(id: string) {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('predefined.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.initialLoading = true;
          this.service.deletePredefined(id)
            .subscribe(() => {
              this.toastr.success('predefined.deletedSuccess');
              this.reload();
              this.initialLoading = false;
            });
        }
      });
  }

  private reload = () => {
    this.service.getPredefined()
      .subscribe(result => {
        this.initialLoading = false;
        this.signatures = result;
      });
  };

  public toggleReordering() {
    if (this.isReordering) {
      this.isSendingRequest = true;
      this.service.reorderPredefined(this.signatures.map(s => s.id))
        .subscribe(() => {
          this.toastr.success('predefined.signaturesReorderedSuccess');
          this.isReordering = !this.isReordering;
          this.isSendingRequest = false;
        });
    } else {
      this.isReordering = !this.isReordering;
    }
  }
}
