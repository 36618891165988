import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { AddAdminReq, UpdateAdminReq, UserRole } from './user-admins.interface';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserAdminsService {

  constructor(private httpClient: HttpClient, private auth: AuthService) { }

  private userRolePartUrl = this.auth.isSuperAdmin ? 'admins' : 'company-managers';

  public fetchAdmins(): Observable<UserRole[]> {
    return this.httpClient.get<UserRole[]>(`/admin/${this.userRolePartUrl}`);
  }

  public addAdmin(form: AddAdminReq): Observable<unknown> {
    return this.httpClient.post(`/admin/${this.userRolePartUrl}`, form);
  }

  public removeAdmin(id: string): Observable<unknown> {
    return this.httpClient.delete(`/admin/${this.userRolePartUrl}/${id}`);
  }

  public updateAdmin(id: string, form: UpdateAdminReq): Observable<unknown> {
    return this.httpClient.put(`/admin/${this.userRolePartUrl}/${id}`, form);
  }

}
