import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {EditClientDialogData} from '../../client/client.interface';
import {SignatureInstallationState} from '../signature-installation-state.interface';

@Component({
  selector: 'app-signature-installation-error-dialog',
  templateUrl: './signature-installation-error-dialog.component.html',
  styleUrls: ['./signature-installation-error-dialog.component.scss']
})
export class SignatureInstallationErrorDialogComponent {
  public readonly errors: SignatureInstallationState['installations'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public state: SignatureInstallationState
  ) {
    this.errors = this.state.installations.filter(i => i.error);
  }

}
