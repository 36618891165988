import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {Store} from '@ngrx/store';
import {
  CHANGE_ACCOUNT_SUCCESS,
  CHANGE_PARTNER_SUCCESS,
  ChangeAccountAction,
  ChangePartnerAction,
  FetchAvailableAccountsAction
} from './accounts.actions';
import {Actions, ofType} from '@ngrx/effects';
import {AvailableAccount, PartnerAccount} from './accounts.interface';
import {AccountsService} from './accounts.service';
import {getAvailableAccountsSelector, getAvailablePartnersSelector} from './accounts.reducer';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  accountForm = new FormControl('');
  filteredOptions$: Observable<AvailableAccount[]>;
  accounts$: Observable<AvailableAccount[]>;
  partners$: Observable<PartnerAccount[]>;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private store: Store,
              private actions: Actions,
              private accountsService: AccountsService,
              private router: Router) {
  }

  ngOnInit() {
    const user = this.authService.appUser;
    const isBasicUserWithSingleAccount = !user.hasPartnerAccount && user.availableDomains.length === 1;

    if (isBasicUserWithSingleAccount) {
      this.navigateToMyAccount();
      return;
    }

    this.store.dispatch(new FetchAvailableAccountsAction());

    this.accounts$ = this.store.select(getAvailableAccountsSelector);
    this.partners$ = this.store.select(getAvailablePartnersSelector);

    this.filteredOptions$ = combineLatest([this.accounts$, this.accountForm.valueChanges.pipe(startWith(''))])
      .pipe(
        map(([accounts, form]) => this.filter(accounts, form))
      );


    this.actions.pipe(
      ofType(CHANGE_ACCOUNT_SUCCESS),
      take(1),
    ).subscribe(() => {
      if (this.authService.isSuperAdmin || this.authService.isPartner || this.authService.isCompanyManager) {
        this.navigateToSignatures();
      } else {
        this.navigateToMyAccount();
      }
    });

    this.actions.pipe(
      ofType(CHANGE_PARTNER_SUCCESS),
      take(1),
    ).subscribe(() => {
      this.navigateToPartnerDashboard();
    });

  }

  private navigateToMyAccount() {
    this.router.navigate(['my-account']);
  }

  private navigateToSignatures() {
    this.router.navigate(['signatures', 'custom']);
  }

  private navigateToPartnerDashboard() {
    this.router.navigate(['partner']);
  }

  private filter = (accounts: AvailableAccount[], value: string): AvailableAccount[] => {
    const filterValue = value.toLowerCase();

    return accounts.filter(option => {
      const domain = option.domain?.toLowerCase();
      const name = option.name?.toLowerCase();

      return domain?.includes(filterValue) || name?.includes(filterValue);
    });
  }

  changeAccount(account: AvailableAccount) {
    this.store.dispatch(new ChangeAccountAction(account.domain));
  }

  goToPartnerPanel(partner: PartnerAccount) {
    this.store.dispatch(new ChangePartnerAction(partner.id));
  }
}
