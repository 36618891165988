import { createSelector } from '@ngrx/store';
import { LinksState } from './links.interface';
import {
  GET_LINKS_SUCCESS,
  REMOVE_LINK_SUCCESS,
  CHANGE_LINK_SUCCESS,
  ADD_LINK_SUCCESS,
  LinksActions
} from './links.actions';

export const initialState: LinksState = {
  links: null
};

export function linksReducer(
  state: LinksState = initialState,
  action: LinksActions
): LinksState {
  switch (action.type) {
    case GET_LINKS_SUCCESS:
      return {
        ...state,
        links: action.payload
      };
    case REMOVE_LINK_SUCCESS:
      return {
        ...state,
        links: {links: state.links.links.filter(link => link.id !== action.payload)}
      };
    case CHANGE_LINK_SUCCESS:
      return {
        ...state,
        links: {links: state.links.links.map(link => (link.id === action.payload.id
            ? {...link, name: action.payload.name, url: action.payload.url, text: action.payload.text}
            : link
          ))}
      };
      case ADD_LINK_SUCCESS:
      return {
        ...state,
        links: {links: [...state.links.links || [], action.payload]}
      };
    default: {
      return state;
    }
  }
}

export const getLinksState = state => state.links;

export const getLinksSelector = createSelector(getLinksState, getLinksState);
