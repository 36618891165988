import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { ImagesEffects } from './images.effects';
import { ImagesService } from './images.service';
import { mediaReducer } from './media.reducer';
import { MediaComponent } from './media.component';
import { ImageComponent } from './image/image.component';
import { ImageAddComponent } from './image-add/image-add.component';

@NgModule({
  declarations: [
    MediaComponent,
    ImageComponent,
    ImageAddComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('media', mediaReducer),
    EffectsModule.forFeature([ImagesEffects]),
  ],
  providers: [ImagesService],
  exports: [
    MediaComponent,
    ImageComponent,
    ImageAddComponent
  ]
})

export class MediaModule {
}
