import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from '../shared/shared.module';
import {SettingsComponent} from './settings.component';
import {EmployeeSettingsComponent} from '../employee-settings/employee-settings.component';
import {SettingsEffects} from './settings.effects';
import {SettingsService} from './settings.service';
import {settingsReducer} from './settings.reducer';
import {CompanyComponent} from '../company/company.component';
import {AdvancedSettingsComponent} from '../advanced-settings/advanced-settings.component';
import {IntegrationSettingsComponent} from '../integration-settings/integration-settings.component';
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';

@NgModule({
  declarations: [
    CompanyComponent,
    EmployeeSettingsComponent,
    AdvancedSettingsComponent,
    SettingsComponent,
    IntegrationSettingsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('settings', settingsReducer),
    EffectsModule.forFeature([SettingsEffects]),
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
  ],
  providers: [SettingsService]
})
export class SettingsModule {
}
