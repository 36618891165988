import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {SignaturePredefined} from '../signatures.interface';
import {parseFooterTemplate} from '../../shared/utils/footers.util';

@Component({
  selector: 'app-signature-predefined-item',
  templateUrl: './signature-predefined-item.component.html',
  styleUrls: ['./signature-predefined-item.component.scss']
})
export class SignaturePredefinedItemComponent implements OnInit {
  @Input() signature!: SignaturePredefined;
  @Input() canReorder = false;

  public displayParsedSignature = true;


  public parsedSignature = '';

  ngOnInit() {
    this.parsedSignature = parseFooterTemplate(this.signature.content, this.signature.placeholderVariables);
  }

  toggleDisplayParsedSignature() {
    this.displayParsedSignature = !this.displayParsedSignature;
  }
}
