<form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <app-image-uploader
      [allowedFileExtensions]="['image/jpeg', 'image/png', 'image/gif', 'image/webp']"
      (onFileChange)="fileChange($event)"
    ></app-image-uploader>
    <input matInput formControlName="file" hidden="true">
    <mat-error *ngIf="form.hasError('required', ['file'])">{{'fileRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'media.images.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <div class="actions">
    <button mat-raised-button (click)="cancel()">{{'cancel' | translate}}</button>
    <button mat-raised-button type="submit">{{'save' | translate}}</button>
  </div>
</form>
