<mat-toolbar class="header">
  <span>{{ 'analytics.header' | translate }}</span>


  <div class="date-range-wrapper">
    <app-range-calendar
      [label]="'analytics.range.label' | translate"
      [dateRangeForm]="dateRangeForm"/>
  </div>


</mat-toolbar>

<app-closable-panel variant="warning" *ngIf="(analyticLinksEnabled$ | async) === false">
  {{ 'analytics.disabled.warning' | translate }}
  <a [routerLink]="['/settings', 'advanced']">{{ 'analytics.disabled.warning2' | translate }}</a>
</app-closable-panel>

<div class="cards-wrapper">

  <mat-card class="clicks-card">
    <mat-card-content *ngIf="!loadingData && totalClicks?.templateClicks !== undefined; else loading">
      <div class="number-of-clicks">{{ totalClicks?.templateClicks }}</div>
      <div>{{ 'analytics.templateClicks' | translate }}</div>
    </mat-card-content>
  </mat-card>

  <mat-card class="clicks-card">
    <mat-card-content *ngIf="!loadingData && totalClicks?.addonBannerClicks !== undefined; else loading">
      <div class="number-of-clicks">{{ totalClicks.addonBannerClicks }}</div>

      <div>{{ 'analytics.addonBannerClicks' | translate }}</div>
    </mat-card-content>
  </mat-card>

  <mat-card class="clicks-card">
    <mat-card-content *ngIf="!loadingData && totalClicks?.addonLinkClicks !== undefined; else loading; else loading">
      <div class="number-of-clicks">{{ totalClicks.addonLinkClicks }}</div>

      <div>{{ 'analytics.addonLinkClicks' | translate }}</div>
    </mat-card-content>
  </mat-card>

  <mat-card class="clicks-card total-clicks-card">
    <mat-card-content *ngIf="!loadingData && totalClicks?.totalClicks !== undefined; else loading">
      <div class="number-of-clicks">{{ totalClicks.totalClicks }}</div>
      <div>{{ 'analytics.totalClicks' | translate }}</div>
    </mat-card-content>
  </mat-card>

</div>

<div class="table-group-wrapper">

  <mat-button-toggle-group name="resource" (change)="onTop10ResourceChange($event)" value="ALL">
    <mat-button-toggle value="ALL">{{ 'analytics.top10.all' | translate }}</mat-button-toggle>
    <mat-button-toggle value="TEMPLATE">{{ 'analytics.top10.template' | translate }}</mat-button-toggle>
    <mat-button-toggle value="BANNER">{{ 'analytics.top10.banner' | translate }}</mat-button-toggle>
    <mat-button-toggle value="LINK">{{ 'analytics.top10.link' | translate }}</mat-button-toggle>
  </mat-button-toggle-group>

</div>


<mat-table [dataSource]="dataSource" *ngIf="!loadingData && top10Users; else loading">

  <ng-container matColumnDef="userEmail">
    <mat-header-cell *matHeaderCellDef>{{ 'analytics.top10.user' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.userEmail }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="clicks">
    <mat-header-cell *matHeaderCellDef>{{ 'analytics.top10.clicks' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.clicks }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="links">
    <mat-header-cell *matHeaderCellDef>{{ 'analytics.top10.links' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ul class="links-ul">
        @for (click of assertColumnItemType(element).topResourceClicks; track click.resourceId) {
          @if (click.resourceName) {
            <li>
              <a [href]="click.resourceType === 'TEMPLATE' ? '/signatures/custom/' + click.resourceId : '/addons'"
                 target="_blank">
                <mat-chip class="link-chip-wrapper"
                          [matTooltip]="click.resourceName.length > 14 ? click.resourceName : undefined">
                  <span class="link-name">
                    {{ click.resourceName }}
                  </span>
                </mat-chip>
              </a>
            </li>
          }
        }
      </ul>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>


<ng-template #loading>
  <app-loading [hideText]="true"/>
</ng-template>




