<form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <mat-label>{{'addons.disclaimers.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <angular-editor formControlName="htmlContent" [config]="config"></angular-editor>
  <mat-error *ngIf="form.hasError('required', ['htmlContent'])">{{'fieldRequired' | translate}}</mat-error>
  <div class="actions">
    <button mat-raised-button (click)="cancel()">{{'cancel' | translate}}</button>
    <button mat-raised-button type="submit">{{'save' | translate}}</button>
  </div>
</form>
