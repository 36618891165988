import { createSelector } from '@ngrx/store';
import { DisclaimersState } from './disclaimers.interface';
import {
  GET_DISCLAIMERS_SUCCESS,
  REMOVE_DISCLAIMER_SUCCESS,
  CHANGE_DISCLAIMER_SUCCESS,
  ADD_DISCLAIMER_SUCCESS,
  DisclaimersActions
} from './disclaimers.actions';

export const initialState: DisclaimersState = {
  disclaimers: null
};

export function disclaimersReducer(
  state: DisclaimersState = initialState,
  action: DisclaimersActions
): DisclaimersState {
  switch (action.type) {
    case GET_DISCLAIMERS_SUCCESS:
      return {
        ...state,
        disclaimers: action.payload
      };
    case REMOVE_DISCLAIMER_SUCCESS:
      return {
        ...state,
        disclaimers: {disclaimers: state.disclaimers.disclaimers.filter(disclaimer => disclaimer.id !== action.payload)}
      };
    case CHANGE_DISCLAIMER_SUCCESS:
      return {
        ...state,
        disclaimers: {disclaimers: state.disclaimers.disclaimers.map(disclaimer => (disclaimer.id === action.payload.id
            ? {...disclaimer, name: action.payload.name, htmlContent: action.payload.htmlContent}
            : disclaimer
          ))}
      };
      case ADD_DISCLAIMER_SUCCESS:
      return {
        ...state,
        disclaimers: {disclaimers: [...state.disclaimers.disclaimers || [], action.payload]}
      };
    default: {
      return state;
    }
  }
}

export const getDisclaimersState = state => state.disclaimers;

export const getDisclaimersSelector = createSelector(getDisclaimersState, getDisclaimersState);
