import { Component, Input, OnInit } from '@angular/core';
import { Banner } from '../banners.interface';
import { ChangeBannerAction, RemoveBannerAction } from '../banners.actions';
import { Store } from '@ngrx/store';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {StartCheckingForInstallationInProgressAction} from '../../../signature-installation-state/signature-installation-state.actions';
import { Image } from '../../../shared/image-uploader/image.interface';
import { ImageService } from '../../../shared/image-uploader/image.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @Input() banner: Banner;
  form: UntypedFormGroup;
  editMode = false;
  file;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private translate: TranslateService,
    private toastr: ToastrService,
    public imageService: ImageService
  ) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.banner.id, Validators.required),
      file: new UntypedFormControl(''),
      name: new UntypedFormControl(this.banner.name, Validators.required),
      url: new UntypedFormControl(this.banner.url, Validators.required),
      utm: new UntypedFormControl(this.banner.utm, Validators.required),
      imageWidth: new UntypedFormControl(this.banner.imageWidth, Validators.min(0)),
      imageHeight: new UntypedFormControl(this.banner.imageHeight, Validators.min(0)),
      originalImageWidth: new UntypedFormControl(this.banner.originalImageWidth, Validators.min(0)),
      originalImageHeight: new UntypedFormControl(this.banner.originalImageHeight, Validators.min(0))
    });
  }

  changeBanner = () => {
    if (this.form.valid) {
      this.store.dispatch(new StartCheckingForInstallationInProgressAction());
      this.store.dispatch(new ChangeBannerAction(this.form.value));
      // this.changeEditMode();
    } else {
      this.form.markAllAsTouched();
    }
  }

  cancelEditBanner = () => {
    this.fillInitialForm();
    this.changeEditMode();
  }

  changeEditMode = () => {
    this.editMode = !this.editMode;
  }

  copyBannerToClipboard = () => {
    navigator.clipboard.writeText(this.banner.htmlContent);
    this.toastr.success(this.translate.instant('addons.banners.copyBannerSuccess'));
  }

  removeBanner = addonBannerId => {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('addons.banners.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.store.dispatch(new RemoveBannerAction(addonBannerId));
          }
        }
      );
  }

  fileChange = (file: Image) => {
    this.file = file.file;
    const width = file.width || this.form.value.originalImageWidth;
    const height = file.height || this.form.value.originalImageHeight;

    this.form.patchValue({
      file:  file.file,
      originalImageWidth: width,
      originalImageHeight: height,
      imageWidth: width,
      imageHeight: height,
    });
  }

}
