import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../shared/shared.module';
import { BannersEffects } from './banners.effects';
import { BannersService } from './banners.service';
import { bannersReducer } from './banners.reducer';
import { BannersComponent } from './banners.component';
import { BannerComponent } from './banner/banner.component';
import { BannerAddComponent } from './banner-add/banner-add.component';

@NgModule({
  declarations: [
    BannerComponent,
    BannerAddComponent,
    BannersComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('banners', bannersReducer),
    EffectsModule.forFeature([BannersEffects]),
  ],
  providers: [BannersService],
  exports: [
    BannerComponent,
    BannerAddComponent,
    BannersComponent
  ]
})
export class BannersModule {
}
