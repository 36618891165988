import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SuperAdminPartnersService} from './super-admin-partners.service';
import {
  FETCH_SUPER_ADMIN_PARTNERS,
  FetchSuperAdminPartnersFailureAction,
  FetchSuperAdminPartnersSuccessAction,
  SAVE_SUPER_ADMIN_PARTNER,
  SaveSuperAdminPartnersAction,
  SaveSuperAdminPartnersFailureAction,
  SaveSuperAdminPartnersSuccessAction
} from './super-admin-partners.actions';
import {Observable} from 'rxjs';
import {SaveSuperAdminRequest} from './super-admin-partners.interface';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class SuperAdminPartnersEffects {
  constructor(private actions$: Actions,
              private service: SuperAdminPartnersService,
  ) {
  }


  fetchPartners$ = createEffect(() => this.actions$.pipe(
    ofType(FETCH_SUPER_ADMIN_PARTNERS),
    switchMap(() => {
      return this.service.fetchAll().pipe(
        switchMap((response) => {
          return [new FetchSuperAdminPartnersSuccessAction(response)];
        }),
        catchError(error => of(new FetchSuperAdminPartnersFailureAction(error)))
      );
    })
  ));

  createPartner$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(SAVE_SUPER_ADMIN_PARTNER),
    map((action: SaveSuperAdminPartnersAction) => action.payload),
    switchMap((payload: SaveSuperAdminRequest) => {
      return this.service.create(payload).pipe(
        switchMap((response) => {
          return [new SaveSuperAdminPartnersSuccessAction(response)];
        }),
        catchError(error => of(new SaveSuperAdminPartnersFailureAction(error)))
      );
    })
  ));

}
