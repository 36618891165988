<div class="signature-wrapper">
  <div class="signature-header">
    <div class="name-default-wrapper">
      <mat-icon *ngIf="canReorder">drag_indicator</mat-icon>
      <h4>{{ signature.name }}</h4>
    </div>
  </div>

  <div class="signature-content-wrapper">
    <div
      *ngIf="!displayParsedSignature"
      [innerHTML]="signature.content | safeHtml" class="signature-content"></div>

    <div
      *ngIf="displayParsedSignature"
      [innerHTML]="parsedSignature | safeHtml" class="signature-content"></div>
  </div>
</div>

<div class="signature-actions" [style.opacity]="canReorder ? 0 : 1">

  <ng-content></ng-content>
  
  <button
    [matTooltip]="'predefined.toggle' | translate"
    mat-icon-button (click)="toggleDisplayParsedSignature()">
    <mat-icon title="{{'predefined.toggle' | translate}}">{{ displayParsedSignature ? 'code' : 'image' }}</mat-icon>
  </button>

</div>
