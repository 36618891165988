import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {SignaturesService} from '../signatures.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../users/users.service';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {AuthService} from '../../auth/auth.service';
import {Store} from '@ngrx/store';
import {StartCheckingForInstallationInProgressAction} from '../../signature-installation-state/signature-installation-state.actions';

@Component({
  selector: 'app-signature-details',
  templateUrl: './signature-details.component.html',
  styleUrls: ['./signature-details.component.scss']
})
export class SignatureDetailsComponent implements OnInit {
  @ViewChild('iframePreview') iframe: ElementRef;
  footer;
  updating;
  deleting;

  constructor(private service: SignaturesService, private auth: AuthService, private route: ActivatedRoute,
              private userService: UserService, private toastr: ToastrService, private router: Router,
              private readonly store: Store,
              private translate: TranslateService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.service.byId(this.route.snapshot.params.id).toPromise().then((f: any) => {
      this.writeToFrame(f.content);
      this.footer = f;
    });
  }

  setForAll = () => {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('signatures.setAllConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.assign({});
          }
        }
      );
  };

  setForUser = (value: any) => this.assign({...value});
  assignToGroup = (group?: string) => this.assign({group});
  assignToUnit = (unit?: string) => this.assign({unit});

  assign(body) {
    this.store.dispatch(new StartCheckingForInstallationInProgressAction());
    this.updating = true;
    this.userService.updateAll({id: this.route.snapshot.params.id, ...body}).toPromise()
      .then(() => this.toastr.success(this.translate.instant('footers.assigned')))
      .finally(() => this.updating = false);
  }

  delete() {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('footers.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.deleting = true;
            this.service.delete(this.route.snapshot.params.id).toPromise()
              .then(() => this.toastr.success(this.translate.instant('footers.deleted')))
              .then(() => this.goToSignatures())
              .finally(() => this.deleting = false);
          }
        }
      );
  }

  writeToFrame(content) {
    const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    doc.open();
    doc.write(content);
    doc.close();
  }

  setForMe() {
    this.assign({email: this.auth.appUser.email});
  }

  goToSignatures = () => {
    this.router.navigate(['signatures']);
  };
}
