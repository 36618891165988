import {BannersState} from '../addons/banners/banners.interface';
import {SuperAdminPartnersState} from './super-admin-partners.interface';
import {ADD_BANNER_SUCCESS, CHANGE_BANNER_SUCCESS, REMOVE_BANNER_SUCCESS} from '../addons/banners/banners.actions';
import {
  FETCH_SUPER_ADMIN_PARTNERS_SUCCESS,
  SAVE_SUPER_ADMIN_PARTNER_SUCCESS,
  SuperAdminPartnersActions
} from './super-admin-partners.actions';
import {createSelector} from '@ngrx/store';
import {getEmployeesState, getWholeEmployeeListState} from '../employees/employees.reducer';

export const initialState: SuperAdminPartnersState = {
  partners: []
};

export function superAdminPartnersReducer(
  state: SuperAdminPartnersState = initialState,
  action: SuperAdminPartnersActions
): SuperAdminPartnersState {
  switch (action.type) {
    case FETCH_SUPER_ADMIN_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.payload
      };
    case SAVE_SUPER_ADMIN_PARTNER_SUCCESS:
      return {
        ...state,
        partners: [...state.partners, action.payload]
      };

    default: {
      return state;
    }
  }
}


export const getSuperAdminPartnersRootState = state => state.superAdminPartners;

export const getSuperAdminPartnersState = (state: SuperAdminPartnersState) => state.partners;

export const getSuperAdminPartnersSelector = createSelector(getSuperAdminPartnersRootState, getSuperAdminPartnersState);


