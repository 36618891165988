import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetImagesAction } from './images.actions';
import { Image } from './media.interface';
import { getImagesSelector } from './media.reducer';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  images: Image[];
  isShowAddForm = false;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetImagesAction());
    this.store.select(getImagesSelector).subscribe((images: Image[]) => this.images = images);
  }

  showAddForm = () => this.isShowAddForm = true;
  hideAddForm = () => this.isShowAddForm = false;

}
