import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { AdvancedSettings } from '../settings/settings.interface';

@Injectable({
  providedIn: 'root'
})
export class AdvancedSettingsService {

  constructor(private http: HttpClient) {
  }

  get(): Observable<AdvancedSettings> {
    return this.http.get<AdvancedSettings>('/settings');
  }

  update(payload: AdvancedSettings): Observable<any> {
    return this.http.post('/settings', payload);
  }

}
