import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {SignatureInstallationState} from './signature-installation-state.interface';

@Injectable({providedIn: 'root'})
export class SignatureInstallationStateService {
  constructor(private http: HttpClient) {
  }

  getInProgressState = (): Observable<SignatureInstallationState[]> => {
    return this.http.get<SignatureInstallationState[]>('/installation-state');
  };

}
