<mat-toolbar class="header signatures-header">
  <span>{{'menu.signatures.custom' | translate}}</span>
  <a mat-raised-button [routerLink]="['create']">{{'signatures.new' | translate}}</a>
</mat-toolbar>

<div class="signatures-container" *ngIf="signatures$">
  <div *ngFor="let signature of signatures$ | async">
    <div class="signature-wrapper">
      <div class="signature-header">
        <div class="name-default-wrapper">
          <h4>{{signature.name}}</h4>

          <button
            *ngIf="!defaultSignatureSet"
            (click)="setDefault(signature)"
            class="chip-button"
            [title]="(!auth.lastGoogleSync ? 'signatures.default.setDefaultDisabledTooltip' : 'signatures.default.setDefaultTooltip') | translate"
            [disabled]="!auth.lastGoogleSync"
          >
            {{'signatures.default.setDefaultButton' | translate}}
          </button>

          <button
            *ngIf="signature.defaultForNewEmployee"
            (click)="removeDefault(signature)"
            class="chip-button" [title]="'signatures.default.unsetDefaultTooltip' | translate">
            <mat-icon>check</mat-icon>
            <span>{{'signatures.default.unsetDefaultButton' | translate}}</span>
          </button>

        </div>
        <span class="chip not-installed-text" disabled
              *ngIf="signature.numberOfInstallations == 0">{{('signatures.notInstalled' | translate)}}</span>

        <a [routerLink]="['/employees']" [queryParams]="{signatureTemplateId: signature.id}"
           *ngIf="signature.numberOfInstallations > 0">
        <span class="chip installed-text" disabled
        >
          {{('signatures.installed' | translate: {installed: signature.numberOfInstallations})}}</span>
        </a>
      </div>
      <div class="signature-content-wrapper">
        <div [innerHTML]="signature.content | safeHtml" class="signature-content"></div>
      </div>
      <div class="signature-addons">
        <div>
          <mat-icon>{{signature.addonLinkId ? 'check_box' : 'indeterminate_check_box'}}</mat-icon>
          {{'signatures.link' | translate}}</div>
        <div>
          <mat-icon>{{signature.addonBannerId  ? 'check_box' : 'indeterminate_check_box'}}</mat-icon>
          {{'signatures.banner' | translate}}</div>
        <div>
          <mat-icon>{{signature.addonDisclaimerId ? 'check_box' : 'indeterminate_check_box'}}</mat-icon>
          {{'signatures.disclaimer' | translate}}</div>
      </div>
    </div>
    <div class="signature-actions">
      <mat-icon title="{{'edit' | translate}}" (click)="goToEditSignature(signature.id)">edit</mat-icon>
      <mat-icon title="{{'duplicate' | translate}}" (click)="duplicateSignature(signature.id)">file_copy</mat-icon>
      <mat-icon title="{{'details' | translate}}" (click)="goToDetailsSignature(signature.id)">description</mat-icon>
      <mat-icon title="{{'delete' | translate}}" (click)="removeSignature(signature.id)" *ngIf="!signature.numberOfInstallations">delete_outline</mat-icon>
      <mat-icon title="{{'copyHTMLToClipboard' | translate}}" (click)="copySignatureToClipboard(signature.content)">
        content_copy
      </mat-icon>
    </div>
  </div>
</div>
