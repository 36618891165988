<div class="advanced-settings">
  <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">

    <mat-checkbox formControlName="overwritingDataByGoogleSync">
      {{ 'settings.overwritingDataByGoogleSync' | translate }}
    </mat-checkbox>

    <mat-checkbox formControlName="updateSignatureWhenGoogleDataChange">
      {{ 'settings.updateSignatureWhenGoogleDataChange' | translate }}
    </mat-checkbox>

    <mat-checkbox formControlName="analyticLinksEnabled">
      {{ 'settings.analyticLinksEnabled' | translate }}
    </mat-checkbox>

    <mat-form-field appearance="fill" class="country-code">
      <mat-label>{{'settings.defaultCountryCallingCode' | translate}}</mat-label>
      <mat-select formControlName="defaultCountryCallingCode">
<!--        <mat-select-filter-->
<!--          [placeholder]="'search' | translate"-->
<!--          [displayMember]="'name'"-->
<!--          [array]="countries"-->
<!--          (filteredReturn)="filteredCountries =$event"-->
<!--        ></mat-select-filter>-->
        <mat-option
          *ngFor="let country of filteredCountries"
          [value]="country.countryCallingCodeNumber"
        >
          {{country.flag}} {{country.countryNameLocal}} (+{{country.countryCallingCode}})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="user-permissions-label">
      {{ 'settings.absence' | translate }}
    </div>

    <mat-divider class="user-permissions-divider"></mat-divider>

    <mat-checkbox formControlName="absenceEnabled">
      {{ 'settings.absenceEnabled' | translate }}
    </mat-checkbox>

    <mat-form-field appearance="fill" *ngIf="form.get('absenceEnabled').value">
      <mat-label>{{'settings.absenceLabel' | translate}}</mat-label>
      <input matInput formControlName="absenceLabel" type="text">
    </mat-form-field>

    <div class="user-permissions-label">
      {{ 'settings.userPermissions' | translate }}
    </div>

    <mat-divider class="user-permissions-divider"></mat-divider>

    <mat-checkbox formControlName="confirmationOfSignatureInstallationByUser">
      {{ 'settings.confirmationOfSignatureInstallationByUser' | translate }}
    </mat-checkbox>

    <mat-checkbox formControlName="bannerDisablingByUser">
      {{ 'settings.bannerDisablingByUser' | translate }}
    </mat-checkbox>


    <div class="actions">
      <button mat-raised-button type="submit">{{'save' | translate}}</button>
    </div>

  </form>
</div>
