import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ClientTableRow } from './client-table.interace';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { StoreExtendedService } from '../store-extended.service';
import { CHANGE_ACCOUNT_SUCCESS, ChangeAccountAction } from '../../accounts/accounts.actions';
import { ClientWithStats } from '../../client/client.interface';
import { TranslateService } from '@ngx-translate/core';
import { ReportsService } from '../reports/reports.service';

@Component({
  selector: 'app-client-table',
  templateUrl: './client-table.component.html',
  styleUrls: ['./client-table.component.scss']
})
export class ClientTableComponent implements OnInit {
  public displayedColumns = ['name', 'googleAdminEmail', 'createdAt', 'stats', 'planId', 'trialFinishDate', 'actions'];
  public clients: ClientWithStats[];

  @Input()
  public data: Observable<ClientTableRow[]>;

  @Input()
  public actionsTemplate?: TemplateRef<any>;

  public dataSource: MatTableDataSource<ClientTableRow>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private readonly store: StoreExtendedService,
              private readonly router: Router,
              private translate: TranslateService,
              private reportsService: ReportsService) {
  }

  async ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();
    if (!this.actionsTemplate) {
      this.displayedColumns.pop();
    }
    this.data.subscribe((clients: ClientWithStats[]) => this.clients = clients);
  }

  loadData = () => this.data.subscribe(data => {
    this.dataSource.data = data;
    this.dataSource.sort = this.sort;
  })

  goToClient(domain: string) {
    this.store.waitForAction(CHANGE_ACCOUNT_SUCCESS)
      .subscribe(() => {
        this.router.navigate(['signatures']);
      });
    this.store.dispatch(new ChangeAccountAction(domain));
  }

  generateReport = () => this.reportsService.generateClientsReport(this.clients);

}
