<!-- custom-button.component.html -->
<button mat-raised-button [disabled]="loading" [color]="color" (click)="onClick()"
        [class.loading]="loading">

  <div class="button-content">
    <mat-spinner *ngIf="loading" diameter="16" mode="indeterminate"></mat-spinner>

    <span translate>
    {{ label }}
  </span>

    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>

  </div>

</button>
