<div *ngIf="!isShowAddForm" class="actions">
  <a mat-raised-button (click)="showAddForm()">{{'addons.disclaimers.add' | translate}}</a>
</div>

<div class="container" *ngIf="disclaimers && !isShowAddForm">
  <div *ngFor="let disclaimer of disclaimers.disclaimers">
    <app-disclaimer [disclaimer]="disclaimer"></app-disclaimer>
  </div>
</div>

<div *ngIf="isShowAddForm">
  <app-disclaimer-add [onClose]="hideAddForm"></app-disclaimer-add>
</div>
