<div class="row my-5 loader">
  <div class="col-12 text-center mb-3">
    <h3 *ngIf="!hideText">{{text || 'loading' | translate}}</h3>
    <div class="spinner-border text-primary mb-1" role="status">
      <span class="sr-only">{{text || 'loading' | translate}}</span>
    </div>
    <div>
    </div>
  </div>
</div>
