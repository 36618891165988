<h3>{{'footers.dynamic' | translate}}</h3>

<ng-template #placeholdersLoading>
  <app-loading></app-loading>
</ng-template>

<div *ngIf="placeholders; else placeholdersLoading" class="placeholders">
  <div class="column">
    <span *ngFor="let placeholder of companyPlaceholders"
          (click)="copyPlaceholderToClipboard(placeholder)">{{placeholder}}</span>
  </div>
  <div class="column">
    <span *ngFor="let placeholder of userPlaceholders"
          (click)="copyPlaceholderToClipboard(placeholder)">{{placeholder}}</span>
  </div>
  <div class="column">
    <span *ngFor="let placeholder of phoneNumberPlaceholders"
          (click)="copyPlaceholderToClipboard(placeholder)">{{placeholder}}</span>
  </div>
  <div class="column">
    <span *ngFor="let placeholder of otherPlaceholders"
          (click)="copyPlaceholderToClipboard(placeholder)">{{placeholder}}</span>
  </div>
</div>
