<mat-toolbar class="header signatures-header">
  <span>{{ 'menu.signatures.predefined' | translate }}</span>
  <div *ngIf="canEdit" class="actions">
    <button mat-stroked-button (click)="toggleReordering()" [disabled]="isSendingRequest">
      <span *ngIf="!isReordering">{{ 'predefined.reorder' | translate }}</span>
      <span *ngIf="isReordering">{{ 'predefined.saveReorder' | translate }}</span>
    </button>
    <a mat-raised-button *ngIf="!isReordering" [routerLink]="['new']">{{ 'predefined.new' | translate }}</a>
  </div>
</mat-toolbar>

<app-loading *ngIf="initialLoading"/>


<ul class="signature-list" [dragula]="isReordering ? 'predefined' : undefined" [(dragulaModel)]="signatures">
  <li *ngFor="let signature of signatures" class="signature-list-item" [class.reordering]="isReordering">

    <app-signature-predefined-item [signature]="signature"
                                   [canReorder]="isReordering">

      <a
        [matTooltip]="'edit' | translate"
        *ngIf="canEdit" mat-icon-button [routerLink]="[signature.id]">
        <mat-icon title="{{'edit' | translate}}">edit</mat-icon>
      </a>

      <button
        [matTooltip]="'predefined.save' | translate"
        mat-icon-button (click)="copyPredefinedToClient(signature.id)">
        <mat-icon title="{{'save' | translate}}">download</mat-icon>
      </button>

      <button
        [matTooltip]="'delete' | translate"
        *ngIf="canEdit"
        mat-icon-button (click)="deletePredefined(signature.id)">
        <mat-icon title="{{'predefined.toggle' | translate}}">delete</mat-icon>
      </button>

    </app-signature-predefined-item>
</ul>
