import { Action } from '@ngrx/store';
import { AddImageObject, ChangeImageObject, Image } from './media.interface';

export const GET_IMAGES = 'GET_IMAGES';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAILURE = 'GET_IMAGES_FAILURE';

export const REMOVE_IMAGE_SUCCESS = 'REMOVE_IMAGE_SUCCESS';
export const REMOVE_IMAGE_FAILURE = 'REMOVE_IMAGE_FAILURE';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';

export const ADD_IMAGE_SUCCESS = 'ADD_IMAGE_SUCCESS';
export const ADD_IMAGE_FAILURE = 'ADD_IMAGE_FAILURE';
export const ADD_IMAGE = 'ADD_IMAGE';

export const CHANGE_IMAGE_SUCCESS = 'CHANGE_IMAGE_SUCCESS';
export const CHANGE_IMAGE_FAILURE = 'CHANGE_IMAGE_FAILURE';
export const CHANGE_IMAGE = 'CHANGE_IMAGE';

export class GetImagesAction implements Action {
  readonly type = GET_IMAGES;

  constructor() {
  }
}

export class GetImagesSuccessAction implements Action {
  readonly type = GET_IMAGES_SUCCESS;

  constructor(public payload: Image[]) {
  }
}

export class GetImagesFailureAction implements Action {
  readonly type = GET_IMAGES_FAILURE;

  constructor(public payload: any) {
  }
}

export class RemoveImageAction implements Action {
  readonly type = REMOVE_IMAGE;

  constructor(public payload: string) {
  }
}

export class RemoveImageSuccessAction implements Action {
  readonly type = REMOVE_IMAGE_SUCCESS;

  constructor(public payload: string ) {
  }
}

export class RemoveImageFailureAction implements Action {
  readonly type = REMOVE_IMAGE_FAILURE;

  constructor(public payload: any) {
  }
}

export class AddImageAction implements Action {
  readonly type = ADD_IMAGE;

  constructor(public payload: AddImageObject) {
  }
}

export class AddImageSuccessAction implements Action {
  readonly type = ADD_IMAGE_SUCCESS;

  constructor(public payload: Image) {
  }
}

export class AddImageFailureAction implements Action {
  readonly type = ADD_IMAGE_FAILURE;

  constructor(public payload: any) {
  }
}

export class ChangeImageAction implements Action {
  readonly type = CHANGE_IMAGE;

  constructor(public payload: ChangeImageObject) {
  }
}

export class ChangeImageSuccessAction implements Action {
  readonly type = CHANGE_IMAGE_SUCCESS;

  constructor(public payload: ChangeImageObject) {
  }
}

export class ChangeImageFailureAction implements Action {
  readonly type = CHANGE_IMAGE_FAILURE;

  constructor(public payload: any) {
  }
}

export type ImagesActions =
  | GetImagesAction
  | GetImagesSuccessAction
  | GetImagesFailureAction
  | RemoveImageAction
  | RemoveImageSuccessAction
  | RemoveImageFailureAction
  | AddImageAction
  | AddImageSuccessAction
  | AddImageFailureAction
  | ChangeImageAction
  | ChangeImageSuccessAction
  | ChangeImageFailureAction;
