import {Action} from '@ngrx/store';
import {AddSignatureObject, Signature, ChangeSignatureObject} from './signatures.interface';

export const GET_SIGNATURES = 'GET_SIGNATURES';
export const GET_SIGNATURES_SUCCESS = 'GET_SIGNATURES_SUCCESS';
export const GET_SIGNATURES_FAILURE = 'GET_SIGNATURES_FAILURE';

export const CHANGE_SIGNATURE_SUCCESS = 'CHANGE_SIGNATURE_SUCCESS';
export const CHANGE_SIGNATURE_FAILURE = 'CHANGE_SIGNATURE_FAILURE';
export const CHANGE_SIGNATURE = 'CHANGE_SIGNATURE';

export const REMOVE_SIGNATURE_SUCCESS = 'REMOVE_SIGNATURE_SUCCESS';
export const REMOVE_SIGNATURE_FAILURE = 'REMOVE_SIGNATURE_FAILURE';
export const REMOVE_SIGNATURE = 'REMOVE_SIGNATURE';

export const INSTALL_SIGNATURE_SUCCESS = 'INSTALL_SIGNATURE_SUCCESS';
export const INSTALL_SIGNATURE_FAILURE = 'INSTALL_SIGNATURE_FAILURE';
export const INSTALL_SIGNATURE = 'INSTALL_SIGNATURE';

export const UNINSTALL_SIGNATURE_SUCCESS = 'UNINSTALL_SIGNATURE_SUCCESS';
export const UNINSTALL_SIGNATURE_FAILURE = 'UNINSTALL_SIGNATURE_FAILURE';
export const UNINSTALL_SIGNATURE = 'UNINSTALL_SIGNATURE';

export const SET_DEFAULT_SIGNATURE = 'SET_DEFAULT_SIGNATURE';
export const SET_DEFAULT_SIGNATURE_SUCCESS = 'SET_DEFAULT_SIGNATURE_SUCCESS';
export const SET_DEFAULT_SIGNATURE_FAILURE = 'SET_DEFAULT_SIGNATURE_FAILURE';

export const DUPLICATE_SIGNATURE_SUCCESS = 'DUPLICATE_SIGNATURE_SUCCESS';
export const DUPLICATE_SIGNATURE_FAILURE = 'DUPLICATE_SIGNATURE_FAILURE';
export const DUPLICATE_SIGNATURE = 'DUPLICATE_SIGNATURE';

export class GetSignaturesAction implements Action {
  readonly type = GET_SIGNATURES;

  constructor() {
  }
}

export class GetSignaturesSuccessAction implements Action {
  readonly type = GET_SIGNATURES_SUCCESS;

  constructor(public payload: Signature[]) {
  }
}

export class GetSignaturesFailureAction implements Action {
  readonly type = GET_SIGNATURES_FAILURE;

  constructor(public payload: any) {
  }
}

export class ChangeSignatureAction implements Action {
  readonly type = CHANGE_SIGNATURE;

  constructor(public payload: ChangeSignatureObject) {
  }
}

export class ChangeSignatureSuccessAction implements Action {
  readonly type = CHANGE_SIGNATURE_SUCCESS;

  constructor(public payload: ChangeSignatureObject) {
  }
}

export class ChangeSignatureFailureAction implements Action {
  readonly type = CHANGE_SIGNATURE_FAILURE;

  constructor(public payload: any) {
  }
}

export class RemoveSignatureAction implements Action {
  readonly type = REMOVE_SIGNATURE;

  constructor(public payload: string) {
  }
}

export class RemoveSignatureSuccessAction implements Action {
  readonly type = REMOVE_SIGNATURE_SUCCESS;

  constructor(public payload: string) {
  }
}

export class RemoveSignatureFailureAction implements Action {
  readonly type = REMOVE_SIGNATURE_FAILURE;

  constructor(public payload: any) {
  }
}

export class InstallSignatureAction implements Action {
  readonly type = INSTALL_SIGNATURE;

  constructor(public payload: any) {
  }
}

export class InstallSignatureSuccessAction implements Action {
  readonly type = INSTALL_SIGNATURE_SUCCESS;

  constructor(public payload: string) {
  }
}

export class InstallSignatureFailureAction implements Action {
  readonly type = INSTALL_SIGNATURE_FAILURE;

  constructor(public payload: any) {
  }
}

export class UninstallSignatureAction implements Action {
  readonly type = UNINSTALL_SIGNATURE;

  constructor(public payload: string) {
  }
}

export class UninstallSignatureSuccessAction implements Action {
  readonly type = UNINSTALL_SIGNATURE_SUCCESS;

  constructor(public payload: string) {
  }
}

export class UninstallSignatureFailureAction implements Action {
  readonly type = UNINSTALL_SIGNATURE_FAILURE;

  constructor(public payload: any) {
  }
}

export class SetDefaultSignatureAction implements Action {
  readonly type = SET_DEFAULT_SIGNATURE;

  constructor(public payload: string | null) {
    // You can adjust the payload type as needed.
  }
}

export class SetDefaultSignatureSuccessAction implements Action {
  readonly type = SET_DEFAULT_SIGNATURE_SUCCESS;

  constructor() {
  }
}

export class SetDefaultSignatureFailureAction implements Action {
  readonly type = SET_DEFAULT_SIGNATURE_FAILURE;

  constructor(public payload: any) {
  }
}

export class DuplicateSignatureAction implements Action {
  readonly type = DUPLICATE_SIGNATURE;

  constructor(public payload: string) {
  }
}

export class DuplicateSignatureSuccessAction implements Action {
  readonly type = DUPLICATE_SIGNATURE_SUCCESS;

  constructor(public payload: string) {
  }
}

export class DuplicateSignatureFailureAction implements Action {
  readonly type = DUPLICATE_SIGNATURE_FAILURE;

  constructor(public payload: any) {
  }
}


export type SignaturesActions =
  | GetSignaturesAction
  | GetSignaturesSuccessAction
  | GetSignaturesFailureAction
  | ChangeSignatureAction
  | ChangeSignatureSuccessAction
  | ChangeSignatureFailureAction
  | RemoveSignatureAction
  | RemoveSignatureSuccessAction
  | RemoveSignatureFailureAction
  | InstallSignatureAction
  | InstallSignatureSuccessAction
  | InstallSignatureFailureAction
  | UninstallSignatureAction
  | UninstallSignatureSuccessAction
  | UninstallSignatureFailureAction
  | SetDefaultSignatureAction
  | SetDefaultSignatureSuccessAction
  | SetDefaultSignatureFailureAction
  | DuplicateSignatureAction
  | DuplicateSignatureSuccessAction
  | DuplicateSignatureFailureAction;
