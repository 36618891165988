<mat-toolbar class="header">
  <span>{{'superAdminPartners.header' | translate}}</span>

  <button mat-raised-button (click)="openCreatePartnerDialog()">{{'superAdminPartners.new' | translate}}</button>
</mat-toolbar>


<mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>{{'superAdminPartners.table.name' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.name }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="numberOfClients">
    <mat-header-cell *matHeaderCellDef>{{'superAdminPartners.table.numberOfClients' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <code>{{ element.numberOfClients }}</code>
    </mat-cell>
  </ng-container>
  

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>
