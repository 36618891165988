import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LANG} from '../../app.component';

@Component({
  selector: 'app-translations-only-blank',
  templateUrl: './translations-only-blank.component.html',
  styleUrls: ['./translations-only-blank.component.scss']
})
export class TranslationsOnlyBlankComponent implements OnInit {

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'es', 'pl']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    const defaultLang = localStorage.getItem(LANG) || (browserLang.match(/en|pl|es/) ? browserLang : 'en');
    translate.use(defaultLang);
  }

  ngOnInit(): void {
  }

}
