import {Component, OnInit} from '@angular/core';
import {CompanyService} from './company.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { Store } from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import { GetCompanySettingsAction } from '../settings/settings.actions';
import { getCompanySettingsSelector } from '../settings/settings.reducer';
import { CompanySettings } from '../settings/settings.interface';
import { validateNonRequiredPhoneNumber } from '../shared/validators/phone-number.validator';
import { Image } from '../shared/image-uploader/image.interface';
import { ImageService } from '../shared/image-uploader/image.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  loading = true;
  saving;
  file;
  previewUrl;
  company;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    website: new UntypedFormControl(),
    address: new UntypedFormControl(),
    phone: new UntypedFormControl('', [validateNonRequiredPhoneNumber]),
    imageWidth: new UntypedFormControl('', Validators.min(0)),
    imageHeight: new UntypedFormControl('', Validators.min(0)),
    originalImageWidth: new UntypedFormControl('', Validators.min(0)),
    originalImageHeight: new UntypedFormControl('', Validators.min(0)),
  });

  constructor(
    private service: CompanyService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private store: Store,
    public imageService: ImageService
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetCompanySettingsAction());

    this.store.select(getCompanySettingsSelector).subscribe((companySettings: CompanySettings) => {
      if (companySettings) {
        this.form.patchValue({
          name: companySettings.name,
          website: companySettings.website,
          address: companySettings.address,
          phone: companySettings.phone,
          imageWidth: companySettings.imageWidth,
          imageHeight: companySettings.imageHeight,
          originalImageWidth: companySettings.originalImageWidth,
          originalImageHeight: companySettings.originalImageHeight,
        });

        if (companySettings.photoUrl) {
          this.previewUrl = `${companySettings.photoUrl}?t=${Date.now()}`;
        }

        this.company = companySettings;
        this.loading = false;
      }
    });
  }

  save = async () => {
    if (this.form.valid) {
      this.saving = true;
      await this.service.save(this.form.value).toPromise();
      if (this.file) {
        await this.service.upload(this.file).toPromise();
      }
      this.toastr.success(this.translate.instant('settings.companyDataEditSuccess'));
      this.saving = false;
    } else {
      this.form.markAllAsTouched();
    }
  }

  fileChange = (file: Image) => {
    const width = file.width || this.form.value.originalImageWidth;
    const height = file.height || this.form.value.originalImageHeight;

    this.form.patchValue({
      originalImageWidth: width,
      originalImageHeight: height,
      imageWidth: width,
      imageHeight: height,
    });
    return this.file = file.file;
  }

}
