import { Component, OnInit } from '@angular/core';
import { Disclaimers } from './disclaimers.interface';
import { GetDisclaimersAction } from './disclaimers.actions';
import { Store } from '@ngrx/store';
import { getDisclaimersSelector } from './disclaimers.reducer';

@Component({
  selector: 'app-disclaimers',
  templateUrl: './disclaimers.component.html',
  styleUrls: ['./disclaimers.component.scss']
})
export class DisclaimersComponent implements OnInit {

  disclaimers: Disclaimers;
  isShowAddForm = false;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetDisclaimersAction());

    this.store.select(getDisclaimersSelector).subscribe((disclaimers: Disclaimers) => {
      this.disclaimers = disclaimers;
    });
  }

  showAddForm = () => this.isShowAddForm = true;

  hideAddForm = () => this.isShowAddForm = false;

}
