import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  GET_EMPLOYEE_SETTINGS,
  GetEmployeeSettingsSuccessAction,
  GetEmployeeSettingsFailureAction,
  CHANGE_EMPLOYEE_SETTINGS,
  ChangeEmployeeSettingsAction,
  ChangeEmployeeSettingsSuccessAction,
  ChangeEmployeeSettingsFailureAction,
  ChangeCompanySettingsAction,
  CHANGE_COMPANY_SETTINGS,
  ChangeCompanySettingsSuccessAction,
  ChangeCompanySettingsFailureAction,
  GET_COMPANY_SETTINGS,
  GetCompanySettingsSuccessAction,
  GetCompanySettingsFailureAction,
  GET_PREDEFINED_EMPLOYEE_SETTINGS,
  GetPredefinedEmployeeSettingsSuccessAction,
  GetPredefinedEmployeeSettingsFailureAction,
  GET_ADVANCED_SETTINGS,
  GetAdvancedSettingsSuccessAction,
  GetAdvancedSettingsFailureAction,
  CHANGE_ADVANCED_SETTINGS,
  ChangeAdvancedSettingsAction,
  ChangeAdvancedSettingsSuccessAction,
  ChangeAdvancedSettingsFailureAction
} from './settings.actions';
import { SettingsService } from './settings.service';
import {
  AdvancedSettings,
  CompanySettings,
  EmployeeSetting,
  PredefinedEmployeeSetting
} from './settings.interface';
import { CompanyService } from '../company/company.service';
import { AdvancedSettingsService } from '../advanced-settings/advanced-settings.service';

@Injectable()
export class SettingsEffects {

  getPredefinedEmployeeSettings$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_PREDEFINED_EMPLOYEE_SETTINGS),
    switchMap(() => {
      return this.settingsService.getPredefinedEmployeeSettings().pipe(
        switchMap((response: PredefinedEmployeeSetting[]) => {
          return [new GetPredefinedEmployeeSettingsSuccessAction(response)];
        }),
        catchError(error => of(new GetPredefinedEmployeeSettingsFailureAction(error)))
      );
    })
  ));

  getEmployeeSettings$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_EMPLOYEE_SETTINGS),
    switchMap(() => {
      return this.settingsService.getEmployeeSettings().pipe(
        switchMap((response: any) => {
          return [new GetEmployeeSettingsSuccessAction(response)];
        }),
        catchError(error => of(new GetEmployeeSettingsFailureAction(error)))
      );
    })
  ));

  changeEmployeeSettings$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_EMPLOYEE_SETTINGS),
    map((action: ChangeEmployeeSettingsAction) => action.payload),
    switchMap((payload: EmployeeSetting[]) => {
      return this.settingsService.changeEmployeeSettings(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('settings.employeeDataEditSuccess'));
          return [new ChangeEmployeeSettingsSuccessAction(response)];
        }),
        catchError(error => of(new ChangeEmployeeSettingsFailureAction(error)))
      );
    })
  ));

  getCompanySettings$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_COMPANY_SETTINGS),
    switchMap(() => {
      return this.companyService.get().pipe(
        switchMap((response: CompanySettings) => {
          return [new GetCompanySettingsSuccessAction(response)];
        }),
        catchError(error => of(new GetCompanySettingsFailureAction(error)))
      );
    })
  ));

  changeCompanySettings$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_COMPANY_SETTINGS),
    map((action: ChangeCompanySettingsAction) => action.payload),
    switchMap((payload: CompanySettings) => {
      return this.companyService.save(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('settings.companyDataEditSuccess'));
          return [new ChangeCompanySettingsSuccessAction(response)];
        }),
        catchError(error => of(new ChangeCompanySettingsFailureAction(error)))
      );
    })
  ));

  getAdvancedSettings$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(GET_ADVANCED_SETTINGS),
    switchMap(() => {
      return this.advancedSettingsService.get().pipe(
        switchMap((response: AdvancedSettings) => {
          return [new GetAdvancedSettingsSuccessAction(response)];
        }),
        catchError(error => of(new GetAdvancedSettingsFailureAction(error)))
      );
    })
  ));

  changeAdvancedSettings$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_ADVANCED_SETTINGS),
    map((action: ChangeAdvancedSettingsAction) => action.payload),
    switchMap((payload: AdvancedSettings) => {
      return this.advancedSettingsService.update(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('settings.advancedSettingsEditSuccess'));
          return [new ChangeAdvancedSettingsSuccessAction(response)];
        }),
        catchError(error => of(new ChangeAdvancedSettingsFailureAction(error)))
      );
    })
  ));

  constructor(private actions$: Actions,
              private settingsService: SettingsService,
              private companyService: CompanyService,
              private advancedSettingsService: AdvancedSettingsService,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

}
