import {SaveSuperAdminRequest, SuperAdminPartner} from './super-admin-partners.interface';

export const FETCH_SUPER_ADMIN_PARTNERS = 'FETCH_SUPER_ADMIN_PARTNERS';
export const FETCH_SUPER_ADMIN_PARTNERS_SUCCESS = 'FETCH_SUPER_ADMIN_PARTNERS_SUCCESS';
export const FETCH_SUPER_ADMIN_PARTNERS_FAILURE = 'FETCH_SUPER_ADMIN_PARTNERS_FAILURE';

export const SAVE_SUPER_ADMIN_PARTNER = 'SAVE_SUPER_ADMIN_PARTNER';
export const SAVE_SUPER_ADMIN_PARTNER_SUCCESS = 'SAVE_SUPER_ADMIN_PARTNER_SUCCESS';
export const SAVE_SUPER_ADMIN_PARTNER_FAILURE = 'SAVE_SUPER_ADMIN_PARTNER_FAILURE';


export class FetchSuperAdminPartnersAction {
  readonly type = FETCH_SUPER_ADMIN_PARTNERS;

  constructor() {
  }
}

export class FetchSuperAdminPartnersSuccessAction {
  readonly type = FETCH_SUPER_ADMIN_PARTNERS_SUCCESS;

  constructor(public payload: SuperAdminPartner[]) {
  }
}

export class FetchSuperAdminPartnersFailureAction {
  readonly type = FETCH_SUPER_ADMIN_PARTNERS_FAILURE;

  constructor(public payload: any) {
  }
}

export class SaveSuperAdminPartnersAction {
  readonly type = SAVE_SUPER_ADMIN_PARTNER;

  constructor(public payload: SaveSuperAdminRequest) {
  }
}

export class SaveSuperAdminPartnersSuccessAction {
  readonly type = SAVE_SUPER_ADMIN_PARTNER_SUCCESS;

  constructor(public payload: SuperAdminPartner) {
  }
}

export class SaveSuperAdminPartnersFailureAction {
  readonly type = SAVE_SUPER_ADMIN_PARTNER_FAILURE;

  constructor(public payload: any) {
  }
}


export type SuperAdminPartnersActions =
  | FetchSuperAdminPartnersAction
  | FetchSuperAdminPartnersSuccessAction
  | FetchSuperAdminPartnersFailureAction
  | SaveSuperAdminPartnersAction
  | SaveSuperAdminPartnersSuccessAction
  | SaveSuperAdminPartnersFailureAction
  ;
