import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
  User as FirebaseUser,
  Auth,
  authState,
  user,
  signInWithRedirect,
  GoogleAuthProvider,
  isSignInWithEmailLink, signOut, signInWithEmailLink, getRedirectResult, idToken, signInWithPopup
} from '@angular/fire/auth';
import {UserService} from '../users/users.service';
import {User} from '../users/user';
import {Store} from '@ngrx/store';
import {ResetAccountAction} from '../accounts/accounts.actions';

const EMAIL_KEY = 'email';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth: Auth = inject(Auth);
  user$ = user(this.auth);
  idToken$ = idToken(this.auth);
  idToken;
  authState$ = authState(this.auth);
  authState;
  appUser: User | undefined;
  hasCompanyManagerAccess = false;
  canChangeAccounts = false;
  isSuperAdmin = false;
  isCompanyManager = false;
  isPartner = false;
  isGoogleAdmin = false;
  trialFinishDate;
  lastGoogleSync;

  constructor(public router: Router, private userService: UserService, private store: Store) {
    this.idToken$.subscribe((token: string | null) => {
      this.idToken = token;
    });
    this.authState$.subscribe((aUser: FirebaseUser | null) => {
      this.authState = aUser;
    });
  }

  public async loginWithGoogle() {
    if (window.location.hostname === 'localhost') {
      await signInWithPopup(this.auth, new GoogleAuthProvider());
    } else {
      window.history.replaceState({}, '', '/login/callback');
      await signInWithRedirect(this.auth, new GoogleAuthProvider());
    }
  }

  public isLoginWithLink() {
    return isSignInWithEmailLink(this.auth, window.location.href);
  }

  async logOut() {
    this.store.dispatch(new ResetAccountAction());
    await signOut(this.auth);
    await this.router.navigate(['login']);
  }

  getToken() {
    return this.idToken$;
  }

  getAppUser() {
    return this.appUser;
  }

  public clearAppUser() {
    this.appUser = null;
  }

  async getRedirectResult() {
    const result = await getRedirectResult(this.auth);
    console.log(result);
    if (result.user) {
      // await this.handleSuccessLogin();
    } else {
      throw new Error('No user');
    }
  }

  async loginWithLink() {
    return signInWithEmailLink(this.auth, localStorage.getItem(EMAIL_KEY), window.location.href)
      .then(() => localStorage.removeItem(EMAIL_KEY));
  }

  fetchCurrent = () =>
    this.userService.current().toPromise()
    .then((currentUser: User) => {
      this.appUser = currentUser;
      this.hasCompanyManagerAccess = ['SUPER_ADMIN', 'COMPANY_MANAGER', 'PARTNER'].includes(currentUser.role);
      this.canChangeAccounts = currentUser.availableDomains.length > 1 || currentUser.hasPartnerAccount;
      this.isSuperAdmin = currentUser.role === 'SUPER_ADMIN';
      this.isCompanyManager = currentUser.role === 'COMPANY_MANAGER';
      this.isPartner = currentUser.role === 'PARTNER';
      this.trialFinishDate = currentUser.trialFinishDate;
      this.isGoogleAdmin = currentUser.isGoogleAdmin;
      this.lastGoogleSync = currentUser.lastGoogleSync;

    })
    .then(() => this.appUser.planId || this.appUser.isPremium ? true : this.router.navigate(['/plan']))
    .catch(() => this.logOut().then(() => false))

  public changeAvatarUrl = avatarUrl => this.appUser.thumbnailPhotoUrl = avatarUrl;

}
