import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AnalStatsResponse, DateRange} from './analytics.interface';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class AnalyticsService {


  constructor(private readonly http: HttpClient) {
  }


  public findTotalClicks(range: DateRange): Observable<AnalStatsResponse> {
    return this.http.get<AnalStatsResponse>('/analytics', {
      params: {
        from: range.from.toISOString(),
        to: range.to?.toISOString(),
      }
    });
  }
}
