<form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <app-image-uploader
      [allowedFileExtensions]="['image/jpeg', 'image/png', 'image/gif']"
      wrongTypeMessage="{{'addons.banners.wrongType' | translate}}"
      description="{{'addons.banners.description' | translate}}"
      additionalDescription="{{'addons.banners.additionalDescription' | translate}}"
      [previewImageStyles]="imageService.getImageStyle(form.get('imageWidth').value)"
      (onFileChange)="fileChange($event)"
    ></app-image-uploader>
    <input matInput formControlName="file" hidden="true">
    <mat-error *ngIf="form.hasError('required', ['file'])">{{'fileRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'imageWidth' | translate}}</mat-label>
    <input matInput formControlName="imageWidth" type="number" min="0">
    <span matSuffix>px</span>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.banners.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.banners.url' | translate}}</mat-label>
    <input matInput formControlName="url" type="text">
    <mat-error *ngIf="form.hasError('required', ['url'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'addons.banners.utm' | translate}}</mat-label>
    <input matInput formControlName="utm" type="text">
    <mat-error *ngIf="form.hasError('required', ['utm'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <div class="actions">
    <button mat-raised-button (click)="cancel()">{{'cancel' | translate}}</button>
    <button mat-raised-button type="submit">{{'save' | translate}}</button>
  </div>
</form>
