<mat-toolbar class="header">
  <span>{{ 'footers.new' | translate }}</span>
</mat-toolbar>

<div class="signature-create-content">
  <form [formGroup]="form" class="form" (ngSubmit)="save()">
    <mat-form-field appearance="fill" class="name-form-field">
      <mat-label>{{ 'footers.name' | translate }}</mat-label>
      <input matInput formControlName="name" type="text">
      <mat-error *ngIf="form.hasError('required', ['name'])">{{ 'fieldRequired' | translate }}</mat-error>
    </mat-form-field>

    <angular-editor matInput formControlName="content" [config]="config"></angular-editor>

    <app-addons [form]="form"></app-addons>

    <mat-form-field appearance="fill">
      <mat-label>{{'footers.userAvatarWidth' | translate}}</mat-label>
      <input matInput formControlName="userAvatarWidth" type="number" min="0">
      <span matSuffix>px</span>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'footers.userAvatarRoundedCorners' | translate}}</mat-label>
      <input matInput formControlName="userAvatarBorderRadius" type="number" min="0" max="100">
      <span matSuffix>%</span>
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button (click)="goToSignatures()">{{ 'cancel' | translate }}</button>
      <button mat-raised-button type="submit" [disabled]="!form.valid || setting">
        {{ setting ? 'saving' : 'save' | translate }}
      </button>
    </div>

    <app-placeholders></app-placeholders>
  </form>


  <aside class="predefined-wrapper">
    <h2>{{ 'footers.predefined' | translate }}</h2>


    <ul class="signature-list" *ngIf="predefined$ | async as predefined">
      <li *ngFor="let signature of predefined" class="signature-list-item">

        <app-signature-predefined-item [signature]="signature">


          <button
            [matTooltip]="'predefined.save' | translate"
            mat-icon-button (click)="load(signature)">
            <mat-icon title="{{'save' | translate}}">download</mat-icon>
          </button>


        </app-signature-predefined-item>
    </ul>
  </aside>

</div>
