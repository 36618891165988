<mat-dialog-content>
  <p class="title" *ngIf="data.title">{{data.title | translate}}</p>
  <p class="body" *ngIf="data.body">{{data.body | translate}}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>
    {{'cancel' | translate}}
  </button>

  <button mat-raised-button [mat-dialog-close]="true">
    {{data.confirmButton || 'yes' | translate}}
  </button>
</mat-dialog-actions>
