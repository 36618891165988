import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {FetchSuperAdminPartnersAction} from '../super-admin-partners.actions';
import {MatTableDataSource} from '@angular/material/table';
import {UserRole} from '../../user-admins/user-admins.interface';
import {SuperAdminPartner} from '../super-admin-partners.interface';
import {getSuperAdminPartnersSelector} from '../super-admin-partners.reducer';
import {MatDialog} from '@angular/material/dialog';
import {AddPartnerDialogComponent} from '../add-partner-dialog/add-partner-dialog.component';
import {ToastrService} from 'ngx-toastr';
import {ToastrTranslatedService} from '../../shared/toastr-translated.service';

@Component({
  selector: 'app-super-admin-partners',
  templateUrl: './super-admin-partners.component.html',
  styleUrls: ['./super-admin-partners.component.scss']
})
export class SuperAdminPartnersComponent implements OnInit {
  public loading = true;
  public displayedColumns = ['name', 'numberOfClients'];
  public dataSource = new MatTableDataSource<SuperAdminPartner>();

  constructor(private readonly store: Store,
              private dialog: MatDialog,
              private toastr: ToastrTranslatedService) {
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchSuperAdminPartnersAction());

    this.store.select(getSuperAdminPartnersSelector).subscribe((data) => {
      this.loading = false;
      this.dataSource.data = data;
    });
  }


  openCreatePartnerDialog() {
    this.dialog.open(AddPartnerDialogComponent)
      .afterClosed()
      .subscribe(success => {
        if (success) {
          this.toastr.success('addPartner.addSuccess');
        }
      });
  }
}
