import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-settings-form-dialog',
  templateUrl: './employee-settings-form-dialog.component.html',
  styleUrls: ['./employee-settings-form-dialog.component.scss']
})
export class EmployeeSettingsFormDialogComponent {

  public sendingRequest = false;

  public form: FormGroup<{
    name: FormControl<string>;
    label: FormControl<string>;
    defaultValue: FormControl<string>;
    overridable: FormControl<boolean>;
    mandatory: FormControl<boolean>;
  }>;

  constructor(public dialogRef: MatDialogRef<EmployeeSettingsFormDialogComponent>) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      label: new FormControl('', Validators.required),
      defaultValue: new FormControl(''),
      overridable: new FormControl(true),
      mandatory: new FormControl(false)
    });
  }

  submit = () => {
    if (this.form.invalid) {
      return;
    }
    this.sendingRequest = true;
    this.dialogRef.close(this.form.getRawValue());
  }

  close = (success?: boolean) => {
    this.dialogRef.close(success);
  }

}
