import { createSelector } from '@ngrx/store';
import { MediaState } from './media.interface';
import {
  GET_IMAGES_SUCCESS,
  REMOVE_IMAGE_SUCCESS,
  ADD_IMAGE_SUCCESS,
  CHANGE_IMAGE_SUCCESS,
  ImagesActions
} from './images.actions';

export const initialState: MediaState = {
  images: null
};

export function mediaReducer(
  state: MediaState = initialState,
  action: ImagesActions
): MediaState {
  switch (action.type) {
    case GET_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.payload
      };
    case REMOVE_IMAGE_SUCCESS:
      return {
        ...state,
        images: state.images.filter(banner => banner.id !== action.payload)
      };
    case ADD_IMAGE_SUCCESS:
      return {
        ...state,
        images: [...state.images || [], action.payload]
      };
    case CHANGE_IMAGE_SUCCESS:
      return {
        ...state,
        images: state.images.map(image => image.id === action.payload.id ? {...image, name: action.payload.name} : image)
      };
    default: {
      return state;
    }
  }
}

export const getMediaState = state => state.media;
export const getImagesState = state => state.images;

export const getImagesSelector = createSelector(getMediaState, getImagesState);
