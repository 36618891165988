import { Action } from '@ngrx/store';
import { AddLinkObject, Link, Links, ChangeLinkObject} from './links.interface';

export const GET_LINKS = 'GET_LINKS';
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS';
export const GET_LINKS_FAILURE = 'GET_LINKS_FAILURE';

export const CHANGE_LINK_SUCCESS = 'CHANGE_LINK_SUCCESS';
export const CHANGE_LINK_FAILURE = 'CHANGE_LINK_FAILURE';
export const CHANGE_LINK = 'CHANGE_LINK';

export const REMOVE_LINK_SUCCESS = 'REMOVE_LINK_SUCCESS';
export const REMOVE_LINK_FAILURE = 'REMOVE_LINK_FAILURE';
export const REMOVE_LINK = 'REMOVE_LINK';

export const ADD_LINK_SUCCESS = 'ADD_LINK_SUCCESS';
export const ADD_LINK_FAILURE = 'ADD_LINK_FAILURE';
export const ADD_LINK = 'ADD_LINK';

export class GetLinksAction implements Action {
  readonly type = GET_LINKS;

  constructor() {
  }
}

export class GetLinksSuccessAction implements Action {
  readonly type = GET_LINKS_SUCCESS;

  constructor(public payload: Links) {
  }
}

export class GetLinksFailureAction implements Action {
  readonly type = GET_LINKS_FAILURE;

  constructor(public payload: any) {
  }
}

export class ChangeLinkAction implements Action {
  readonly type = CHANGE_LINK;

  constructor(public payload: ChangeLinkObject) {
  }
}

export class ChangeLinkSuccessAction implements Action {
  readonly type = CHANGE_LINK_SUCCESS;

  constructor(public payload: ChangeLinkObject) {
  }
}

export class ChangeLinkFailureAction implements Action {
  readonly type = CHANGE_LINK_FAILURE;

  constructor(public payload: any) {
  }
}

export class RemoveLinkAction implements Action {
  readonly type = REMOVE_LINK;

  constructor(public payload: string) {
  }
}

export class RemoveLinkSuccessAction implements Action {
  readonly type = REMOVE_LINK_SUCCESS;

  constructor(public payload: string ) {
  }
}

export class RemoveLinkFailureAction implements Action {
  readonly type = REMOVE_LINK_FAILURE;

  constructor(public payload: any) {
  }
}

export class AddLinkAction implements Action {
  readonly type = ADD_LINK;

  constructor(public payload: AddLinkObject) {
  }
}

export class AddLinkSuccessAction implements Action {
  readonly type = ADD_LINK_SUCCESS;

  constructor(public payload: Link) {
  }
}

export class AddLinkFailureAction implements Action {
  readonly type = ADD_LINK_FAILURE;

  constructor(public payload: any) {
  }
}

export type LinksActions =
  | GetLinksAction
  | GetLinksSuccessAction
  | GetLinksFailureAction
  | ChangeLinkAction
  | ChangeLinkSuccessAction
  | ChangeLinkFailureAction
  | RemoveLinkAction
  | RemoveLinkSuccessAction
  | RemoveLinkFailureAction
  | AddLinkAction
  | AddLinkSuccessAction
  | AddLinkFailureAction;
