import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '../users/users.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {Group} from '../users/user';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  displayedColumns = ['name', 'email', 'directMembersCount', 'assign'];
  dataSource: MatTableDataSource<Group>;
  @Input() updating ? = false;
  @Output() clicked = new EventEmitter<string>();
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();
  }

  loadData() {
    this.userService.groups().subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
    });
  }

  assign(group: string) {
    return this.clicked.emit(group);
  }

}
