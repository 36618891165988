import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SendEmployeeRequestAction } from '../employees.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-employee-request',
  templateUrl: './employee-request.component.html',
  styleUrls: ['./employee-request.component.scss']
})
export class EmployeeRequestComponent implements OnInit {

  form: UntypedFormGroup;
  targetValues = ['ALL'/* , 'WITH_MISSING_DATA'*/];

  constructor(public dialog: MatDialog, private store: Store) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      subject: new UntypedFormControl('The new settings for email signatures', Validators.required),
      body: new UntypedFormControl('Dear team,\n\nWe have implemented a centrally managed email signature. By logging in with your Google account, you can access your personal profile settings at <a href="https://app.gsignature.com/">Gmail Signature Manager</a> Please get familiar with your data and correct/update it as necessary.', Validators.required),
      target: new UntypedFormControl('ALL', Validators.required)
    });
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.store.dispatch(new SendEmployeeRequestAction(this.form.value));
    }
  }

}
