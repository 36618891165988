import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserRole } from '../user-admins.interface';
import { UserAdminsService } from '../user-admins.service';
import { AuthService } from '../../auth/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-admins-edit-dialog',
  templateUrl: './user-admins-edit-dialog.component.html',
  styleUrls: ['./user-admins-edit-dialog.component.scss']
})
export class UserAdminsEditDialogComponent {

  public sendingRequest = false;
  public form: FormGroup;

  constructor(
    private service: UserAdminsService,
    public auth: AuthService,
    public dialogRef: MatDialogRef<UserAdminsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserRole
  ) {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new FormGroup({
      email: new FormControl(this.data.email, [Validators.required, Validators.email]),
      role: new FormControl(this.data.role, [Validators.required])
    });
  }

  submit = () => {
    this.sendingRequest = true;
    if (this.form.valid) {
      this.service.updateAdmin(this.data.id, this.form.value).subscribe(
        () => this.close(this.form.value),
        () => {
          this.sendingRequest = false;
        });
    } else {
      this.form.markAllAsTouched();
      this.sendingRequest = false;
    }
  }

  close = response => this.dialogRef.close(response);

}
