import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientService} from '../../client/client.service';
import {SaveSuperAdminRequest} from '../super-admin-partners.interface';
import {Store} from '@ngrx/store';
import {SaveSuperAdminPartnersAction} from '../super-admin-partners.actions';
import {StoreExtendedService} from '../../shared/store-extended.service';

@Component({
  selector: 'app-add-partner-dialog',
  templateUrl: './add-partner-dialog.component.html',
  styleUrls: ['./add-partner-dialog.component.scss']
})
export class AddPartnerDialogComponent {

  public sendingRequest = false;

  public form = new FormGroup({
    name: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl('', [Validators.email]),
    clientDomain: new FormControl({disabled: true, value: ''}),
    clientGoogleAdminEmail: new FormControl({disabled: true, value: ''}, [Validators.email])
  });

  constructor(
    public dialogRef: MatDialogRef<AddPartnerDialogComponent>,
    private clientService: ClientService,
    private store: Store,
    private storeExtended: StoreExtendedService,
  ) {
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.sendingRequest = true;
    const value = this.form.value as SaveSuperAdminRequest;

    this.storeExtended.dispatchAndWaitForResult(new SaveSuperAdminPartnersAction(value))
      .subscribe(result => {
        this.sendingRequest = false;
        this.close(result.success);
      });

  }

  close(success?: boolean) {
    this.dialogRef.close(success);
  }

  toggleClientDomainEnabled(shouldEnable: boolean) {
    if (shouldEnable) {
      this.form.controls.clientDomain.enable();
      this.form.controls.clientGoogleAdminEmail.enable();
    } else {
      this.form.controls.clientDomain.disable();
      this.form.controls.clientGoogleAdminEmail.disable();
    }
  }
}
