import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {validateNonRequiredPhoneNumber} from 'src/app/shared/validators/phone-number.validator';
import {GetEmployeeAction, UpdateEmployeeAction } from '../employees.actions';
import { getEmployeeSelector } from '../employees.reducer';
import { Employee } from '../employees.interface';
import { getEmployeeSettingsSelector } from '../../settings/settings.reducer';
import { EmployeeSetting } from '../../settings/settings.interface';
import { GetEmployeeSettingsAction } from '../../settings/settings.actions';
import {StartCheckingForInstallationInProgressAction} from '../../signature-installation-state/signature-installation-state.actions';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent implements OnInit {

  form: UntypedFormGroup;
  employee: Employee;
  employeeSettings: EmployeeSetting[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetEmployeeAction(this.route.snapshot.params.id));

    this.form = new UntypedFormGroup({
      excludedFromSignatures: new UntypedFormControl('', Validators.required),
      givenName: new UntypedFormControl({value: '', disabled: true}),
      familyName: new UntypedFormControl({value: '', disabled: true}),
      dynamicVariables: new FormGroup({}),
    });

    this.store.dispatch(new GetEmployeeSettingsAction());
    this.store.select(getEmployeeSettingsSelector).subscribe((employeeSettings: EmployeeSetting[]) => {
      this.employeeSettings = employeeSettings;
      employeeSettings.forEach((setting: EmployeeSetting) => {
        const validators = [];
        if (setting.mandatory) {
          validators.push(Validators.required);
        }
        if (['phoneNumber', 'mobileNumber'].includes(setting.name)) {
          validators.push(validateNonRequiredPhoneNumber);
        }
        this.dynamicVariables.addControl(
          setting.name,
          new FormControl({value: setting.defaultValue, disabled: !setting.overridable}, validators)
        );
      });

      this.store.select(getEmployeeSelector).subscribe((employee: Employee) => {
        if (employee && this.route.snapshot.params.id === employee.id) {
          this.employee = employee;
          this.fillForm(employee);
        }
      });

    });
    this.form.updateValueAndValidity();
  }

  get dynamicVariables() {
    return this.form.get('dynamicVariables') as FormGroup;
  }

  fillForm = (employee: Employee) => {
    this.form.patchValue({
      excludedFromSignatures: employee.excludedFromSignatures,
      givenName: employee.givenName,
      familyName: employee.familyName,
      dynamicVariables: employee.dynamicVariables
    });
  };

  goToEmployeeList = () => this.router.navigate(['employees']);

  save = () => {
    if (this.form.valid) {
      this.store.dispatch(new UpdateEmployeeAction({...this.employee, ...this.form.value, installSignature: false}));
    } else {
      this.form.markAllAsTouched();
    }
  }

  saveAndInstall = () => {
    if (this.form.valid) {
      this.store.dispatch(new UpdateEmployeeAction({...this.employee, ...this.form.value, installSignature: true}));
      this.store.dispatch(new StartCheckingForInstallationInProgressAction());
    } else {
      this.form.markAllAsTouched();
    }
  };

  isPhoneNumberInput = name => ['phoneNumber', 'mobileNumber'].includes(name);

}
