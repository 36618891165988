import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {PartnerService} from '../partner.service';
import {AddClientRequest} from '../../client/client.interface';
import { defaultTrialFinishDate } from '../../shared/utils/trial.util';
import { validateFutureDate } from '../../shared/validators/date.validator';
import { validateHTTPExists } from '../../shared/validators/url.validator';

@Component({
  selector: 'app-partner-create-client-dialog',
  templateUrl: './partner-create-client-dialog.component.html',
  styleUrls: ['./partner-create-client-dialog.component.scss']
})
export class PartnerCreateClientDialogComponent {

  public sendingRequest = false;

  public form = new FormGroup({
    domain: new FormControl('', [Validators.required, validateHTTPExists]),
    googleAdminEmail: new FormControl('', [Validators.email, Validators.required]),
    partnerAdminEmail: new FormControl('', [Validators.email]),
    isIncludedDemoContent: new FormControl(false),
    trialFinishDate: new FormControl(defaultTrialFinishDate, [Validators.required, validateFutureDate]),
  });

  constructor(
    public dialogRef: MatDialogRef<PartnerCreateClientDialogComponent>,
    private partnerService: PartnerService,
  ) {
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.sendingRequest = true;
    const value = this.form.value as AddClientRequest;

    this.partnerService.addClient(value)
      .subscribe(result => {
        this.sendingRequest = false;
        this.close(true);
      });

  }

  close(success?: boolean) {
    this.dialogRef.close(success);
  }
}
