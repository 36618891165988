import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SignaturesService} from '../../signatures.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { greaterThan, lowerThan, validateNotPastDate } from '../../../shared/validators/date.validator';

@Component({
  selector: 'app-wizard-final',
  templateUrl: './wizard-final.component.html',
  styleUrls: ['./wizard-final.component.scss']
})
export class WizardFinalComponent implements OnInit {

  form = new UntypedFormGroup({
    dateFrom: new UntypedFormControl('', [Validators.required, validateNotPastDate, lowerThan('dateTo')]),
    tmplFrom: new UntypedFormControl('', Validators.required),
    dateTo: new UntypedFormControl('', [Validators.required, greaterThan('dateFrom')]),
    tmplTo: new UntypedFormControl('', Validators.required)
  });
  state;
  footers;
  updating;
  minDate;

  constructor(private router: Router, private route: ActivatedRoute, private service: SignaturesService) {
  }

  ngOnInit(): void {
    const date = new Date();
    this.minDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDay()};
    this.state = window.history.state;

    if (!this.state.internal) {
      this.router.navigate(['wizard']);
    } else {
      this.footers = this.service.getAll();
    }
  }

  submit() {
    this.updating = true;
    const {dateFrom, dateTo, tmplFrom, tmplTo} = this.form.value;
    const fromUtc = Date.UTC(dateFrom.year(), dateFrom.month(), dateFrom.date());
    const toUtc = Date.UTC(dateTo.year(), dateTo.month(), dateTo.date());

    this.service.setFooter({fromUtc, toUtc, tmplFrom, tmplTo, context: this.state.internal}).toPromise()
      .then(() => this.router.navigate(['wizard/success']))
      .finally(() => this.updating = false);
  }
}
