import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, flatMap, map, switchMap, tap} from 'rxjs/operators';
import {
  CHANGE_ACCOUNT, CHANGE_ACCOUNT_SUCCESS,
  CHANGE_PARTNER,
  ChangeAccountAction,
  ChangeAccountSuccessAction,
  ChangePartnerAction,
  ChangePartnerSuccessAction,
  FETCH_AVAILABLE_ACCOUNT,
  FETCH_AVAILABLE_ACCOUNT_FAILURE,
  FetchAvailableAccountsFailureAction,
  FetchAvailableAccountsSuccessAction,
  RESET_ACCOUNT_SUCCESS,
  ResetAccountSuccessAction
} from './accounts.actions';
import {AccountsResponse, CURRENT_ACCOUNT_LOCAL_STORAGE_KEY, CURRENT_PARTNER_ID_LOCAL_STORAGE_KEY} from './accounts.interface';
import {AccountsService} from './accounts.service';
import {of} from 'rxjs';
import {AuthService} from '../auth/auth.service';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AccountsEffects {
  constructor(private actions$: Actions,
              private accountsService: AccountsService,
              private authService: AuthService) {
  }


  changeAccount$ = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_ACCOUNT),
    map((payload: ChangeAccountAction) => {
      window.localStorage.setItem(CURRENT_ACCOUNT_LOCAL_STORAGE_KEY, payload.payload);
      return new ChangeAccountSuccessAction(payload.payload);
    })
  ));

  changeAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_ACCOUNT_SUCCESS),
    tap(() => {
      this.authService.fetchCurrent();
    })
  ), {dispatch: false});


  changePartner$ = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_PARTNER),
    map((payload: ChangePartnerAction) => {
      window.localStorage.setItem(CURRENT_PARTNER_ID_LOCAL_STORAGE_KEY, payload.payload);
      return new ChangePartnerSuccessAction(payload.payload);
    })
  ));

  resetAccount$ = createEffect(() => this.actions$.pipe(
    ofType(RESET_ACCOUNT_SUCCESS),
    map(() => {
      window.localStorage.removeItem(CURRENT_ACCOUNT_LOCAL_STORAGE_KEY);
      this.authService.clearAppUser();
      return new ResetAccountSuccessAction();
    })
  ));

  fetchAvailableAccounts = createEffect(() => this.actions$.pipe(
    ofType(FETCH_AVAILABLE_ACCOUNT),
    switchMap(() => {
      return this.accountsService.getAllAvailable().pipe(
        switchMap((response: AccountsResponse) => {
          return [new FetchAvailableAccountsSuccessAction(response)];
        }),
        catchError(error => of(new FetchAvailableAccountsFailureAction(error)))
      );
    }),
  ));

  defaultAccountsRedirect = createEffect(() => this.actions$.pipe(
    ofType(FETCH_AVAILABLE_ACCOUNT_FAILURE),
    map(() => {
      return new ChangeAccountAction(this.authService.appUser.availableDomains[0]);
    })
  ));


}
