import {Component} from '@angular/core';
import {ToastrTranslatedService} from '../shared/toastr-translated.service';
import {AuthService} from '../auth/auth.service';
import moment from 'moment';
import {MY_FORMATS} from '../shared/shared.module';

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss']
})
export class IntegrationSettingsComponent {
  public readonly clientId = '111875350985434773237';
  public readonly scopes = 'https://www.googleapis.com/auth/gmail.settings.sharing, https://www.googleapis.com/auth/gmail.settings.basic, https://www.googleapis.com/auth/admin.directory.user.readonly, https://www.googleapis.com/auth/admin.directory.orgunit.readonly, https://www.googleapis.com/auth/admin.directory.group.readonly';
  public lastGoogleSync: Date | undefined;
  public lastGoogleSyncFormatted: string | undefined

  constructor(private readonly toastr: ToastrTranslatedService, private readonly auth: AuthService) {
    this.lastGoogleSync = auth.appUser.lastGoogleSync;
    if (this.lastGoogleSync) {
      this.lastGoogleSyncFormatted = moment(this.lastGoogleSync).format(MY_FORMATS.display.dateInput)
    }
  }

  copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    this.toastr.success('settings.integration.copied');
  };


}
