import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-review-dialog',
  templateUrl: './employee-review-dialog.component.html',
  styleUrls: ['./employee-review-dialog.component.scss']
})
export class EmployeeReviewDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EmployeeReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: [],
  ) {
  }

  onCloseClick = () => this.dialogRef.close();

}
