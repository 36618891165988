<mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'group.name' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.name">{{ element.name }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'group.email' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.email">{{ element.email }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="directMembersCount">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'group.count' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.directMembersCount">{{ element.directMembersCount }}</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="assign">
    <mat-header-cell *matHeaderCellDef>{{'footers.set' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-raised-button (click)="assign(element.id)" [disabled]="updating">
        {{(updating ? 'footers.setting' : 'footers.set') | translate}}
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
