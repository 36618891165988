import { createSelector } from '@ngrx/store';
import {
  GET_ADVANCED_SETTINGS_SUCCESS,
  GET_COMPANY_SETTINGS_SUCCESS,
  GET_EMPLOYEE_SETTINGS_SUCCESS,
  GET_PREDEFINED_EMPLOYEE_SETTINGS_SUCCESS,
  SettingsActions
} from './settings.actions';
import { SettingsState } from './settings.interface';

export const initialState: SettingsState = {
  employeeSettings: [],
  companySettings: null,
  predefinedEmployeeSettings: [],
  advancedSettings: null,
};

export function settingsReducer(
  state: SettingsState = initialState,
  action: SettingsActions
): SettingsState {
  switch (action.type) {
    case GET_EMPLOYEE_SETTINGS_SUCCESS:
      return {
        ...state,
        employeeSettings: action.payload
      };
    case GET_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        companySettings: action.payload
      };
    case GET_PREDEFINED_EMPLOYEE_SETTINGS_SUCCESS:
      return {
        ...state,
        predefinedEmployeeSettings: action.payload
      };
    case GET_ADVANCED_SETTINGS_SUCCESS:
      return {
        ...state,
        advancedSettings: action.payload
      };
    default: {
      return state;
    }
  }
}

export const getSettingsState = state => state.settings;
export const getEmployeeSettingsState = state => state.employeeSettings;
export const getPredefinedEmployeeSettingsState = state => state.predefinedEmployeeSettings;
export const getCompanySettingsState = state => state.companySettings;
export const getAdvancedSettingsState = state => state.advancedSettings;

export const getEmployeeSettingsSelector = createSelector(getSettingsState, getEmployeeSettingsState);
export const getPredefinedEmployeeSettingsSelector = createSelector(getSettingsState, getPredefinedEmployeeSettingsState);
export const getCompanySettingsSelector = createSelector(getSettingsState, getCompanySettingsState);
export const getAdvancedSettingsSelector = createSelector(getSettingsState, getAdvancedSettingsState);
