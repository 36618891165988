import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-wizard-unit',
  templateUrl: './wizard-unit.component.html',
  styleUrls: ['./wizard-unit.component.scss']
})
export class WizardUnitComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  unitSelected = (unit: string) => this.router.navigate(['wizard/final'], {state: {internal: {unit}}});
}
