import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ImageService {

  constructor() {
  }

  getImageStyle = (imageWidth?: number) => {
      return `width:${imageWidth ? `${imageWidth}px` : 'auto'}px;max-width:${imageWidth ? `${imageWidth}px` : 'auto'};height:auto;max-height:none;`;
  }

}
