<div class="addons" [formGroup]="form">

  <mat-form-field appearance="fill" *ngIf="links">
    <mat-label>{{'signatures.link' | translate}}</mat-label>
    <mat-select formControlName="addonLinkId">
      <mat-option [value]="null">{{'none' | translate}}</mat-option>
      <mat-option *ngFor="let link of links.links" [value]="link.id" [title]="link.url">
        {{link.name || link.url}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="banners">
    <mat-label>{{'signatures.banner' | translate}}</mat-label>
    <mat-select formControlName="addonBannerId">
      <mat-option [value]="null">{{'none' | translate}}</mat-option>
      <mat-option *ngFor="let banner of banners.banners" [value]="banner.id" [title]="banner.url">
        {{banner.name || banner.url}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="disclaimers">
    <mat-label>{{'signatures.disclaimer' | translate}}</mat-label>
    <mat-select formControlName="addonDisclaimerId">
      <mat-option [value]="null">{{'none' | translate}}</mat-option>
      <mat-option *ngFor="let disclaimer of disclaimers.disclaimers" [value]="disclaimer.id" [title]="disclaimer.htmlContent" [matTooltip]="extractDisclaimerHtmlContent(disclaimer)">
        {{disclaimer.name || disclaimer.htmlContent}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
