import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  GET_IMAGES,
  GetImagesSuccessAction,
  GetImagesFailureAction,
  REMOVE_IMAGE,
  RemoveImageAction,
  RemoveImageSuccessAction,
  RemoveImageFailureAction,
  ADD_IMAGE,
  AddImageAction,
  AddImageSuccessAction,
  AddImageFailureAction, CHANGE_IMAGE, ChangeImageAction, ChangeImageSuccessAction, ChangeImageFailureAction
} from './images.actions';
import { ImagesService } from './images.service';
import { AddImageObject, ChangeImageObject, Image } from './media.interface';

@Injectable()
export class ImagesEffects {

  getImages$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_IMAGES),
      switchMap(() => {
        return this.imagesService.getImages().pipe(
          switchMap((response: Image[]) => {
            return [new GetImagesSuccessAction(response)];
          }),
          catchError(error => of(new GetImagesFailureAction(error)))
        );
      })
    );
  });

  removeImage$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(REMOVE_IMAGE),
    map((action: RemoveImageAction) => action.payload),
    switchMap((payload: string) => {
      return this.imagesService.removeImage(payload).pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('media.images.deleteSuccess'));
          return [new RemoveImageSuccessAction(payload)];
        }),
        catchError(error => of(new RemoveImageFailureAction(error)))
      );
    })
  ));

  addImage$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ADD_IMAGE),
    map((action: AddImageAction) => action.payload),
    switchMap((payload: AddImageObject) => {
      return this.imagesService.addImage(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('media.images.addSuccess'));
          return [new AddImageSuccessAction(response)];
        }),
        catchError(error => of(new AddImageFailureAction(error)))
      );
    })
  ));

  changeImage$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_IMAGE),
    map((action: ChangeImageAction) => action.payload),
    switchMap((payload: ChangeImageObject) => {
      return this.imagesService.changeImage(payload).pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('media.images.editSuccess'));
          return [new ChangeImageSuccessAction(payload)];
        }),
        catchError(error => of(new ChangeImageFailureAction(error)))
      );
    })
  ));

  constructor(private actions$: Actions,
              private imagesService: ImagesService,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

}
