<div *ngIf="!editMode">
  Name: {{disclaimer.name}}<br/><br/>
  {{disclaimer.htmlContent}}
</div>

<form [formGroup]="form" class="form" (ngSubmit)="changeDisclaimer()" *ngIf="editMode">
  <mat-form-field appearance="fill">
    <mat-label>{{'addons.disclaimers.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <angular-editor formControlName="htmlContent" [config]="config"></angular-editor>
  <mat-error *ngIf="form.hasError('required', ['htmlContent'])">{{'fieldRequired' | translate}}</mat-error>
</form>

<mat-divider></mat-divider>
<div class="actions">
  <mat-icon *ngIf="editMode" title="{{'cancel' | translate}}" (click)="cancelEditDisclaimer()">cancel</mat-icon>
  <mat-icon *ngIf="editMode" title="{{'save' | translate}}" (click)="changeDisclaimer()">save</mat-icon>
  <mat-icon *ngIf="!editMode" title="{{'edit' | translate}}" (click)="changeEditMode()">edit</mat-icon>
  <mat-icon *ngIf="!editMode" title="{{'delete' | translate}}" (click)="removeDisclaimer(disclaimer.id)">
    delete_outline
  </mat-icon>
</div>
