import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService } from '../client.service';
import { EditClientDialogData, EditClientRequest } from '../client.interface';
import { validateFutureDate } from '../../shared/validators/date.validator';

@Component({
  selector: 'app-client-edit-dialog',
  templateUrl: './client-edit-dialog.component.html',
  styleUrls: ['./client-edit-dialog.component.scss']
})
export class ClientEditDialogComponent {
  public sendingRequest = false;
  private readonly oldDomain: string;
  public subscriptions = ['free', 'paid', 'partner'];
  trialFinishDateValidations = [Validators.required, validateFutureDate];

  public form: FormGroup<{
    googleAdminEmail: FormControl<string>;
    domain: FormControl<string>;
    planId: FormControl<string>;
    trialFinishDate: FormControl<Date>;
  }>;

  constructor(
    public dialogRef: MatDialogRef<ClientEditDialogComponent>,
    private clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) public data: EditClientDialogData
  ) {

    this.oldDomain = data.domain;

    this.form = new FormGroup({
      domain: new FormControl({disabled: true, value: data.domain}, {nonNullable: true, validators: [Validators.required]}),
      googleAdminEmail: new FormControl(data.googleAdminEmail, [Validators.email]),
      planId: new FormControl(data.planId, [Validators.required]),
      trialFinishDate: new FormControl(data.trialFinishDate, data.planId === 'free' ? this.trialFinishDateValidations : [])
    });

    this.form.get('planId').valueChanges.subscribe(value => {
      if (value === 'free') {
        this.form.get('trialFinishDate').setValidators(this.trialFinishDateValidations);
      } else {
        this.form.get('trialFinishDate').clearValidators();
      }

      this.form.get('trialFinishDate').updateValueAndValidity();
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.sendingRequest = true;
    const value = this.form.getRawValue() as EditClientRequest;

    this.clientService.edit(this.oldDomain, value)
      .subscribe(
        () => this.close(true),
        () => {
          this.sendingRequest = false;
        });
  }

  close(success?: boolean) {
    this.dialogRef.close(success);
  }

  toggleDomainChangeEnabled(shouldEnable: boolean) {
    if (shouldEnable) {
      this.form.controls.domain.enable();
    } else {
      this.form.controls.domain.disable();
    }
  }

}

