<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

<div *ngIf="footers | async as footers; else loading">
  <form [formGroup]="form" (ngSubmit)="submit()" class="form">
    <h4>{{'wizard.whenStart' | translate}}</h4>

    <mat-form-field appearance="fill">
      <mat-label>{{'wizard.dateFrom' | translate}}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dateFrom" [min]="minDate">
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="form.hasError('required', ['dateFrom'])">{{'fieldRequired' | translate}}</mat-error>
      <mat-error *ngIf="form.hasError('dateInvalid', ['dateFrom'])">{{'futureDateInvalid' | translate}}</mat-error>
      <mat-error *ngIf="form.hasError('lowerThanInvalid', ['dateFrom'])">{{'dateRangeInvalid' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'wizard.template' | translate}}</mat-label>
      <mat-select formControlName="tmplFrom">
        <mat-option *ngFor="let footer of footers" [value]="footer.id">{{footer.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', ['tmplFrom'])">{{'fieldRequired' | translate}}</mat-error>
    </mat-form-field>

    <h4>{{'wizard.whenFinish' | translate}}</h4>

    <mat-form-field appearance="fill">
      <mat-label>{{'wizard.dateTo' | translate}}</mat-label>
      <input matInput [matDatepicker]="picker2" formControlName="dateTo" [min]="minDate">
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
      <mat-error *ngIf="form.hasError('required', ['dateTo'])">{{'fieldRequired' | translate}}</mat-error>
      <mat-error *ngIf="form.hasError('greaterThanInvalid', ['dateTo'])">{{'dateRangeInvalid' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'wizard.template' | translate}}</mat-label>
      <mat-select formControlName="tmplTo">
        <mat-option *ngFor="let footer of footers" [value]="footer.id">{{footer.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', ['tmplTo'])">{{'fieldRequired' | translate}}</mat-error>
    </mat-form-field>

    <div class="actions">
      <button
        mat-raised-button
        [disabled]="form.invalid || updating"
        type="submit"
      >
        {{(updating ? 'footers.setting' : 'wizard.schedule') | translate}}
      </button>
    </div>
  </form>
</div>
