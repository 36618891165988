import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  GET_DISCLAIMERS,
  GetDisclaimersSuccessAction,
  GetDisclaimersFailureAction,
  CHANGE_DISCLAIMER,
  ChangeDisclaimerAction,
  ChangeDisclaimerSuccessAction,
  ChangeDisclaimerFailureAction,
  REMOVE_DISCLAIMER,
  RemoveDisclaimerAction,
  RemoveDisclaimerSuccessAction,
  RemoveDisclaimerFailureAction,
  ADD_DISCLAIMER,
  AddDisclaimerAction,
  AddDisclaimerSuccessAction,
  AddDisclaimerFailureAction
} from './disclaimers.actions';
import { DisclaimersService } from './disclaimers.service';
import { AddDisclaimerObject, Disclaimers, Disclaimer } from './disclaimers.interface';

@Injectable()
export class DisclaimersEffects {

  getDisclaimers$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_DISCLAIMERS),
      switchMap(() => {
        return this.disclaimersService.getDisclaimers().pipe(
          switchMap((response: Disclaimers) => {
            return [new GetDisclaimersSuccessAction(response)];
          }),
          catchError(error => of(new GetDisclaimersFailureAction(error)))
        );
      })
    );
  });

  changeDisclaimer$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_DISCLAIMER),
    map((action: ChangeDisclaimerAction) => action.payload),
    switchMap((payload: Disclaimer) => {
      return this.disclaimersService.changeDisclaimer(payload).pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('addons.disclaimers.editSuccess'));
          return [new ChangeDisclaimerSuccessAction(payload)];
        }),
        catchError(error => of(new ChangeDisclaimerFailureAction(error)))
      );
    })
  ));

  removeDisclaimer$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(REMOVE_DISCLAIMER),
    map((action: RemoveDisclaimerAction) => action.payload),
    switchMap((payload: string) => {
      return this.disclaimersService.removeDisclaimer(payload).pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('addons.disclaimers.deleteSuccess'));
          return [new RemoveDisclaimerSuccessAction(payload)];
        }),
        catchError(error => of(new RemoveDisclaimerFailureAction(error)))
      );
    })
  ));

  addDisclaimer$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ADD_DISCLAIMER),
    map((action: AddDisclaimerAction) => action.payload),
    switchMap((payload: AddDisclaimerObject) => {
      return this.disclaimersService.addDisclaimer(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('addons.disclaimers.addSuccess'));
          return [new AddDisclaimerSuccessAction(response)];
        }),
        catchError(error => of(new AddDisclaimerFailureAction(error)))
      );
    })
  ));

  constructor(private actions$: Actions,
              private disclaimersService: DisclaimersService,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

}
