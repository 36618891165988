import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  GET_BANNERS,
  GetBannersSuccessAction,
  GetBannersFailureAction,
  CHANGE_BANNER,
  ChangeBannerAction,
  ChangeBannerSuccessAction,
  ChangeBannerFailureAction,
  REMOVE_BANNER,
  RemoveBannerAction,
  RemoveBannerSuccessAction,
  RemoveBannerFailureAction,
  ADD_BANNER,
  AddBannerAction,
  AddBannerSuccessAction,
  AddBannerFailureAction
} from './banners.actions';
import { BannersService } from './banners.service';
import { AddBannerObject, Banner, Banners, ChangeBannerObject } from './banners.interface';

@Injectable()
export class BannersEffects {

  getBanners$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_BANNERS),
      switchMap(() => {
        return this.bannersService.getBanners().pipe(
          switchMap((response: Banners) => {
            return [new GetBannersSuccessAction(response)];
          }),
          catchError(error => of(new GetBannersFailureAction(error)))
        );
      })
    );
  });

  changeBanner$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_BANNER),
    map((action: ChangeBannerAction) => action.payload),
    switchMap((payload: ChangeBannerObject) => {
      return this.bannersService.changeBanner(payload).pipe(
        switchMap((response: Banner) => {
          this.toastr.success(this.translate.instant('addons.banners.editSuccess'));
          return [new ChangeBannerSuccessAction(response)];
        }),
        catchError(error => of(new ChangeBannerFailureAction(error)))
      );
    })
  ));

  removeBanner$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(REMOVE_BANNER),
    map((action: RemoveBannerAction) => action.payload),
    switchMap((payload: string) => {
      return this.bannersService.removeBanner(payload).pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('addons.banners.deleteSuccess'));
          return [new RemoveBannerSuccessAction(payload)];
        }),
        catchError(error => of(new RemoveBannerFailureAction(error)))
      );
    })
  ));

  addBanner$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ADD_BANNER),
    map((action: AddBannerAction) => action.payload),
    switchMap((payload: AddBannerObject) => {
      return this.bannersService.addBanner(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('addons.banners.addSuccess'));
          return [new AddBannerSuccessAction(response)];
        }),
        catchError(error => of(new AddBannerFailureAction(error)))
      );
    })
  ));

  constructor(private actions$: Actions,
              private bannersService: BannersService,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

}
