import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AddLinkAction } from '../links.actions';

@Component({
  selector: 'app-link-add',
  templateUrl: './link-add.component.html',
  styleUrls: ['./link-add.component.scss']
})
export class LinkAddComponent implements OnInit {

  @Input() onClose;

  form: UntypedFormGroup;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      url: new UntypedFormControl('', Validators.required),
      text: new UntypedFormControl('', Validators.required)
    });
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.store.dispatch(new AddLinkAction(this.form.value));
      this.onClose();
    }
  }

  cancel = () => {
    this.fillInitialForm();
    this.onClose();
  }

}
