<h1 class="display-3 text-center text-white">{{'plan.title' | translate}}</h1>
<p class="lead text-center text-muted">{{'plan.info' | translate}}</p>
<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
<div class="row justify-content-center" *ngIf="plans | async as plans; else loading">
  <div class="col-12 col-lg-4" *ngFor="let p of plans">
    <div class="card">
      <div class="card-body">
        <h6 class="text-uppercase text-center text-muted my-4">Plan</h6>
        <div class="row g-0 align-items-center justify-content-center">
          <div class="col-auto">
            <div class="h3 mb-0 text-uppercase">{{p.currency}}</div>
          </div>
          <div class="col-auto">
            <div class="display-2 mb-0">{{p.unit_amount / 100}}</div>
          </div>
        </div>
        <div class="h6 text-uppercase text-center text-muted mb-5">/ {{'plan.month' | translate}}</div>
        <button [disabled]="buying" (click)="buy(p.id)"
                class="btn btn-block btn-light">{{(buying ? 'plan.buying' : 'plan.buy') | translate}}</button>
      </div>
    </div>
  </div>
</div>
