import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared.module';
import {CalendarRangeHeaderComponent} from './calendar-range-header/calendar-range-header.component';

@Component({
  selector: 'app-range-calendar',
  standalone: true,
  imports: [
    SharedModule,
    ReactiveFormsModule
  ],
  templateUrl: './range-calendar.component.html',
  styleUrl: './range-calendar.component.scss'
})
export class RangeCalendarComponent {
  @Input({required: true}) dateRangeForm: FormGroup<{ from: FormControl<Date | null>; to: FormControl<Date | null> }>;
  @Input() label: string;

  readonly CalendarRangeHeaderComponent = CalendarRangeHeaderComponent;
}
