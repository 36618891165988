import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../auth/auth.service';
import {LANG} from '../../app.component';

@Component({
  selector: 'app-partner-main',
  templateUrl: './partner-main.component.html',
  styleUrls: ['./partner-main.component.scss']
})
export class PartnerMainComponent {
  languages = ['en', 'es', 'pl'];
  selectedLanguage: 'en' | 'es' | 'pl';

  constructor(public auth: AuthService,
              public translate: TranslateService,
  ) {
    translate.addLangs(this.languages);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    const defaultLang = localStorage.getItem(LANG) || (browserLang.match(/en|es|pl/) ? browserLang : 'en');
    translate.use(defaultLang);
    this.selectedLanguage = defaultLang as 'en' | 'es' | 'pl';
  }

  changeLanguage = (event) => {
    localStorage.setItem(LANG, event.value);
    this.translate.use(event.value);
  }
}
