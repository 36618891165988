import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss']
})
export class AvatarUploaderComponent {

  @Input() avatarUploadUrl: string;

  useCustomAvatarEnabled = false;

  toggleUseCustomAvatar = () => {
    this.useCustomAvatarEnabled = !this.useCustomAvatarEnabled;
  }

}
