import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastrTranslatedService {

  constructor(private toastr: ToastrService, private translate: TranslateService) {
  }

  public success(translationKey: string, interpolateParams?: object) {
    this.toastr.success(this.translate.instant(translationKey, interpolateParams));
  }

  public error(translationKey: string, interpolateParams?: object) {
    this.toastr.error(this.translate.instant(translationKey, interpolateParams));
  }
}
