import { Component, OnInit } from '@angular/core';
import { Banners } from './banners.interface';
import { GetBannersAction } from './banners.actions';
import { Store } from '@ngrx/store';
import { getBannersSelector } from './banners.reducer';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  banners: Banners;
  isShowAddForm = false;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetBannersAction());

    this.store.select(getBannersSelector).subscribe((banners: Banners) => {
      this.banners = banners;
    });
  }

  showAddForm = () => this.isShowAddForm = true;

  hideAddForm = () => this.isShowAddForm = false;

}
