import {Component, OnInit} from '@angular/core';
import {PlanService} from '../plan/plan.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit {

  constructor(private service: PlanService) {
  }

  ngOnInit(): void {
    this.service.settings().toPromise().then(res => window.open(res.url, '_self'));
  }
}
