import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, switchMap, take} from 'rxjs/operators';
import {AuthService} from '../../auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.getToken()
      .pipe(take(1),
        switchMap((token: string | null) => {
          const apiReq = request.url.startsWith('/assets') ? request : request.clone({
            url: `${environment.apiUrl}${request.url}`,
            setHeaders: {Authorization: `Bearer ${token}`}
          });
          return next.handle(apiReq).pipe(catchError((e) => this.serviceError(e)));
        }));
  }

  private serviceError(e) {
    console.log(e);
    this.toastr.error(this.translate.instant('error'), e.status?.toString());
    return throwError(e.message);
  }
}
