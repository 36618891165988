import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AddonsComponent } from './addons.component';
import { BannersModule } from './banners/banners.module';
import { DisclaimersModule } from './disclaimers/disclaimers.module';
import { LinksModule } from './links/links.module';

@NgModule({
  declarations: [
    AddonsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    BannersModule,
    DisclaimersModule,
    LinksModule,
  ]
})

export class AddonsModule {
}
