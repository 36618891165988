<h1 mat-dialog-title translate>addPartner.header</h1>

<div mat-dialog-content class="content">

  <form [formGroup]="form" (submit)="submit()" class="form">

    <mat-form-field appearance="fill">
      <mat-label translate>addPartner.nameLabel</mat-label>
      <input type="text" matInput formControlName="name">
    </mat-form-field>


    <mat-form-field appearance="fill">
      <mat-label translate>addPartner.emailLabel</mat-label>
      <input type="text" matInput formControlName="email">
    </mat-form-field>

    <mat-checkbox (change)="toggleClientDomainEnabled($event.checked)" class="client-section">
      {{'addPartner.createClientLabel' | translate}}
    </mat-checkbox>

    <mat-form-field appearance="fill">
      <mat-label translate>addPartner.clientDomainLabel</mat-label>
      <input type="text" matInput formControlName="clientDomain">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label translate>addPartner.clientGoogleAdminEmail</mat-label>
      <input type="text" matInput formControlName="clientGoogleAdminEmail">
    </mat-form-field>

  </form>

</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="close()">{{'cancel' | translate}}</button>
  <app-progress-button label="save" (buttonClick)="submit()" [loading]="sendingRequest"/>
</div>
