import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SignaturesService } from '../signatures/signatures.service';
import { AllContext, Context, Planned, UnitContext, UserContext } from './planned';

@Component({
  selector: 'app-planned',
  templateUrl: './planned.component.html',
  styleUrls: ['./planned.component.scss']
})
export class PlannedComponent implements OnInit {

  public displayedColumns = ['templateName', 'dateUtc', 'context', 'type', 'cancel'];
  public dataSource: MatTableDataSource<Planned>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dialog: MatDialog, private translate: TranslateService, private service: SignaturesService) {
  }

  async ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();
  }

  loadData = () => this.service.getScheduled().subscribe(data => {
    this.dataSource.data = data;
    this.dataSource.sort = this.sort;
  })

  toDestination(context: Context) {
    if ((context as AllContext).all) {
      return 'planned.all';
    }
    return (context as UnitContext).unit || (context as UserContext).user.alias || (context as UserContext).user.email;
  }

  remove = (id: string) => this.service.removeScheduled(id).toPromise().then(() => this.loadData());

}
