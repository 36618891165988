<mat-tab-group preserveContent mat-stretch-tabs="false">
  <mat-tab label="{{'addons.links.label' | translate}}">
    <app-links></app-links>
  </mat-tab>
  <mat-tab label="{{'addons.banners.label' | translate}}">
    <app-banners></app-banners>
  </mat-tab>
  <mat-tab label="{{'addons.disclaimers.label' | translate}}">
    <app-disclaimers></app-disclaimers>
  </mat-tab>
</mat-tab-group>
