import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmployeesRoutingModule} from './employees-routing.module';
import {EmployeesComponent} from './employees.component';
import {EmployeeListComponent} from './employee-list/employee-list.component';
import {ReactiveFormsModule} from '@angular/forms';
import {EmployeesService} from './employees.service';
import {StoreModule} from '@ngrx/store';
import {employeesReducer} from './employees.reducer';
import {EffectsModule} from '@ngrx/effects';
import {EmployeesEffects} from './employees.effects';
import {SharedModule} from '../shared/shared.module';
import {EmployeeEditComponent} from './employee-edit/employee-edit.component';
import {EmployeeRequestComponent} from './employee-request/employee-request.component';
import {EmployeeReviewDialogComponent} from './employee-review-dialog/employee-review-dialog.component';
import {CurrentEmployeeFormComponent} from './current-employee-form/current-employee-form.component';
import {EmployeeDataImportComponent} from './employee-data-import/employee-data-import.component';
import {EmployeeDataImportDialogComponent} from './employee-data-import/employee-data-import-dialog/employee-data-import-dialog.component';
import {
  EmployeeDataImportPreviewComponent
} from './employee-data-import/employee-data-import-preview/employee-data-import-preview.component';

@NgModule({
  declarations: [
    EmployeesComponent,
    EmployeeListComponent,
    EmployeeEditComponent,
    EmployeeRequestComponent,
    EmployeeReviewDialogComponent,
    CurrentEmployeeFormComponent,
    EmployeeDataImportComponent,
    EmployeeDataImportDialogComponent,
    EmployeeDataImportPreviewComponent,
  ],
  imports: [
    CommonModule,
    EmployeesRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('employees', employeesReducer),
    EffectsModule.forFeature([EmployeesEffects]),
  ],
  providers: [EmployeesService]
})
export class EmployeesModule {
}
