import { Action } from '@ngrx/store';
import { AddBannerObject, Banner, Banners, ChangeBannerObject} from './banners.interface';

export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE';

export const CHANGE_BANNER_SUCCESS = 'CHANGE_BANNER_SUCCESS';
export const CHANGE_BANNER_FAILURE = 'CHANGE_BANNER_FAILURE';
export const CHANGE_BANNER = 'CHANGE_BANNER';

export const REMOVE_BANNER_SUCCESS = 'REMOVE_BANNER_SUCCESS';
export const REMOVE_BANNER_FAILURE = 'REMOVE_BANNER_FAILURE';
export const REMOVE_BANNER = 'REMOVE_BANNER';

export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const ADD_BANNER_FAILURE = 'ADD_BANNER_FAILURE';
export const ADD_BANNER = 'ADD_BANNER';

export class GetBannersAction implements Action {
  readonly type = GET_BANNERS;

  constructor() {
  }
}

export class GetBannersSuccessAction implements Action {
  readonly type = GET_BANNERS_SUCCESS;

  constructor(public payload: Banners) {
  }
}

export class GetBannersFailureAction implements Action {
  readonly type = GET_BANNERS_FAILURE;

  constructor(public payload: any) {
  }
}

export class ChangeBannerAction implements Action {
  readonly type = CHANGE_BANNER;

  constructor(public payload: ChangeBannerObject) {
  }
}

export class ChangeBannerSuccessAction implements Action {
  readonly type = CHANGE_BANNER_SUCCESS;

  constructor(public payload: Banner) {
  }
}

export class ChangeBannerFailureAction implements Action {
  readonly type = CHANGE_BANNER_FAILURE;

  constructor(public payload: any) {
  }
}

export class RemoveBannerAction implements Action {
  readonly type = REMOVE_BANNER;

  constructor(public payload: string) {
  }
}

export class RemoveBannerSuccessAction implements Action {
  readonly type = REMOVE_BANNER_SUCCESS;

  constructor(public payload: string ) {
  }
}

export class RemoveBannerFailureAction implements Action {
  readonly type = REMOVE_BANNER_FAILURE;

  constructor(public payload: any) {
  }
}

export class AddBannerAction implements Action {
  readonly type = ADD_BANNER;

  constructor(public payload: AddBannerObject) {
  }
}

export class AddBannerSuccessAction implements Action {
  readonly type = ADD_BANNER_SUCCESS;

  constructor(public payload: Banner) {
  }
}

export class AddBannerFailureAction implements Action {
  readonly type = ADD_BANNER_FAILURE;

  constructor(public payload: any) {
  }
}

export type BannersActions =
  | GetBannersAction
  | GetBannersSuccessAction
  | GetBannersFailureAction
  | ChangeBannerAction
  | ChangeBannerSuccessAction
  | ChangeBannerFailureAction
  | RemoveBannerAction
  | RemoveBannerSuccessAction
  | RemoveBannerFailureAction
  | AddBannerAction
  | AddBannerSuccessAction
  | AddBannerFailureAction;
