import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv';
import { ClientWithStats } from '../../client/client.interface';
import { Employee } from '../../employees/employees.interface';

@Injectable({providedIn: 'root'})
export class ReportsService {

  constructor(private translate: TranslateService) {
  }

  generateClientsReport = (clients: ClientWithStats[]) => {
    const reportData = clients.map(
      ({name, domain, createdAt, googleAdminEmail, stats, planId, trialFinishDate}) =>
        ({
          name: name || '',
          domain,
          createdAt,
          googleAdminEmail,
          installedCurrentWeek: stats.current.numberOfEmployeesWithSignatureInstalled,
          totalCurrentWeek: stats.current.numberOfEmployees,
          installedPreviousWeek: stats.previous.numberOfEmployeesWithSignatureInstalled,
          totalPreviousWeek: stats.previous.numberOfEmployees,
          planId,
          trialFinishDate: trialFinishDate || ''
        })
    );

    const headerKeys = ['client.name', 'client.domain', 'client.createdAt', 'client.googleAdminEmail', 'client.installedCurrentWeek', 'client.totalCurrentWeek', 'client.installedPreviousWeek', 'client.totalPreviousWeek', 'client.subscription', 'client.trialFinishDate'];
    this.generateReport(reportData, headerKeys.map(item => this.translate.instant(item)), 'menu.clients');
  };

  generateEmployeesReport = (employees: Employee[], employeeSettings: string[]) => {
    const reportData = employees.map(
      (employee) => {
        return ({
          givenName: employee.givenName || '',
          familyName: employee.familyName || '',
          excludedFromSignatures: employee.excludedFromSignatures,
          createdAt: employee.createdAt,
          modifiedAt: employee.modifiedAt,
          email: employee.email,
          ...employeeSettings.reduce((ac, settingName) => ({...ac, [settingName]: employee.dynamicVariables?.[settingName] || ''}), {})
        });
      }
    );

    const headers = [
      'employee.familyName',
      'employee.givenName',
      'employee.excludedFromSignatures',
      'employee.createdAt',
      'employee.modifiedAt'
    ].map(item => this.translate.instant(item));

    this.generateReport(reportData, [...headers, ...['email'], ...employeeSettings], 'menu.employees');
  };

  generateReport = (reportData: any, headers: string[], nameKey) => {
    new ngxCsv(
      reportData,
      this.translate.instant(nameKey),
      {
        headers
      }
    );
  };

}
