export const environment = {
  production: true,
  apiUrl: 'https://gstamp.lm.r.appspot.com',
  stripeKey: 'pk_test_51I89sXGqpDTuSLudECZFXaAJASwoiTJuN52v8hY60zxLRIC9ry8zzDwF2mEfwAYyISdpEGzv80RrJx1ALIP2voBs00gxPB4By8',
  firebaseConfig: {
    apiKey: 'AIzaSyAU0G3TcBFotX3VB96CvZha8yBZEZjUPfY',
    authDomain: 'app-staging.gsignature.com',
    projectId: 'gstamp',
    storageBucket: 'gstamp.appspot.com',
    messagingSenderId: '384943335894',
    appId: '1:384943335894:web:65c7260ee9f34244ed5c77'
  }
};
