<h1 mat-dialog-title>{{'settings.newHeader' | translate}}</h1>

<div mat-dialog-content class="content">
  <form [formGroup]="form" (submit)="submit()" class="form">
    <mat-form-field appearance="fill">
      <mat-label translate>settings.name</mat-label>
      <input type="text" matInput formControlName="name">
    </mat-form-field>


    <mat-form-field appearance="fill">
      <mat-label translate>settings.label</mat-label>
      <input type="text" matInput formControlName="label">
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="close()">{{'cancel' | translate}}</button>
  <app-progress-button label="save" (buttonClick)="submit()" [loading]="sendingRequest"/>
</div>
