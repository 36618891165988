<mat-toolbar class="header">
  <span>{{'userAdmins.header' | translate}}</span>

  <a mat-raised-button [routerLink]="['create']">{{'userAdmins.new' | translate}}</a>
</mat-toolbar>

<mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>{{'userAdmins.email' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.email }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="role">
    <mat-header-cell *matHeaderCellDef>{{'userAdmins.role' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <code>{{ element.role }}</code>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <mat-header-cell *matHeaderCellDef>{{'userAdmins.createdAt' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span [matTooltip]="element.createdAt">
      {{ element.createdAt | localizedDate }}
        </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef sticky></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a *ngIf="auth.isSuperAdmin || element.role === 'COMPANY_MANAGER'"
        (click)="editAdmin(element)"
        title="{{'edit' | translate}}"
        class="edit-icon"
      >
        <mat-icon>edit</mat-icon>
      </a>
      <a
        *ngIf="auth.isSuperAdmin || element.role === 'COMPANY_MANAGER'"
        (click)="removeAdmin(element.id)"
        title="{{'delete' | translate}}"
        class="delete-icon"
      >
        <mat-icon>delete_outline</mat-icon>
      </a>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>
