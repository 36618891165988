import { createSelector } from '@ngrx/store';
import { BannersState } from './banners.interface';
import {
  GET_BANNERS_SUCCESS,
  REMOVE_BANNER_SUCCESS,
  CHANGE_BANNER_SUCCESS,
  ADD_BANNER_SUCCESS,
  BannersActions
} from './banners.actions';

export const initialState: BannersState = {
  banners: null
};

export function bannersReducer(
  state: BannersState = initialState,
  action: BannersActions
): BannersState {
  switch (action.type) {
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload
      };
    case REMOVE_BANNER_SUCCESS:
      return {
        ...state,
        banners: {banners: state.banners.banners.filter(banner => banner.id !== action.payload)}
      };
    // case CHANGE_BANNER_SUCCESS:
    //   return {
    //     ...state,
    //     banners: {banners: state.banners.banners.map(banner => (banner.id === action.payload.id
    //         ? {...banner, ...action.payload }
    //         : banner
    //       ))}
    //   };
      case ADD_BANNER_SUCCESS:
      return {
        ...state,
        banners: {banners: [...state.banners.banners || [], action.payload]}
      };
    default: {
      return state;
    }
  }
}

export const getBannersState = state => state.banners;

export const getBannersSelector = createSelector(getBannersState, getBannersState);
