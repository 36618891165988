import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import moment from 'moment';
import {finalize, map, switchMap, take} from 'rxjs/operators';
import {LoaderService} from '../../shared/loading/loader.service';
import {AuthService} from '../auth.service';

@Injectable()
export class AuthGuard  {
  constructor(private auth: AuthService, private router: Router, private loading: LoaderService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.loading.setLoading(true);
    return this.auth.getToken().pipe(
      take(1),
      map(user => !!user),
      map(loggedIn => {
        if (!loggedIn) {
          console.log('access denied');
          this.router.navigate(['/login']);
          return false;
        }
      }),
      switchMap(() => this.auth.fetchCurrent().then(() => {
        if (!this.auth.isSuperAdmin
          && !this.auth.isPartner
          && this.auth.trialFinishDate
          && moment().isAfter(moment(this.auth.trialFinishDate))
        ) {
          this.router.navigate(['/expired-trial']);
          return true;
        }
        if (!this.auth.appUser.planId && !this.auth.appUser.isPremium) {
          this.router.navigate(['/plan']);
          return false;
        }
        if (next.data.adminRoute && !(this.auth.appUser.isAdmin || this.auth.appUser.role === 'SUPER_ADMIN')) {
          this.auth.logOut();
          return true;
        }
        return true;
      })),
      finalize(() => this.loading.setLoading(false))
    );
  }
}

