import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../shared/shared.module';
import { DisclaimersEffects } from './disclaimers.effects';
import { DisclaimersService } from './disclaimers.service';
import { disclaimersReducer } from './disclaimers.reducer';
import { DisclaimersComponent } from './disclaimers.component';
import { DisclaimerAddComponent } from './disclaimer-add/disclaimer-add.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

@NgModule({
  declarations: [
    DisclaimerComponent,
    DisclaimerAddComponent,
    DisclaimersComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('disclaimers', disclaimersReducer),
    EffectsModule.forFeature([DisclaimersEffects]),
  ],
  providers: [DisclaimersService],
  exports: [
    DisclaimerComponent,
    DisclaimerAddComponent,
    DisclaimersComponent
  ]
})
export class DisclaimersModule {
}
