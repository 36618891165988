<mat-toolbar class="toolbar">{{'employee.sendRequest.label' | translate}}</mat-toolbar>

<form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill">
    <mat-label>{{'employee.sendRequest.subject' | translate}}</mat-label>
    <input matInput formControlName="subject" type="text">
    <mat-error *ngIf="form.hasError('required', ['subject'])">
      {{'fieldRequired' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{'employee.sendRequest.body' | translate}}</mat-label>
    <textarea matInput formControlName="body" rows="10"></textarea>
    <mat-error *ngIf="form.hasError('required', ['body'])">
      {{'fieldRequired' | translate}}
    </mat-error>
  </mat-form-field>

  <div>
    <label>{{'employee.sendRequest.targetLabel' | translate}}:</label>
    <mat-radio-group formControlName="target">
      <span *ngFor="let value of targetValues">
        <mat-radio-button [value]="value">{{'employee.sendRequest.target.' + value | translate}}</mat-radio-button>
      </span>
      <mat-error *ngIf="form.hasError('required', ['target'])">
        {{'fieldRequired' | translate}}
      </mat-error>
    </mat-radio-group>
  </div>

<!--  <div class="summary">
    <label>{{'employee.sendRequest.employeeNumber' | translate}}:</label>
    <span class="value">123</span>
    &lt;!&ndash;<button mat-button (click)="openReviewDialog()">{{'employee.sendRequest.review' | translate}}</button>&ndash;&gt;
  </div>-->

  <div class="actions">
    <button mat-raised-button type="submit">{{'send' | translate}}</button>
    <a mat-raised-button [routerLink]="['/employees']">{{'cancel' | translate}}</a>
  </div>
</form>
