<mat-toolbar class="header">
  <span>{{ 'partner.clients.header' | translate: {number: (clients$ | async)?.length || 0} }}</span>

  <div>
    <button mat-raised-button
            (click)="generateReport()" class="report-button">{{ 'client.downloadReport' | translate }}
    </button>
    <button mat-raised-button
            (click)="openCreateClientDialog()">{{ 'partner.clients.new' | translate }}
    </button>
  </div>
</mat-toolbar>


<app-client-table [data]="clients$"></app-client-table>
