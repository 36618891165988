import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AddDisclaimerAction } from '../disclaimers.actions';
import { wyswigConfig } from '../../../signatures/signatures.service';

@Component({
  selector: 'app-disclaimer-add',
  templateUrl: './disclaimer-add.component.html',
  styleUrls: ['./disclaimer-add.component.scss']
})
export class DisclaimerAddComponent implements OnInit {

  @Input() onClose;
  config = wyswigConfig;
  file;
  form: UntypedFormGroup;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      htmlContent: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.store.dispatch(new AddDisclaimerAction(this.form.value));
      this.onClose();
    }
  }

  cancel = () => {
    this.fillInitialForm();
    this.onClose();
  }

}
