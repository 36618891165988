import {SuperAdminPartner} from '../super-admin-partners/super-admin-partners.interface';

export interface AccountsState {
  currentAccount?: string;
  currentPartnerId?: string;
  availableAccounts: AvailableAccount[];
  availablePartners: PartnerAccount[];
}

export type PartnerAccount = SuperAdminPartner;

export interface AccountsResponse {
  clients: AvailableAccount[];
  partners: SuperAdminPartner[];
}

export interface AvailableAccount {
  domain: string;
  createdAt: Date;
  googleAdminEmail?: string | null;
  name?: string | null;
  website?: string | null;
  address?: string | null;
  phone?: string | null;
  photoUrl?: string | null;
}

export const CURRENT_ACCOUNT_LOCAL_STORAGE_KEY = 'currentAccount';
export const CURRENT_PARTNER_ID_LOCAL_STORAGE_KEY = 'currentPartnerId';
