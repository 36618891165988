<form class="account-form">
  <mat-form-field>
    <mat-label>{{"account" | translate}}</mat-label>
    <input type="text" matInput [formControl]="accountForm">
  </mat-form-field>

  <app-loading *ngIf="(accounts$ | async)?.length === 0 && (partners$ | async)?.length === 0"/>

</form>


<h3 *ngIf="(partners$ | async)?.length > 0">
  {{'accounts.partnersHeader' | translate}}
</h3>

<ul class="partners-wrapper">
  <li *ngFor="let partner of partners$ | async">

    <mat-card appearance="outlined">
      <mat-card-header [title]="partner.name">
        <mat-card-title class="ellipsis">
          {{partner.name}}
        </mat-card-title>
      </mat-card-header>

      <mat-card-actions class="action-wrapper">
        <button mat-raised-button color="primary"
                (click)="goToPartnerPanel(partner)">{{'accounts.selectPartner' | translate}}</button>
      </mat-card-actions>

    </mat-card>

  </li>
</ul>

<h3 *ngIf="(partners$ | async)?.length > 0 && (accounts$ | async)?.length > 0">
  {{'accounts.accountsHeader' | translate}}
</h3>

<ul class="accounts-wrapper">

  <li *ngFor="let option of filteredOptions$ | async" class="list-item">

    <mat-card appearance="outlined">
      <mat-card-header [title]="option.name">
        <mat-card-title [class.noName]="!option.name" class="ellipsis">
          {{option.name ?? 'accounts.noName' | translate}}
        </mat-card-title>
        <mat-card-subtitle>
          {{option.domain}}
        </mat-card-subtitle>
      </mat-card-header>

      <img mat-card-image [src]="option.photoUrl" alt="{{option.domain}} logo" title="{{option.domain}} logo">

      <mat-card-content>
      </mat-card-content>

      <mat-card-actions class="action-wrapper">
        <button mat-raised-button color="primary" (click)="changeAccount(option)">{{'select' | translate}}</button>
      </mat-card-actions>

    </mat-card>


  </li>
</ul>
