<form [formGroup]="form" class="form" (ngSubmit)="save()">

  <mat-form-field appearance="fill">
    <mat-label>{{ 'footers.name' | translate }}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{ 'fieldRequired' | translate }}</mat-error>
  </mat-form-field>

  <angular-editor matInput formControlName="content" [config]="config"></angular-editor>


  <section class="placeholder-variables-container">
    <h4>{{ 'predefined.placeholder.title' | translate }}</h4>

    <ul class="placeholder-variables" formArrayName="placeholderVariables">

      <li *ngFor="let item of placeholderVariables.controls" [formGroup]="item"
          class="placeholder-variable"
      >
        <mat-form-field appearance="fill" class="placeholder-name"
                        [class.not-exists-in-content]=""

        >
          <mat-label>{{ 'predefined.placeholder.name' | translate }}</mat-label>
          <input matInput formControlName="name">

          <mat-hint class="not-exists-in-content" *ngIf="!item.get('existsInContent').value">
            {{ 'predefined.notExistsInContent' | translate }}
          </mat-hint>

        </mat-form-field>

        <mat-form-field appearance="fill" class="placeholder-value">
          <mat-label>{{ 'predefined.placeholder.value' | translate }}</mat-label>
          <input matInput formControlName="value">
        </mat-form-field>

        <button mat-icon-button (click)="removePlaceholderVariable(item)">
          <mat-icon>delete</mat-icon>
        </button>

      </li>
    </ul>
  </section>


  <div class="actions">
    <button mat-raised-button (click)="goToSignatures()">{{ 'cancel' | translate }}</button>
    <button mat-raised-button type="submit" color="primary"
            [disabled]="!form.valid || saving">{{ (saving ? 'saving' : 'save') | translate }}
    </button>
  </div>

  <app-placeholders></app-placeholders>
</form>
