import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable} from 'rxjs';

// https://github.com/angular/angular/issues/20564#issuecomment-587558261

@Injectable()
export class StripUndefinedParamsInterceptor implements HttpInterceptor {
  /**
   * Iterate through query parameters and remove all those that are `undefined`.
   * (Angular adds literal string "undefined" for some reason)
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let params = request.params;
    for (const key of request.params.keys()) {
      if (params.get(key) === undefined || params.get(key) === 'undefined') {
        params = params.delete(key, undefined);
      }
    }
    request = request.clone({params});
    return next.handle(request);
  }
}
