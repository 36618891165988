import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {SaveSuperAdminRequest, SuperAdminPartner} from './super-admin-partners.interface';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminPartnersService {

  constructor(private httpClient: HttpClient) {
  }


  public fetchAll(): Observable<SuperAdminPartner[]> {
    return this.httpClient.get<SuperAdminPartner[]>('/admin/partners');
  }

  public create(req: SaveSuperAdminRequest): Observable<SuperAdminPartner> {
    return this.httpClient.post<SuperAdminPartner>('/admin/partners', req);
  }
}
