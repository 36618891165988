import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  GET_LINKS,
  GetLinksSuccessAction,
  GetLinksFailureAction,
  CHANGE_LINK,
  ChangeLinkAction,
  ChangeLinkSuccessAction,
  ChangeLinkFailureAction,
  REMOVE_LINK,
  RemoveLinkAction,
  RemoveLinkSuccessAction,
  RemoveLinkFailureAction,
  ADD_LINK,
  AddLinkAction,
  AddLinkSuccessAction,
  AddLinkFailureAction
} from './links.actions';
import { LinksService } from './links.service';
import { AddLinkObject, Links, ChangeLinkObject } from './links.interface';

@Injectable()
export class LinksEffects {

  getLinks$: Observable<any> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_LINKS),
      switchMap(() => {
        return this.linksService.getLinks().pipe(
          switchMap((response: Links) => {
            return [new GetLinksSuccessAction(response)];
          }),
          catchError(error => of(new GetLinksFailureAction(error)))
        );
      })
    );
  });

  changeLink$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(CHANGE_LINK),
    map((action: ChangeLinkAction) => action.payload),
    switchMap((payload: ChangeLinkObject) => {
      return this.linksService.changeLink(payload).pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('addons.links.editSuccess'));
          return [new ChangeLinkSuccessAction(payload)];
        }),
        catchError(error => of(new ChangeLinkFailureAction(error)))
      );
    })
  ));

  removeLink$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(REMOVE_LINK),
    map((action: RemoveLinkAction) => action.payload),
    switchMap((payload: string) => {
      return this.linksService.removeLink(payload).pipe(
        switchMap(() => {
          this.toastr.success(this.translate.instant('addons.links.deleteSuccess'));
          return [new RemoveLinkSuccessAction(payload)];
        }),
        catchError(error => of(new RemoveLinkFailureAction(error)))
      );
    })
  ));

  addLink$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(ADD_LINK),
    map((action: AddLinkAction) => action.payload),
    switchMap((payload: AddLinkObject) => {
      return this.linksService.addLink(payload).pipe(
        switchMap((response) => {
          this.toastr.success(this.translate.instant('addons.links.addSuccess'));
          return [new AddLinkSuccessAction(response)];
        }),
        catchError(error => of(new AddLinkFailureAction(error)))
      );
    })
  ));

  constructor(private actions$: Actions,
              private linksService: LinksService,
              private translate: TranslateService,
              private toastr: ToastrService) {
  }

}
