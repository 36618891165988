import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({email: new UntypedFormControl('', [Validators.required, Validators.email])});
  sending = false;
  redirecting = false;

  constructor(private auth: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    const sub = this.auth.user$.subscribe(user => user && user.uid ?
      this.router.navigate(['/accounts']).finally(() => sub.unsubscribe()) :
      sub.unsubscribe());
  }

  async loginWithGoogle() {
    this.redirecting = true;
    await this.auth.loginWithGoogle().finally(() => this.redirecting = false);
  }
}
