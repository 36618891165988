import {Component} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {EmployeeDataImportDialogComponent} from './employee-data-import-dialog/employee-data-import-dialog.component';
import {ToastrTranslatedService} from '../../shared/toastr-translated.service';
import {EmployeeDataImportResult} from '../employees.interface';

@Component({
  selector: 'app-employee-data-import',
  templateUrl: './employee-data-import.component.html',
  styleUrls: ['./employee-data-import.component.scss']
})
export class EmployeeDataImportComponent {
  public loading = false;

  constructor(private dialog: MatDialog, private readonly toastr: ToastrTranslatedService) {
  }

  public openDialog() {
    this.dialog.open(EmployeeDataImportDialogComponent)
      .afterClosed()
      .subscribe((result?: EmployeeDataImportResult) => {
        if (result) {
          this.toastr.success('employee.import.success', result);
        }
      });
  }

}
