<div class="card" *ngIf="previewUrl">
  <div class="text-center">
    <img [src]="previewUrl" class="preview" alt="" [style]="previewImageStyles">
  </div>
</div>

<div>
  <div
    class="dz-default dz-message my-4"
    (dragover)="onDragOver($event)"
    (drop)="onDropSuccess($event)"
    (click)="fileInput.click()"
  >
    <div>{{description}}</div>
    <div class="additional-description" *ngIf="additionalDescription">{{additionalDescription}}</div>
    <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)">
  </div>
</div>
