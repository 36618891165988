import {MatDialog} from '@angular/material/dialog';
import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Signature} from './signatures.interface';
import {
  DuplicateSignatureAction,
  GetSignaturesAction,
  RemoveSignatureAction,
  SetDefaultSignatureAction,
} from './signatures.actions';
import {getSignaturesSelector} from './signatures.reducer';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';
import {Router} from '@angular/router';
import {StoreExtendedService} from '../shared/store-extended.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.scss']
})
export class SignaturesComponent implements OnInit {

  signatures$: Observable<Signature[]>;
  defaultSignatureSet: boolean;

  constructor(
    private dialog: MatDialog,
    private store: StoreExtendedService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    public auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetSignaturesAction());

    this.signatures$ = this.store.select(getSignaturesSelector)
      .pipe(
        tap(signatures => {
          this.defaultSignatureSet = signatures?.some(s => s.defaultForNewEmployee);
        })
      );
  }

  copySignatureToClipboard = (signatureBody: string) => {
    navigator.clipboard.writeText(signatureBody);
    this.toastr.success(this.translate.instant('employee.copySignatureSuccess'));
  }

  duplicateSignature = id => this.store.dispatch(new DuplicateSignatureAction(id));

  removeSignature = id => {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('signatures.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.store.dispatch(new RemoveSignatureAction(id));
          }
        }
      );
  }

  goToEditSignature = id => this.router.navigate(['signatures', id, 'edit']);

  goToDetailsSignature = id => this.router.navigate(['signatures', id]);

  setDefault(signature: Signature) {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('signatures.default.setDefaultConfirmation')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.store.dispatchAndWaitForResult(new SetDefaultSignatureAction(signature.id))
              .subscribe(a => {
                this.toastr.success(this.translate.instant('signatures.default.setDefaultSuccess'));
              });
          }
        }
      );

  }

  removeDefault(signature: Signature) {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('signatures.default.unsetDefaultConfirmation')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.store.dispatchAndWaitForResult(new SetDefaultSignatureAction(null))
              .subscribe(a => {
                this.toastr.success(this.translate.instant('signatures.default.unsetDefaultSuccess'));
              });
          }
        }
      );
  }
}
