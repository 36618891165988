import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-closable-panel',
  templateUrl: './closable-panel.component.html',
  styleUrls: ['./closable-panel.component.scss']
})
export class ClosablePanelComponent {
  public open = true;

  @Input()
  public variant?: 'warning';

  closePanel() {
    this.open = false;
  }
}
