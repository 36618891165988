<h1 mat-dialog-title translate>editClient.header</h1>

<div mat-dialog-content class="content">

  <form [formGroup]="form" (submit)="submit()" class="form">


    <mat-form-field appearance="fill">
      <mat-label translate>client.googleAdminEmail</mat-label>
      <input type="text" matInput formControlName="googleAdminEmail">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'editClient.subscription' | translate}}</mat-label>
      <mat-select formControlName="planId">
        <mat-option *ngFor="let subscription of subscriptions" [value]="subscription">{{subscription}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', ['planId'])">{{'fieldRequired' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="form.get('planId').value === 'free'">
      <mat-label>{{'client.trialFinishDate' | translate}}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="trialFinishDate">
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="form.hasError('required', ['trialFinishDate'])">{{'fieldRequired' | translate}}</mat-error>
      <mat-error *ngIf="form.hasError('dateInvalid', ['trialFinishDate'])">{{'futureDateInvalid' | translate}}</mat-error>
    </mat-form-field>

    <mat-checkbox (change)="toggleDomainChangeEnabled($event.checked)" class="client-section">
      {{'editClient.changeDomainWarning' | translate}}
    </mat-checkbox>


    <mat-form-field appearance="fill">
      <mat-label translate>client.domain</mat-label>
      <input type="text" matInput formControlName="domain">
    </mat-form-field>

  </form>

</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="close()">{{'cancel' | translate}}</button>
  <app-progress-button label="save" (buttonClick)="submit()" [loading]="sendingRequest"/>
</div>
