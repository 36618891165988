import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { Disclaimer } from '../disclaimers.interface';
import { wyswigConfig } from '../../../signatures/signatures.service';
import { ChangeDisclaimerAction, RemoveDisclaimerAction } from '../disclaimers.actions';
import {StartCheckingForInstallationInProgressAction} from '../../../signature-installation-state/signature-installation-state.actions';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {

  @Input() disclaimer: Disclaimer;
  form: UntypedFormGroup;
  editMode = false;
  config = wyswigConfig;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.disclaimer.id),
      name: new UntypedFormControl(this.disclaimer.name, Validators.required),
      htmlContent: new UntypedFormControl(this.disclaimer.htmlContent, Validators.required)
    });
  }

  changeDisclaimer = () => {
    if (this.form.valid) {
      this.store.dispatch(new StartCheckingForInstallationInProgressAction());
      this.store.dispatch(new ChangeDisclaimerAction(this.form.value));
      this.changeEditMode();
    }
  }

  cancelEditDisclaimer = () => {
    this.fillInitialForm();
    this.changeEditMode();
  }

  changeEditMode = () => {
    this.editMode = !this.editMode;
  }

  removeDisclaimer = addonDisclaimerId => {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('addons.disclaimer.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.store.dispatch(new StartCheckingForInstallationInProgressAction());
            this.store.dispatch(new RemoveDisclaimerAction(addonDisclaimerId));
          }
        }
      );
  }

}
