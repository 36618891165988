<div class="data-import-container">
  <h1 mat-dialog-title>{{ 'employee.import.header' | translate }}</h1>

  <mat-dialog-content>

    <ng-container *ngIf="(availableColumns$ | async) as availableColumns; else loader">

      <ng-container *ngIf="availableColumns.length; else noColumnsAvailable">

        <p>{{ 'employee.import.p1' | translate }}</p>
        <p>{{ 'employee.import.p2' | translate }}</p>

        <h3>{{ 'employee.import.mandatoryColumns' | translate }}</h3>
        <p><code>email</code>: {{ 'employee.import.mandatoryEmail' | translate }}</p>

        <h3>{{ 'employee.import.optionalColumns' | translate }}</h3>
        <p>{{ 'employee.import.optionalDescription' | translate }}</p>

        <code *ngFor="let column of availableColumns; let isLast=last">
          {{ column }}{{ isLast ? '.' : ', ' }}
        </code>


        <h2>{{ 'employee.import.uploadHeader' | translate }}</h2>
        <p>{{ 'employee.import.uploadDescription' | translate }}</p>

        <input type="file" class="file-input"
               accept="text/csv"
               (change)="onFileSelected($event)" #fileUpload>
        <div class="file-upload">

          <button mat-stroked-button color="primary" class="upload-btn"
                  (click)="fileUpload.click()">
            {{ 'employee.import.testButton' | translate }}
            <mat-icon>attach_file</mat-icon>
          </button>
          <h6>{{ fileName || ('employee.import.noFileYet' | translate) }}</h6>

        </div>


      </ng-container>


    </ng-container>

    <ng-container *ngIf="parsingFile; then loader"></ng-container>

    <ng-container *ngIf="verificationResult">

      <h2>{{ 'employee.import.validationHeader' | translate }}</h2>

      <div class="import-error" *ngFor="let error of verificationResult?.errors">
        <p>{{ ('employee.import.error.' + error.key) | translate }}</p>

        <ng-container *ngIf="error.value && error.value.length">
          <span *ngFor="let value of error.value">{{ value }}</span>
        </ng-container>

      </div>

      <div class="import-warning" *ngFor="let warning of verificationResult?.warnings">
        <h5>{{ ('employee.import.warning.' + warning.key) | translate }}</h5>

        <ul *ngIf="warning.value && warning.value.length">
          <li *ngFor="let value of warning.value">
            <code>{{ value }}</code>
          </li>
        </ul>
      </div>

      <div class="preview" *ngIf="verificationResult?.preview?.length; else noDataToImport">
        <h3>{{ 'employee.import.previewHeader' | translate }}</h3>
        <app-employee-data-import-preview [displayedColumns]="verificationResult.meta.columns"
                                          [dataSource]="verificationResult.preview"/>


        <p class="mt-5">{{ 'employee.import.summary' | translate: { numberOfEmployees: verificationResult.meta.numberOfEmployees } }}</p>

      </div>

    </ng-container>


  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">{{ 'cancel' | translate }}</button>

    <app-progress-button *ngIf="verificationResult?.preview?.length"
                         [label]="'employee.import.buttonInstall' | translate"
                         (buttonClick)="importData(true)"
                         [loading]="importingData || parsingFile"
                         icon="save_alt"/>

    <app-progress-button *ngIf="verificationResult?.preview?.length"
                         [label]="'employee.import.buttonImport' | translate"
                         (buttonClick)="importData(false)"
                         [loading]="importingData || parsingFile"
                         icon="save_alt"/>

  </div>

</div>


<ng-template #noDataToImport>
  <div class="import-error mt-3" *ngIf="!verificationResult.errors.length">
    <p>{{ ('employee.import.error.NO_PREVIEW') | translate }}</p>
  </div>
</ng-template>

<ng-template #noColumnsAvailable>
  <div>

    <p>{{ 'employee.import.noColumnsAvailable' | translate }}</p>


    <a routerLink="/settings" mat-stroked-button>{{ 'employee.import.goToSettings' | translate }}</a>
  </div>
</ng-template>

<ng-template #loader>
  <app-loading/>
</ng-template>
