import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import { Store } from '@ngrx/store';
import {ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GetCurrentUserAction } from '../../employees/employees.actions';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-image-processing',
  templateUrl: './image-processing.component.html',
  styleUrls: ['./image-processing.component.scss']
})
export class ImageProcessingComponent implements OnInit {

  @Input() imageUploadUrl: string;

  public imageSrc: string | ArrayBuffer;

  public readonly imageSize = 360;

  public transparentAvatar: Blob | undefined;

  uploadedImage: File = undefined;
  finalImage: File | Blob = undefined;

  croppedImage: string;
  backgroundColor: string = undefined;

  customBackgroundColor = new FormControl('#');

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(private store: Store, private http: HttpClient, private toast: ToastrService, private translate: TranslateService) {
  }

  ngOnInit() {
    this.customBackgroundColor.valueChanges.subscribe(value => {
      this.drawAvatarWithColor(value);
    });
  }

  get loading(): boolean {
    return this.imageSrc && !this.transparentAvatar;
  }

  onFileSelect(event: Event): void {
    this.transparentAvatar = null;

    const element = event.currentTarget as HTMLInputElement;

    this.uploadedImage = element.files[0];
    this.finalImage = this.uploadedImage;
    this.removeImageBackground();
  }

  public async drawAvatarWithColor(color: string) {
    this.finalImage = this.transparentAvatar;
    this.backgroundColor = color;
    setTimeout(() => {
       this.imageCropper.crop();
    }, 1000);

  }

  drawOriginalAvatar() {
    this.backgroundColor = undefined;
    this.finalImage = this.uploadedImage;
    this.imageCropper.crop();
  }

  saveNewImage() {
    this.http.put(this.imageUploadUrl, {avatarBase64: this.croppedImage})
      .subscribe(() => {
        this.toast.success(this.translate.instant('employee.avatar.saveSuccess'));
        this.store.dispatch(new GetCurrentUserAction());
      });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  removeImageBackground() {
    const reader = new FileReader();
    reader.onload = e => this.imageSrc = reader.result;
    reader.readAsDataURL(this.uploadedImage);

    const form = new FormData();
    form.append('image', this.uploadedImage);

    this.http.post(`/image-processing/remove-background`, form, {responseType: 'blob'})
      .subscribe(result => {
        this.finalImage = result;
        this.transparentAvatar = result;
      });
  }
}
