import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanySettings } from '../settings/settings.interface';


@Injectable({providedIn: 'root'})
export class CompanyService {

  constructor(private http: HttpClient) {
  }

  get = () => this.http.get('/company');

  save = (companySettings: CompanySettings) => this.http.post('/company', companySettings);

  upload = (file: any) => {
    const form = new FormData();
    form.append('image', file);
    return this.http.post(`/company/photo`, form);
  }
}
