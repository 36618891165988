import {Component, OnInit} from '@angular/core';
import {SignaturesService, wyswigConfig} from '../signatures.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {SaveTemplatePredefined, Signature} from '../signatures.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrTranslatedService} from '../../shared/toastr-translated.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-edit-signature-predefined',
  templateUrl: './edit-signature-predefined.component.html',
  styleUrls: ['./edit-signature-predefined.component.scss']
})
export class EditSignaturePredefinedComponent implements OnInit {
  public readonly config: AngularEditorConfig = wyswigConfig;
  public readonly editExisting: boolean;

  public saving = false;

  public readonly form = new FormGroup({
    name: new FormControl(''),
    content: new FormControl('', Validators.required),
    placeholderVariables: new FormArray([]),
  });

  constructor(private readonly service: SignaturesService,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly toastr: ToastrTranslatedService,
  ) {
    this.editExisting = Boolean(this.route.snapshot.params.id);
  }

  ngOnInit(): void {
    if (this.editExisting) {
      this.service.predefinedById(this.route.snapshot.params.id).subscribe((f) => {
        this.form.patchValue({
          name: f.name,
          content: f.content,
        });
        this.updatePlaceholderVariables(f.placeholderVariables ?? {});
      });
    }

    this.form.controls.content.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        const variableNames = this.findAllPlaceholderVariablesInHtmlContent(value);
        this.synchronizePlaceholderVariablesWithHtmlVariables(variableNames);
      });

  }


  public get placeholderVariables(): FormArray {
    return this.form.get('placeholderVariables') as FormArray;
  }

  public save() {
    if (this.form.invalid) {
      console.error('Form is invalid');
      return;
    }
    this.saving = true;
    const body = this.normalizeFormToRequestBody();

    const request = this.editExisting
      ? this.service.updatePredefined(this.route.snapshot.params.id, body)
      : this.service.addPredefined(body);

    request.toPromise()
      .then(() => this.toastr.success('footers.updated'))
      .then(() => this.goToSignatures())
      .finally(() => this.saving = false);
  }

  public goToSignatures() {
    this.router.navigate(['signatures', 'library']);
  }

  public removePlaceholderVariable(item: AbstractControl) {
    this.placeholderVariables.removeAt(this.placeholderVariables.controls.indexOf(item));
  }

  private normalizeFormToRequestBody(): SaveTemplatePredefined {
    const placeholderVariables = this.placeholderVariables.getRawValue().reduce((obj, item) => {
      obj[item.name] = item.value;
      return obj;
    }, {} as Record<string, string>);

    return {...this.form.getRawValue(), placeholderVariables};
  }

  private findAllPlaceholderVariablesInHtmlContent(htmlContent: string) {
    const regex = /\$\{.*?\}/g;
    const variableNames = htmlContent
      ?.match(regex)
      ?.map(match => match.slice(2, -1)) ?? [];

    return [...new Set(variableNames)];
  }

  private synchronizePlaceholderVariablesWithHtmlVariables(variableNames: string[]) {
    const existingVariableNames = this.placeholderVariables.controls.map(control => control.value.name);
    const newVariableNames = variableNames.filter(name => !existingVariableNames.includes(name));
    const removedVariableNames = existingVariableNames.filter(name => !variableNames.includes(name));

    newVariableNames.forEach(name => this.placeholderVariables.push(new FormGroup({
      name: new FormControl(name, Validators.required),
      value: new FormControl('', Validators.required),
      existsInContent: new FormControl(true),
    })));

    existingVariableNames.forEach(name => {
      const index = this.placeholderVariables.controls.findIndex(control => control.value.name === name);
      this.placeholderVariables.at(index).get('existsInContent').setValue(true);
    });

    removedVariableNames.forEach(name => {
      const index = this.placeholderVariables.controls.findIndex(control => control.value.name === name);
      this.placeholderVariables.at(index).get('existsInContent').setValue(false);
    });
  }

  private updatePlaceholderVariables(placeholderVariables: Record<string, string>) {
    Object.entries(placeholderVariables).forEach(([name, value]) => {
      this.placeholderVariables.push(new FormGroup({
        name: new FormControl(name, Validators.required),
        value: new FormControl(value, Validators.required),
        existsInContent: new FormControl(true),
      }));
    });
  }
}
