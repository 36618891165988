import { Component, Input, OnInit } from '@angular/core';
import { Link } from '../links.interface';
import { ChangeLinkAction, RemoveLinkAction } from '../links.actions';
import { Store } from '@ngrx/store';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {StartCheckingForInstallationInProgressAction} from '../../../signature-installation-state/signature-installation-state.actions';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit {

  @Input() link: Link;
  form: UntypedFormGroup;
  editMode = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.fillInitialForm();
  }

  fillInitialForm = () => {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.link.id, Validators.required),
      name: new UntypedFormControl(this.link.name, Validators.required),
      url: new UntypedFormControl(this.link.url, Validators.required),
      text: new UntypedFormControl(this.link.text, Validators.required)
    });
  }

  changeLink = () => {
    if (this.form.valid) {
      this.store.dispatch(new StartCheckingForInstallationInProgressAction());
      this.store.dispatch(new ChangeLinkAction(this.form.value));
      this.changeEditMode();
    } else {
      this.form.markAllAsTouched();
    }
  }

  cancelEditLink = () => {
    this.fillInitialForm();
    this.changeEditMode();
  }

  changeEditMode = () => {
    this.editMode = !this.editMode;
  }

  removeLink = addonLinkId => {
    this.dialog.open(ConfirmationDialogComponent, {data: {title: this.translate.instant('addons.links.deleteConfirmationText')}})
      .afterClosed()
      .subscribe(confirm => {
          if (confirm) {
            this.store.dispatch(new RemoveLinkAction(addonLinkId));
          }
        }
      );
  }

}
