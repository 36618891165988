import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {AccountsResponse, AvailableAccount} from './accounts.interface';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private httpClient: HttpClient) {
  }


  public getAllAvailable(): Observable<AccountsResponse> {
    return this.httpClient.get<AccountsResponse>('/accounts');
  }
}
