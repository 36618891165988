import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {imageDimensionsFromData} from 'image-dimensions';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  @Input() previewUrl;
  @Input() allowedFileExtensions: string[] = ['image/jpeg', 'image/png'];
  @Input() wrongTypeMessage: string = this.translate.instant('company.wrongType');
  @Input() description: string = this.translate.instant('company.photoClick');
  @Input() additionalDescription: string;
  @Input() previewImageStyles: string;
  @Output() private onFileChange = new EventEmitter<any>();

  constructor(private translate: TranslateService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

  uploadFile = async (files: any) => {
    const file = files[0];
    const mimeType = file.type;

    if (!this.allowedFileExtensions.includes(mimeType)) {
      return this.toastr.error(this.wrongTypeMessage);
    }

    if (file.size > 5_000_000) {
      return this.toastr.error(this.translate.instant('company.fileTooLarge'));
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      this.previewUrl = reader.result;
      const dimensions = imageDimensionsFromData(new Uint8Array(await file.arrayBuffer()));
      this.onFileChange.emit({file, width: dimensions?.width, height: dimensions?.height});
    };
  }

  onDragOver = event => event.preventDefault();

  onDropSuccess = event => {
    event.preventDefault();
    this.uploadFile(event.dataTransfer.files);
  }

}
