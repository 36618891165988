import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthService} from '../../auth/auth.service';
import {LANG} from '../../app.component';
import {User as FirebaseUser} from '@angular/fire/auth';
import {Store} from '@ngrx/store';
import {getCurrentAccount} from '../../accounts/accounts.reducer';
import {User} from '../../users/user';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  user: Observable<FirebaseUser>;
  account$: Observable<string>;
  languages = ['en', 'es', 'pl'];
  selectedLanguage;

  constructor(public auth: AuthService,
              public translate: TranslateService,
              private store: Store,
              public router: Router,
              private observer: BreakpointObserver) {
    translate.addLangs(this.languages);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    const defaultLang = localStorage.getItem(LANG) || (browserLang.match(/en|es|pl/) ? browserLang : 'en');
    translate.use(defaultLang);
    this.selectedLanguage = defaultLang;
  }

  ngOnInit(): void {
    this.user = this.auth.authState$;
    this.account$ = this.store.select(getCurrentAccount);
  }

  get hideMenu(): boolean {
    return window.location.pathname === '/accounts';
  }

  get appUser(): User | undefined {
    return this.auth.appUser;
  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 768px)']).subscribe((res) => {
      if (this.hideMenu) {
        return;
      }
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
  }

  initials = (name: string) => name ? `${name.split(' ')[0][0]} ${name.split(' ')[1][0]}` : '';

  change = (event) => {
    localStorage.setItem(LANG, event.value);
    this.translate.use(event.value);
  }

}
