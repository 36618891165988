import { Component } from '@angular/core';

@Component({
  selector: 'app-signatures-navigation',
  templateUrl: './signatures-navigation.component.html',
  styleUrls: ['./signatures-navigation.component.scss']
})
export class SignaturesNavigationComponent {

}
