<app-loading *ngIf="loading"></app-loading>
<form [formGroup]="form" (ngSubmit)="save()" *ngIf="!loading" class="company-settings-form">
  <div class="combined-inputs">
    <mat-form-field appearance="fill">
      <mat-label>{{'company.name' | translate}}</mat-label>
      <input matInput formControlName="name" type="text">
    </mat-form-field>
    <div>{{'${companyName}'}}</div>
  </div>

  <div class="combined-inputs">
    <mat-form-field appearance="fill">
      <mat-label>{{'company.website' | translate}}</mat-label>
      <input matInput formControlName="website" type="text">
    </mat-form-field>
    <div>{{'${companyWebsite}'}}</div>
  </div>

  <div class="combined-inputs">
    <mat-form-field appearance="fill">
      <mat-label>{{'company.address' | translate}}</mat-label>
      <textarea matInput formControlName="address" rows="5"></textarea>
    </mat-form-field>
    <div>{{'${companyAddress}'}}</div>
  </div>

  <div class="combined-inputs">
    <mat-form-field appearance="fill">
      <mat-label>{{'company.phone' | translate}}</mat-label>
      <input matInput formControlName="phone" type="text">
      <mat-error *ngIf="form.hasError('phoneNumberInvalid', ['phone'])">
        {{'phoneNumberInvalid' | translate}}
      </mat-error>
    </mat-form-field>
    <div>{{'${companyPhone}'}}</div>
  </div>

  <div *ngIf="company?.photoUrl">
    <h3>{{'company.photoUrl' | translate}}</h3>
    <span class="text-muted">{{company?.photoUrl}}</span>
  </div>

  <app-image-uploader
    [allowedFileExtensions]="['image/jpeg', 'image/png', 'image/gif', 'image/webp']"
    [previewUrl]="previewUrl"
    (onFileChange)="fileChange($event)"
    [previewImageStyles]="imageService.getImageStyle(form.get('imageWidth').value)"
  ></app-image-uploader>

  <mat-form-field appearance="fill">
    <mat-label>{{'imageWidth' | translate}}</mat-label>
    <input matInput formControlName="imageWidth" type="number" min="0">
    <span matSuffix>px</span>
  </mat-form-field>

  <div class="action-wrapper">
    <button mat-raised-button type="submit" [disabled]="saving">
      {{(saving ? 'saving' : 'save') | translate}}
    </button>
  </div>
</form>
