<div class="avatar-wrapper">
  <div class="toggle-avatar">
    <mat-slide-toggle (change)="toggleUseCustomAvatar()">
      {{'employee.avatar.customAvatar' | translate}}
    </mat-slide-toggle>
  </div>
  <mat-accordion>
    <mat-expansion-panel hideToggle [disabled]="!useCustomAvatarEnabled">

      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'employee.avatar.label' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-image-processing [imageUploadUrl]="avatarUploadUrl"></app-image-processing>
    </mat-expansion-panel>
  </mat-accordion>
</div>
