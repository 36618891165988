import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../shared/shared.module';
import { LinksEffects } from './links.effects';
import { LinksService } from './links.service';
import { linksReducer } from './links.reducer';
import { LinksComponent } from './links.component';
import { LinkComponent } from './link/link.component';
import { LinkAddComponent } from './link-add/link-add.component';

@NgModule({
  declarations: [
    LinkComponent,
    LinkAddComponent,
    LinksComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('links', linksReducer),
    EffectsModule.forFeature([LinksEffects]),
  ],
  providers: [LinksService],
  exports: [
    LinkComponent,
    LinkAddComponent,
    LinksComponent
  ]
})
export class LinksModule {
}
