import {Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SignaturePredefined} from '../signatures.interface';
import {SignaturesService, wyswigConfig} from '../signatures.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-signature-create',
  templateUrl: './signature-create.component.html',
  styleUrls: ['./signature-create.component.scss']
})
export class SignatureCreateComponent implements OnInit {

  form = new UntypedFormGroup({
    content: new UntypedFormControl(''),
    name: new UntypedFormControl('', Validators.required),
    addonBannerId: new UntypedFormControl(''),
    addonLinkId: new UntypedFormControl(''),
    addonDisclaimerId: new UntypedFormControl(''),
    userAvatarWidth: new UntypedFormControl(null, Validators.min(0)),
    userAvatarHeight: new UntypedFormControl(null, Validators.min(0)),
    userAvatarBorderRadius: new UntypedFormControl(null, [Validators.min(0), Validators.max(100)])
  });

  setting = false;
  config = wyswigConfig;

  public predefined$: Observable<SignaturePredefined[]>;

  constructor(
    private service: SignaturesService,
    private toast: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.predefined$ = this.service.predefined();
  }

  save() {
    this.setting = true;
    this.service.save(this.form.value).toPromise()
      .then(() => this.toast.success(this.translate.instant('footers.added')))
      .then(() => this.goToSignatures())
      .finally(() => this.setting = false);
  }

  load = (tpl: any) => this.form.patchValue({content: tpl.content});

  goToSignatures = () => {
    this.router.navigate(['signatures']);
  };

}
