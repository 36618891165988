import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {CallbackComponent} from './auth/callback/callback.component';
import {ErrorComponent} from './auth/error/error.component';
import {MainComponent} from './shared/main/main.component';
import {SignatureCreateComponent} from './signatures/signature-create/signature-create.component';
import {SignatureDetailsComponent} from './signatures/signature-details/signature-details.component';
import {SignatureEditComponent} from './signatures/signature-edit/signature-edit.component';
import {WizardUnitComponent} from './signatures/wizard/wizard-unit/wizard-unit.component';
import {WizardUserComponent} from './signatures/wizard/wizard-user/wizard-user.component';
import {WizardComponent} from './signatures/wizard/wizard.component';
import {WizardChooseComponent} from './signatures/wizard/wizard-choose/wizard-choose.component';
import {WizardFinalComponent} from './signatures/wizard/wizard-final/wizard-final.component';
import {WizzardSuccessComponent} from './signatures/wizard/wizzard-success/wizzard-success.component';
import {PlannedComponent} from './planned/planned.component';
import {TranslationsOnlyBlankComponent} from './shared/blank/translations-only-blank.component';
import {PlanComponent} from './plan/plan.component';
import {SubscriptionSuccessComponent} from './plan/subscription-success/subscription-success.component';
import {SubscriptionFailedComponent} from './plan/subscription-failed/subscription-failed.component';
import {FinanceComponent} from './finance/finance.component';
import {AuthGuard} from './auth/guard/auth.guard';
import {ClientComponent} from './client/client.component';
import {EmployeeListComponent} from './employees/employee-list/employee-list.component';
import {EmployeeEditComponent} from './employees/employee-edit/employee-edit.component';
import {EmployeeRequestComponent} from './employees/employee-request/employee-request.component';
import {CurrentEmployeeFormComponent} from './employees/current-employee-form/current-employee-form.component';
import {SettingsComponent} from './settings/settings.component';
import {AddonsComponent} from './addons/addons.component';
import {AccountsComponent} from './accounts/accounts.component';
import {UserAdminsComponent} from './user-admins/user-admins.component';
import {UserAdminsCreateComponent} from './user-admins/user-admins-create/user-admins-create.component';
import {SignaturesComponent} from './signatures/signatures.component';
import {SuperAdminPartnersComponent} from './super-admin-partners/super-admin-partners/super-admin-partners.component';
import {PartnerClientsComponent} from './partner/partner-clients/partner-clients.component';
import {PartnerMainComponent} from './partner/partner-main/partner-main.component';
import {MediaComponent} from './media/media.component';
import {CompanyComponent} from './company/company.component';
import {EmployeeSettingsComponent} from './employee-settings/employee-settings.component';
import {AdvancedSettingsComponent} from './advanced-settings/advanced-settings.component';
import {IntegrationSettingsComponent} from './integration-settings/integration-settings.component';
import {ExpiredTrialComponent} from './expired-trial/expired-trial.component';
import {SignaturesNavigationComponent} from './signatures/signatures-navigation/signatures-navigation.component';
import {SignaturesPredefinedComponent} from './signatures/signatures-predefined/signatures-predefined.component';
import {EditSignaturePredefinedComponent} from './signatures/edit-signature-predefined/edit-signature-predefined.component';
import {AnalyticsDashboardComponent} from './analytics-dashboard/analytics-dashboard.component';

const routes: Routes = [
  {
    path: 'login', component: TranslationsOnlyBlankComponent, children: [
      {path: '', component: LoginComponent},
      {path: 'callback', component: CallbackComponent},
      {path: 'error', component: ErrorComponent}
    ]
  },
  {
    path: 'plan', component: TranslationsOnlyBlankComponent, children: [
      {path: '', component: PlanComponent},
      {path: 'success', component: SubscriptionSuccessComponent},
      {path: 'fail', component: SubscriptionFailedComponent}
    ]
  },
  {
    path: 'partner', canActivate: [AuthGuard], component: PartnerMainComponent, children: [
      {path: '', redirectTo: 'clients', pathMatch: 'full'},
      {path: 'clients', component: PartnerClientsComponent},
    ]
  },
  {
    path: '', canActivate: [AuthGuard], component: MainComponent, children: [
      {path: '', redirectTo: 'accounts', pathMatch: 'full'},
      {
        path: 'settings', component: SettingsComponent, children: [
          {path: 'company', component: CompanyComponent},
          {path: 'employees', component: EmployeeSettingsComponent},
          {path: 'advanced', component: AdvancedSettingsComponent},
          {path: 'integration', component: IntegrationSettingsComponent},
        ]
      },

      {
        path: 'signatures', component: SignaturesNavigationComponent, children: [
          {path: '', redirectTo: 'custom', pathMatch: 'full'},
          {path: 'custom', component: SignaturesComponent},
          {path: 'custom/create', component: SignatureCreateComponent},
          {path: 'library', component: SignaturesPredefinedComponent},
          {path: 'library/new', component: EditSignaturePredefinedComponent},
          {path: 'library/:id', component: EditSignaturePredefinedComponent},
          {path: 'custom/create', component: SignatureCreateComponent},
          {path: 'custom/:id/edit', component: SignatureEditComponent},
          {path: 'custom/:id', component: SignatureDetailsComponent},
        ]
      },

      {
        path: 'stats', component: AnalyticsDashboardComponent,
      },

      {path: 'media', component: MediaComponent},
      {path: 'signatures', redirectTo: 'signatures/custom', pathMatch: 'full'},
      {path: 'signatures/create', redirectTo: 'signatures/custom/create', pathMatch: 'full'},
      {path: 'signatures/:id/edit', redirectTo: 'signatures/custom/:id/edit', pathMatch: 'full'},
      {path: 'signatures/:id', redirectTo: 'signatures/custom/:id', pathMatch: 'full'},
      {path: 'planned', component: PlannedComponent},
      {path: 'finance', component: FinanceComponent},
      {path: 'employees', component: EmployeeListComponent},
      {path: 'employees/request', component: EmployeeRequestComponent},
      {path: 'employees/:id/edit', component: EmployeeEditComponent},
      {path: 'my-account', component: CurrentEmployeeFormComponent},
      {path: 'addons', component: AddonsComponent},
      {path: 'accounts', component: AccountsComponent},
      {
        path: 'wizard', component: WizardComponent, children: [
          {path: '', component: WizardChooseComponent},
          {path: 'group', component: WizardUnitComponent},
          {path: 'user', component: WizardUserComponent},
          {path: 'final', component: WizardFinalComponent},
          {path: 'success', component: WizzardSuccessComponent}
        ]
      },
      {path: 'user-admins', component: UserAdminsComponent},
      {path: 'user-admins/create', component: UserAdminsCreateComponent},
      {
        path: 'stats', component: AnalyticsDashboardComponent,
      },
    ]
  },
  {
    path: 'admin', canActivate: [AuthGuard], data: {adminRoute: true}, component: MainComponent, children: [
      {path: 'client', component: ClientComponent},
      {path: 'partners', component: SuperAdminPartnersComponent}
    ]
  },
  {
    path: 'expired-trial', component: MainComponent, children: [
      {path: '', component: ExpiredTrialComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
