import { Component, OnInit } from '@angular/core';
import { Links } from './links.interface';
import { GetLinksAction } from './links.actions';
import { Store } from '@ngrx/store';
import { getLinksSelector } from './links.reducer';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {

  links: Links;
  isShowAddForm = false;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetLinksAction());

    this.store.select(getLinksSelector).subscribe((links: Links) => {
      this.links = links;
    });
  }

  showAddForm = () => this.isShowAddForm = true;

  hideAddForm = () => this.isShowAddForm = false;

}
