<div class="d-flex align-items-center bg-auth border-top border-top-1 border-primary min-vh-100"
     *ngIf="loading.isLoading() | async">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
