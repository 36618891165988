import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {EffectsModule} from '@ngrx/effects';
import {AccountsComponent} from './accounts.component';
import {AccountsEffects} from './accounts.effects';
import {StoreModule} from '@ngrx/store';
import {accountsReducer, accountsStateName} from './accounts.reducer';

@NgModule({
  declarations: [
    AccountsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature(accountsStateName, accountsReducer),
    EffectsModule.forFeature([AccountsEffects]),
  ],
  providers: [],
  exports: [
    AccountsComponent,
  ]
})
export class AccountsModule {
}
