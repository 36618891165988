import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddBannerObject, Banner, Banners, ChangeBannerObject, ChangeBannerResponse } from './banners.interface';
import { map } from 'rxjs/operators';
import { Addon, AddonsResponse } from '../addons.interface';

@Injectable({ providedIn: 'root' })
export class BannersService {

  constructor(private http: HttpClient) {
  }

  getBanners = (): Observable<Banners> => {
    return this.http.get<AddonsResponse>('/addons')
      .pipe(
        map((addons) => addons?.BANNER?.map(banner => ({
          id: banner.id,
          ...banner.data,
        } as Banner))),
        map(banners => ({ banners }))
      );
  };

  changeBanner = ({ id, ...body }: ChangeBannerObject): Observable<Banner> => {
    return this.http.put<ChangeBannerResponse>(`/addons/${ id }`, this.prepareBannerFormData(body))
      .pipe(
        map(banner => ({id, ...banner.addon.data} as Banner))
      );
  };

  removeBanner = (id: string): Observable<any> => {
    return this.http.delete(`/addons/${ id }`);
  };

  addBanner = (payload: AddBannerObject): Observable<Banner> => {
    return this.http.post<Addon>('/addons', this.prepareBannerFormData(payload))
      .pipe(
        map(banner => ({
            id: banner.id,
            ...banner.data,
          } as Banner)
        )
      );
  };

  private prepareBannerFormData = payload => {
    const formData = new FormData();
    formData.append('image', payload.file);
    formData.append('name', payload.name);
    formData.append('utm', payload.utm);
    formData.append('url', payload.url);
    formData.append('imageWidth', payload.imageWidth);
    formData.append('imageHeight', payload.imageHeight);
    formData.append('originalImageWidth', payload.originalImageWidth);
    formData.append('originalImageHeight', payload.originalImageHeight);
    return formData;
  };
}
